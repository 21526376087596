import React, { Component } from "react";
import { connect } from "react-redux";
import Input from "../base/Input";
import Table, { TableHeader } from "../base/Table";
import { adminDataLimit } from "../store/actions/adminsActions";
import { adminStateInterface } from "../store/reducers/adminsReducer";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  history: any;
  pageIndex: number;
  searchText: string;
  adminStore: adminStateInterface;
  authStore: authStateInterface;
  getAdminsWithPagination: (
    paginationStartAt: string,
    paginationSearch: string
  ) => void;
  handleUpdatePageIndex: (pageIndex: number) => void;
  handleFilter: (e: any) => void;
}

class Admins extends Component<Props> {
  handleSelectedData = (id: string) => {
    this.props.history.push(`/dashboard/adminEditor?data=${id}`);
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      //INFO : Check whether need to get new data from firebase
      if (
        this.props.adminStore.lastCursor &&
        this.props.pageIndex * adminDataLimit + 1 >
          this.props.adminStore.admins.length
      ) {
        this.props.getAdminsWithPagination(
          this.props.adminStore.lastCursor,
          ""
        );
      }
      this.props.handleUpdatePageIndex(this.props.pageIndex + 1);
    } else {
      this.props.handleUpdatePageIndex(this.props.pageIndex - 1);
    }
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (this.props.adminStore.admins?.length > 0) {
      this.props.adminStore.admins.map((eachAdmin) =>
        listView.push({
          id: eachAdmin.id,
          email: eachAdmin.email,
          name: eachAdmin.name,
          role: eachAdmin.role,
          state: eachAdmin.state?.toString() ?? "",
        })
      );
    }
    return listView;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "email",
        title: "Email",
      },
      {
        key: "name",
        title: "Name",
      },
      {
        key: "role",
        title: "Role",
      },
      {
        key: "state",
        title: "State",
      },
    ];
    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Admin
            </h3>
            <div className="ml-4 w-60 sm:w-80">
              <Input
                id="id"
                placeholder="Search by Email"
                value={this.props.searchText}
                onChange={this.props.handleFilter}
              />
            </div>
          </div>
        </div>

        <Table
          dataLimit={adminDataLimit}
          loading={this.props.adminStore.loading}
          header={headers}
          pageIndex={this.props.pageIndex}
          lastCursor={this.props.adminStore.lastCursor}
          data={this.renderFilteredData()}
          handlePage={this.handlePagination}
          onClickRow={this.handleSelectedData}
          newTabUrl="/dashboard/adminEditor?data="
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    adminStore: state.adminStore,
  };
};

export default connect(mapStateToProps)(Admins);
