import Reward from "../../../models/Reward";
import {
  RewardStateAttribute,
  RewardStateError,
} from "../../dashboard/RewardEditor";
import firebase from "../../utils/firebase";

export const rewardDataLimit: number = 10;

export const getRewardWithPagination = (
  paginationStartAfter: string | Date
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let rewardSnapshot;
      let rewardCollectionQuery = firebase
        .firestore()
        .collection("rewards")
        .orderBy("importance")
        .limit(rewardDataLimit);

      let rewardList: Reward[] = getState().rewardStore.reward;
      const newRewardList: Reward[] = [];

      updateRewardLoadingState(dispatch, true);

      //INFO: PaginationStartAfter is a string, to make it as number, add Number syntax.
      if (paginationStartAfter) {
        rewardSnapshot = await rewardCollectionQuery
          .startAfter(Number(paginationStartAfter))
          .get();
      } else {
        rewardSnapshot = await rewardCollectionQuery.get();
      }
      if (rewardSnapshot) {
        rewardSnapshot.forEach((eachDoc: any) => {
          const eachReward = eachDoc.data() as Reward;
          newRewardList.push(eachReward);
        });
      }

      if (newRewardList.length > 0) {
        let lastCursor: string | Date = "";
        if (newRewardList.length === rewardDataLimit) {
          lastCursor = String(newRewardList[rewardDataLimit - 1].importance);
        }

        dispatch({
          type: "UPDATE_REWARD_LIST",
          payload: {
            reward: rewardList.concat(newRewardList),
            lastCursor: lastCursor,
          },
        });
      } else {
        dispatch({
          type: "UPDATE_REWARD_LIST",
          payload: {
            reward: rewardList,
            lastCursor: "",
          },
        });
      }

      updateRewardLoadingState(dispatch, false);
    } catch (err: any) {
      updateRewardLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const handleRewardCondition = (
  rewardState: RewardStateAttribute,
  rewardError: RewardStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "name":
        if (rewardState.name.replace(/\s/g, "").length <= 0) {
          rewardError["nameError"] = "Please enter the reward name";
        } else {
          rewardError["nameError"] = "";
        }
        break;
      case "oneliner":
        if (rewardState.oneliner.replace(/\s/g, "").length <= 0) {
          rewardError["onelinerError"] = "Please enter the one liner";
        } else {
          rewardError["onelinerError"] = "";
        }
        break;
      case "description":
        if (rewardState.description.replace(/\s/g, "").length <= 0) {
          rewardError["descriptionError"] = "Please enter the description";
        } else {
          rewardError["descriptionError"] = "";
        }
        break;
      case "importance":
        if (rewardState.importance <= 0) {
          rewardError["importanceError"] = "Please enter the reward importance";
        } else {
          rewardError["importanceError"] = "";
        }
        break;
      case "validity":
        if (rewardState.validity.replace(/\s/g, "").length <= 0) {
          rewardError["validityError"] = "Please enter the validity";
        } else {
          rewardError["validityError"] = "";
        }
        break;
      case "value":
        if (rewardState.value <= 0) {
          rewardError["valueError"] = "Please enter the value of the reward";
        } else {
          rewardError["valueError"] = "";
        }
        break;
      case "category":
        if (rewardState.value <= 0) {
          rewardError["categoryError"] = "Please enter the reward's category";
        } else {
          rewardError["categoryError"] = "";
        }
        break;
      case "tag":
        if (rewardState.value <= 0) {
          rewardError["tagError"] = "Please enter the reward's tag";
        } else {
          rewardError["tagError"] = "";
        }
        break;
      case "rewardLogo":
        if (rewardState.rewardLogo) {
          const fileExtensionFilter = /.(jpg|jpeg|png)$/;
          const fileSize = rewardState.rewardLogo.size / 1024 / 1024;
          if (!fileExtensionFilter.test(rewardState.rewardLogo.name)) {
            rewardError["rewardLogoError"] = "Please upload an image file";
          } else if (fileSize > 5) {
            rewardError["rewardLogoError"] = "File size cannot exceed 5MB";
          } else {
            rewardError["rewardLogoError"] = "";
          }
        } else {
          rewardError["rewardLogoError"] = "Please upload an image file";
        }
        break;
      case "rewardBanner":
        if (rewardState.rewardBanner) {
          const fileExtensionFilter = /.(jpg|jpeg|png)$/;
          const fileSize = rewardState.rewardBanner.size / 1024 / 1024;
          if (!fileExtensionFilter.test(rewardState.rewardBanner.name)) {
            rewardError["rewardBannerError"] = "Please upload an image file";
          } else if (fileSize > 5) {
            rewardError["rewardBannerError"] = "File size cannot exceed 5MB";
          } else {
            rewardError["rewardBannerError"] = "";
          }
        } else {
          rewardError["rewardBannerError"] = "Please upload an image file";
        }
        break;
    }
    return null;
  });
};

export const getReward = async (selectedId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      let rewardQuery = await firebase
        .firestore()
        .collection("rewards")
        .doc(selectedId)
        .get();
      if (rewardQuery.exists) {
        return rewardQuery.data() as Reward;
      } else {
        return "Reward cannot be found";
      }
    } else {
      return "Reward cannot be found";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const updateReward = async (reward: RewardStateAttribute) => {
  try {
    const rewardRef = firebase.firestore().collection("rewards").doc(reward.id);

    await rewardRef.update({
      name: reward.name,
      description: reward.description,
      validity: reward.validity,
      oneliner: reward.oneliner,
      category: reward.category,
      tag: reward.tag,
      importance: Number(reward.importance),
      value: reward.value,
      website: reward.website,
    });
  } catch (err: any) {
    return err.message;
  }
};

export const createReward = async (reward: RewardStateAttribute) => {
  try {
    const rewardRef = firebase.firestore().collection("rewards").doc();
    const newRewardRef = rewardRef.id;
    if (reward.rewardLogo && reward.rewardBanner) {
      const logoUploadTask = await firebase
        .storage()
        .ref("rewards")
        .child(newRewardRef + `/logo.jpg`)
        .put(reward.rewardLogo);
      const logoUploadTaskUrl: string =
        await logoUploadTask.ref.getDownloadURL();
      const logoUrlParams = new URLSearchParams(logoUploadTaskUrl);
      const logoImageToken = logoUrlParams.get("token");

      const bannerUploadTask = await firebase
        .storage()
        .ref("rewards")
        .child(newRewardRef + `/banner.jpg`)
        .put(reward.rewardBanner);
      const bannerUploadTaskUrl: string =
        await bannerUploadTask.ref.getDownloadURL();
      const bannerUrlParams = new URLSearchParams(bannerUploadTaskUrl);
      const bannerImageToken = bannerUrlParams.get("token");

      if (logoImageToken && bannerImageToken) {
        const rewardModel: Reward = {
          id: newRewardRef,
          name: reward.name,
          validity: reward.validity,
          description: reward.description,
          oneliner: reward.oneliner,
          rewardLogo: logoImageToken,
          rewardBanner: bannerImageToken,
          category: reward.category,
          tag: reward.tag,
          importance: Number(reward.importance),
          value: reward.value,
          website: reward.website,
        };

        await rewardRef.set(rewardModel);
        return "";
      } else {
        return "Unknown error, please contact developer at info@arusoil.com if this continues";
      }
    }
  } catch (err: any) {
    return err.message;
  }
};

export const deleteReward = async (selectedId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      await firebase
        .storage()
        .ref("rewards")
        .child(selectedId + "/logo.jpg")
        .delete();
      await firebase
        .storage()
        .ref("rewards")
        .child(selectedId + "/banner.jpg")
        .delete();
      await firebase.firestore().collection("rewards").doc(selectedId).delete();
      return "";
    } else {
      return "Reward cannot be found";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

const updateRewardLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_REWARD_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const updateSelectedReward = (selectedReward: Reward | null) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_SELECTED_REWARD",
      payload: {
        selectedReward: selectedReward,
      },
    });
  };
};

export const clearReward = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_REWARD_LIST",
      payload: {
        reward: [],
        lastCursor: "",
      },
    });
  };
};
