import React, { Component } from "react";
import { Address } from "../../models/User";

interface Props {
  id?: string;
  label?: string;
  value: Address;
  className?: string;
  disableTitle?: boolean;
  error?: string;
  onChange: (e: Address) => void;
}

interface State {
  addressPlaceholder: string;
}

export default class AddressInput extends Component<Props> {
  autocompleteService: any = null;
  placeService: any = null;
  state: State = {
    addressPlaceholder: "",
  };

  componentDidMount() {
    setTimeout(() => {
      if (window.google && this.props.id) {
        const input = document.getElementById(
          this.props.id
        ) as HTMLInputElement;

        this.autocompleteService = new window.google.maps.places.Autocomplete(
          input,
          {
            componentRestrictions: { country: ["my", "bn"] },
            fields: ["geometry", "formatted_address", "address_component"],
          }
        );
        this.autocompleteService.addListener(
          "place_changed",
          this.handlePlaceChangeListener
        );
      }
    }, 150);
  }

  handlePlaceChangeListener = () => {
    const selectedPlace = this.autocompleteService.getPlace();
    const initialAddress: Address = {
      name: "",
      lat: 0,
      lng: 0,
      district: "",
      state: "",
      country: "",
    };
    if (selectedPlace.address_components) {
      selectedPlace.address_components.map((eachAddress: any) => {
        if (eachAddress.types.includes("country")) {
          initialAddress["country"] = eachAddress.long_name ?? "";
        } else if (eachAddress.types.includes("administrative_area_level_1")) {
          initialAddress["state"] = eachAddress.long_name ?? "";
        } else if (eachAddress.types.includes("locality")) {
          initialAddress["district"] = eachAddress.long_name ?? "";
        }
        return "";
      });
    }
    initialAddress["name"] = selectedPlace.formatted_address;
    initialAddress["lat"] = selectedPlace.geometry.location.lat();
    initialAddress["lng"] = selectedPlace.geometry.location.lng();
    this.setState({ addressPlaceholder: "" });

    if (this.props.onChange) {
      this.props.onChange(initialAddress);
    }
  };

  handleOnChange = (e: any) => {
    this.setState({
      addressPlaceholder: e.target.value,
    });
    if (this.props.onChange) {
      this.props.onChange({ name: e.target.value, lat: 0, lng: 0 });
    }
  };

  handleFocus = () => {
    this.setState({
      addressPlaceholder: "",
    });
    if (this.props.onChange) {
      this.props.onChange({ name: "", lat: 0, lng: 0 });
    }
  };

  render() {
    let defaultClassName =
      "block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 focus:border-green-500 focus:outline-none";
    if (this.props.className) {
      defaultClassName += ` ${this.props.className}`;
    }
    if (this.props.error) {
      defaultClassName += " mb-0.5 border-red-300";
    }

    return (
      <div className="w-full">
        <div className="relative">
          <div className="flex justify-between">
            {!this.props.disableTitle && (
              <label className="block text-sm font-medium text-gray-700">
                Address
              </label>
            )}
            <span className="text-sm text-gray-500" id="email-optional">
              Lat : {this.props.value.lat.toFixed(4)} Lng :{" "}
              {this.props.value.lng.toFixed(4)}
            </span>
          </div>
          <label className="sr-only">{this.props.label}</label>
          <input
            id={this.props.id}
            className={defaultClassName}
            value={
              this.state.addressPlaceholder
                ? this.state.addressPlaceholder
                : this.props.value.name
            }
            placeholder="Enter your address"
            onFocus={this.handleFocus}
            onChange={this.handleOnChange}
          />
          <span className="text-red-500 text-sm">{this.props.error}</span>
        </div>
      </div>
    );
  }
}
