import { Dialog, DialogBackdrop, Transition } from "@headlessui/react";
import moment from "moment";
import React, { Component, Fragment } from "react";
import {
  ActivityLog,
  ActivityType,
  Flag,
  Restaurant,
} from "../../models/Restaurant";
import Button from "../base/Button";
import Input from "../base/Input";
import Select, { Option } from "../base/Select";
import {
  createRestaurant,
  handleUpdateLogCondition,
} from "../store/actions/operationActions";

export interface UpdateLogAttribute {
  activityType: keyof typeof ActivityType;
  comment: string;
}

export interface UpdateLogAttributeError {
  activityTypeError: string;
  commentError: string;
}

export const flagOption: Option[] = Object.keys(Flag).map((eachFlagKey) => {
  return {
    key: eachFlagKey,
    title: Flag[eachFlagKey as keyof typeof Flag],
  };
});

const activityTypeOption: Option[] = Object.keys(ActivityType).map(
  (eachActivitykey) => {
    return {
      key: eachActivitykey,
      title: ActivityType[eachActivitykey as keyof typeof ActivityType],
    };
  }
);

interface State {
  loading: boolean;
  updateLogAttribute: UpdateLogAttribute;
  updateLogAttributeError: UpdateLogAttributeError;
  flag: keyof typeof Flag;
  attribute: string;
  attributeError: string;
}

interface Props {
  open: boolean;
  modalType: string;
  selectedRestaurant: Restaurant;
  onClose: (visible: boolean, modalType: string) => void;
  handleUpdateState: (attribute: any) => void;
}

const sampleState: State = {
  loading: false,
  updateLogAttribute: {
    activityType: "SENT_EMAIL",
    comment: "",
  },
  flag: "RED",
  attribute: "",
  attributeError: "",
  updateLogAttributeError: {
    activityTypeError: "",
    commentError: "",
  },
};

export default class OperationModal extends Component<Props> {
  state: State = JSON.parse(JSON.stringify(sampleState));

  handleCloseModal = () => {
    this.props.onClose(false, "");
    const state = JSON.parse(JSON.stringify(sampleState));
    this.setState(state);
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleInputChange = (e: any) => {
    if (this.props.modalType === "logs") {
      const clonedUpdateLogAttribute = JSON.parse(
        JSON.stringify(this.state.updateLogAttribute)
      );
      clonedUpdateLogAttribute["comment"] = e.target.value;
      this.setState({
        updateLogAttribute: clonedUpdateLogAttribute,
      });
    } else {
      this.setState({
        attribute: e.target.value,
      });
    }
  };

  handleSelectChange = (id: string, key: string) => {
    if (this.props.modalType === "flag") {
      this.setState({
        flag: key,
      });
    } else if (this.props.modalType === "logs") {
      const clonedUpdateLogAttribute = JSON.parse(
        JSON.stringify(this.state.updateLogAttribute)
      );
      clonedUpdateLogAttribute[id] = key;
      clonedUpdateLogAttribute["comment"] = "";
      this.setState({
        updateLogAttribute: clonedUpdateLogAttribute,
      });
    }
  };

  handleUpdateLogAttribute = () => {
    const conditionsList: string[] = ["activityType", "comment"];
    const clonedUpdateLogAttributeError = JSON.parse(
      JSON.stringify(this.state.updateLogAttributeError)
    );

    handleUpdateLogCondition(
      this.state.updateLogAttribute,
      clonedUpdateLogAttributeError,
      conditionsList
    );

    this.setState(
      {
        updateLogAttributeError: clonedUpdateLogAttributeError,
      },
      () => {
        if (
          !this.state.updateLogAttributeError.activityTypeError &&
          !this.state.updateLogAttributeError.commentError &&
          !this.state.attributeError
        ) {
          const activityLog: ActivityLog = {
            activityType: this.state.updateLogAttribute.activityType,
            comment: this.state.updateLogAttribute.comment,
            creationDate: moment().toDate(),
          };
          this.handleLoading(true);
          createRestaurant(
            this.props.selectedRestaurant,
            this.props.modalType,
            this.state.attribute,
            this.state.flag,
            activityLog
          );
          this.props.handleUpdateState(activityLog);
          this.handleCloseModal();
          this.handleLoading(false);
        }
      }
    );
  };

  handleUpdateOperationAttribute = async () => {
    let error = "";
    if (this.props.modalType === "email") {
      const filter =
        // eslint-disable-next-line no-useless-escape
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!filter.test(this.state.attribute.replace(/\s/g, ""))) {
        error = this.renderAttributeError();
      } else {
        error = "";
      }
    } else if (this.props.modalType === "phoneNumber") {
      const phoneFilter = /^(\+60)-*[0-9]{7,10}$/;
      if (!phoneFilter.test(this.state.attribute.replace(/\s/g, ""))) {
        error = this.renderAttributeError();
      } else {
        error = "";
      }
    } else if (this.props.modalType === "website") {
      if (this.state.attribute.replace(/\s/g, "").length <= 0) {
        error = this.renderAttributeError();
      } else {
        error = "";
      }
    }
    if (error) {
      this.setState({
        attributeError: error,
      });
    } else {
      if (!this.state.attributeError) {
        this.handleLoading(true);
        createRestaurant(
          this.props.selectedRestaurant,
          this.props.modalType,
          this.state.attribute,
          this.state.flag
        );
        if (this.props.modalType === "flag") {
          this.props.handleUpdateState(this.state.flag);
        } else {
          this.props.handleUpdateState(this.state.attribute);
        }
        this.handleCloseModal();
        this.handleLoading(false);
      }
    }
  };

  renderAttributeError = () => {
    if (this.props.modalType === "email") {
      return "Please enter the restaurant email in correct format";
    } else if (this.props.modalType === "phoneNumber") {
      return "Please enter the restaurant contact number";
    } else if (this.props.modalType === "website") {
      return "Please enter the restaurant website";
    } else {
      return "";
    }
  };

  renderTitle() {
    if (this.props.modalType === "email") {
      return "Update Email";
    } else if (this.props.modalType === "flag") {
      return "Update flag";
    } else if (this.props.modalType === "website") {
      return "Update Website";
    } else if (this.props.modalType === "phoneNumber") {
      return "Update Contact";
    } else if (this.props.modalType === "logs") {
      return "Update Activity Logs";
    }
  }

  renderPlaceHolder() {
    if (this.props.modalType === "email") {
      return "Email";
    } else if (this.props.modalType === "website") {
      return "https://www.domain.com";
    } else if (this.props.modalType === "phoneNumber") {
      return "+60123456789";
    }
  }

  renderLabel() {
    if (this.props.modalType === "email") {
      return "Email";
    } else if (this.props.modalType === "website") {
      return "Website";
    } else if (this.props.modalType === "phoneNumber") {
      return "Contact Number";
    }
  }

  renderContent() {
    if (this.state.loading) {
      return (
        <div className="mt-6">
          <div className="mx-auto flex items-center justify-center h-10 w-10 rounded-full border-4 border-t-4 border-arusgreen-100 loading-spinner" />
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-title"
            >
              Loading
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Please wait while we are updating the restaurant.
              </p>
            </div>
          </div>
        </div>
      );
    } else if (this.props.modalType === "flag") {
      return (
        <div className="mt-1 mb-40">
          <label className="block text-sm font-medium text-gray-700">
            Flag Color
          </label>
          <Select
            id="flag"
            value={this.state.flag}
            placeholder="Flag Color"
            options={flagOption}
            onChange={this.handleSelectChange}
          />
        </div>
      );
    } else if (this.props.modalType === "logs") {
      return (
        <div className="mt-1">
          <label className="block text-sm font-medium text-gray-700">
            Activity
          </label>
          <Select
            id="activityType"
            value={this.state.updateLogAttribute.activityType}
            placeholder="Activity Type"
            options={activityTypeOption}
            onChange={this.handleSelectChange}
          />
          <label className="block text-sm font-medium text-gray-700 mt-3">
            Comment
          </label>
          <Input
            id="name"
            className="mt-1 mb-4"
            placeholder={"Description"}
            value={this.state.updateLogAttribute.comment}
            error={this.state.updateLogAttributeError.commentError}
            onChange={this.handleInputChange}
          />
        </div>
      );
    } else {
      return (
        <div className="mt-1 mb-4">
          <label className="block text-sm font-medium text-gray-700">
            {this.renderLabel()}
          </label>
          <Input
            id="name"
            className="mt-1"
            placeholder={this.renderPlaceHolder()}
            value={this.state.attribute}
            error={this.state.attributeError}
            onChange={this.handleInputChange}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <Transition.Root show={this.props.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.props.open}
          onClose={this.handleCloseModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full align-bottom bg-white rounded-lg px-4 pt-5 pb-10 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:p-6">
                <div>
                  <div className=" border-b border-gray-200 pb-4">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {this.renderTitle()}
                    </Dialog.Title>
                  </div>
                  <div className="mt-2">{this.renderContent()}</div>
                  <div className="flex flex-row justify-end">
                    <Button
                      text="Cancel"
                      type="light"
                      className="mx-4"
                      onClick={this.handleCloseModal}
                    />
                    <Button
                      text="Confirm"
                      type="normal"
                      onClick={
                        this.props.modalType === "logs"
                          ? this.handleUpdateLogAttribute
                          : this.handleUpdateOperationAttribute
                      }
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
