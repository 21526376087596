import {
  CubeIcon,
  GiftIcon,
  HomeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Option, TextInput, Select } from "blixify-ui-web/lib";
import moment from "moment";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  Age,
  BankType,
  CookFrequency,
  EnterpriseStatus,
  FamilyMembers,
  Gender,
  ProfessionSector,
  UserModel,
} from "../../models/User";
import Button from "../base/Button";
import Feeds, { Feed } from "../base/Feeds";
import Input from "../base/Input";
import Overlay from "../base/Overlay";
import { updateJerryCanRedeemed } from "../store/actions/authActions";
import {
  clearPromotion,
  getPromotion,
  getPromotionList,
} from "../store/actions/promotionActions";
import {
  createUser,
  getUser,
  handleInventoryCondition,
  handleUserCondition,
  updateEnterpriseStatus,
  updateInventory,
  updateSelectedUserJerry,
} from "../store/actions/usersActions";
import { promotionStateInterface } from "../store/reducers/promotionReducer";
import { userStateInterface } from "../store/reducers/usersReducer";
import ConfirmationModal from "./ConfirmationModal";
import PromoModal from "./UpdatePromoModal";
import { Modal } from "../base/Modal";

interface Props {
  history: any;
  userStore: userStateInterface;
  promotionStore: promotionStateInterface;
  confirmationModalVisible: boolean;
  inventoryModalVisible: boolean;
  updatePromoModalVisible: boolean;
  inventoryList: any[];
  handleOpenConfirmationModal: (visible: boolean) => void;
  handleOpenInventoryVisible: (visible: boolean) => void;
  updateSelectedUserJerry: (
    id: string,
    jerryCanRedeem: Date | undefined
  ) => void;
  handleOpenPromoModal: (visible: boolean) => void;
  getPromotionList: (selectedUser: UserModel) => void;
  getInventoryWithPagination: (paginationSearch: any) => void;
  clearPromotion: () => void;
}

export interface UserState {
  email: string;
  password: string;
  name: string;
  mobileNo: string;
  promoCode: string;
}

export interface UserStateError {
  nameError: string;
  promoCodeError: string;
}

export interface UserInventoryState {
  itemName: string;
  quantity: number;
}

export interface UserInventoryStateError {
  itemNameError: string;
  quantityError: string;
}

interface State {
  loading: boolean;
  creation: boolean;
  selectedUser: UserModel;
  userScheduleAnalytics: UserScheduleAnalytics;
  userState: UserState;
  userStateError: UserStateError;
  userInventoryState: UserInventoryState;
  userInventoryStateError: UserInventoryStateError;
}

export interface UserScheduleAnalytics {
  totalSchedule: number;
  totalEstimatedWeight: number;
}

class UserEditor extends Component<Props> {
  mobileInputV2: any = createRef();
  emailInputV2: any = createRef();
  passwordInputV2: any = createRef();
  state: State = {
    loading: false,
    creation: false,
    selectedUser: {
      id: "",
      email: "",
      name: "",
      gender: "M",
      age: "M",
      profession: "ACC",
      mobileNo: "",
      familyMembers: "S",
      cookFrequency: "AVERAGE",
      createdAt: new Date(),
    },
    userScheduleAnalytics: {
      totalSchedule: 0,
      totalEstimatedWeight: 0,
    },
    userState: {
      email: "",
      password: "",
      name: "",
      mobileNo: "",
      promoCode: "",
    },
    userStateError: {
      nameError: "",
      promoCodeError: "",
    },
    userInventoryState: {
      itemName: "",
      quantity: 0,
    },
    userInventoryStateError: {
      itemNameError: "",
      quantityError: "",
    },
  };

  componentDidMount = () => {
    this.handleGetSelectedUser();
  };

  componentWillUnmount() {
    this.props.updateSelectedUserJerry("", undefined);
    this.props.clearPromotion();
  }

  handleGetSelectedUser = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId && selectedId !== "new") {
      const userData = await getUser(selectedId);
      if (typeof userData === "string") {
        this.handleBack();
      } else {
        this.props.updateSelectedUserJerry(
          userData.user.id,
          userData.user.jerryCanRedeem ?? undefined
        );
        this.setState(
          {
            selectedUser: userData.user,
            userScheduleAnalytics: userData.userScheduleAnalytics,
          },
          () => {
            this.props.getPromotionList(this.state.selectedUser);
          }
        );
      }
    } else if (selectedId && selectedId === "new") {
      this.setState({
        creation: true,
      });
    } else {
      this.handleBack();
    }
  };

  handleBack = () => {
    this.props.history.push("/dashboard/users");
  };

  handleLoading = (loading: boolean) => {
    this.setState({ loading });
  };

  handleInventoryInputChange = (e: any) => {
    const clonedUserInventoryState = JSON.parse(
      JSON.stringify(this.state.userInventoryState)
    );
    clonedUserInventoryState[e.target.id] = e.target.value;
    this.setState({
      userInventoryState: clonedUserInventoryState,
    });
  };

  handleInventorySelectChange = (key: string) => {
    const clonedUserInventoryState = JSON.parse(
      JSON.stringify(this.state.userInventoryState)
    );
    clonedUserInventoryState["itemName"] = key;
    this.setState({
      userInventoryState: clonedUserInventoryState,
    });
  };

  handleInputChange = (e: any) => {
    const clonedUserState = JSON.parse(JSON.stringify(this.state.userState));
    clonedUserState[e.target.id] = e.target.value;
    this.setState({
      userState: clonedUserState,
    });
  };

  handleSelectChange = (id: string, key: string) => {
    const clonedUserState = JSON.parse(JSON.stringify(this.state.userState));
    clonedUserState[id] = key;
    this.setState({
      userState: clonedUserState,
    });
  };

  handleUpdateEnterpriseStatus = async (
    status: keyof typeof EnterpriseStatus
  ) => {
    await updateEnterpriseStatus(this.state.selectedUser.id, status);
    const userData = await getUser(this.state.selectedUser.id);
    if (typeof userData === "string") {
      this.handleBack();
    } else {
      this.props.updateSelectedUserJerry(
        userData.user.id,
        userData.user.jerryCanRedeem ?? undefined
      );
      this.setState({
        selectedUser: userData.user,
        userScheduleAnalytics: userData.userScheduleAnalytics,
      });
    }
  };

  handleCloseInventory = () => {
    this.setState({
      userInventoryState: {
        itemName: "",
        quantity: 0,
      },
      userInventoryStateError: {
        itemNameError: "",
        quantityError: "",
      },
    });
    this.props.handleOpenInventoryVisible(false);
  };

  handleUpdateInventory = async () => {
    const clonedUserInventoryError = JSON.parse(
      JSON.stringify(this.state.userInventoryStateError)
    );
    handleInventoryCondition(
      this.state.userInventoryState,
      clonedUserInventoryError,
      ["itemName", "quantity"]
    );

    this.setState(
      {
        userInventoryStateError: clonedUserInventoryError,
      },
      async () => {
        if (
          !this.state.userInventoryStateError.itemNameError &&
          !this.state.userInventoryStateError.quantityError
        ) {
          const newItemList = this.state.selectedUser.items ?? [];
          newItemList?.push({
            inventoryId: this.state.userInventoryState.itemName,
            quantity: Number(this.state.userInventoryState.quantity),
            date: moment().toDate(),
          });
          const itemsError = await updateInventory(
            this.props.userStore.selectedUser.id,
            newItemList
          );
          if (!itemsError) {
            this.props.history.push("/dashboard/users");
            this.props.handleOpenInventoryVisible(false);
          }
        }
      }
    );
  };

  handleRedeemJerryCan = async () => {
    const redeemError = await updateJerryCanRedeemed(
      this.props.userStore.selectedUser.id
    );
    if (redeemError) {
    } else {
      this.props.history.push("/dashboard/users");
      this.props.updateSelectedUserJerry("", undefined);
    }
  };

  handleSubmit = async () => {
    this.handleLoading(true);
    let conditionsList: string[] = ["name"];
    let mobileInputCondition = false;
    let emailInputCondition = true;
    let passwordInputCondition = true;
    if (this.mobileInputV2.current)
      mobileInputCondition = await this.mobileInputV2.current.handleSubmit();
    if (this.state.userState.email) {
      if (this.emailInputV2.current)
        emailInputCondition = await this.emailInputV2.current.handleSubmit();
      if (this.passwordInputV2.current)
        passwordInputCondition =
          await this.passwordInputV2.current.handleSubmit();
    }
    const clonedUserError = JSON.parse(
      JSON.stringify(this.state.userStateError)
    );
    handleUserCondition(this.state.userState, clonedUserError, conditionsList);

    this.setState(
      {
        userStateError: clonedUserError,
      },
      async () => {
        if (
          !this.state.userStateError.nameError &&
          mobileInputCondition &&
          emailInputCondition &&
          passwordInputCondition
        ) {
          let promoPresent = false;
          if (this.state.userState.promoCode) {
            const promoCodeError = await getPromotion(
              this.state.userState.promoCode
            );
            if (typeof promoCodeError !== "string") {
              promoPresent = true;
            }
          } else {
            promoPresent = true;
          }

          if (promoPresent) {
            clonedUserError["promoCodeError"] = "";
            const createUserResponse = await createUser(this.state.userState);
            if (createUserResponse.error) {
              clonedUserError["nameError"] = "User may exist in our database";
            } else {
              this.props.history.push("/dashboard/users?data=r");
            }
          } else {
            clonedUserError["promoCodeError"] = "Promo code cannot be found";
          }
          this.setState({ userStateError: clonedUserError });
        }
        this.handleLoading(false);
      }
    );
  };

  renderBreadcrumb = () => {
    return (
      <nav
        className="bg-white border-b border-gray-200 flex mt-0.5 mx-auto w-full"
        aria-label="Breadcrumb"
      >
        <ol className="max-w-6xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8 ">
          <li className="flex">
            <div className="flex items-center">
              <p
                className="text-gray-600 hover:text-arusgreen-500 cursor-pointer"
                onClick={this.handleBack}
              >
                <HomeIcon className="flex-shrink-0 h-5 w-5" />
              </p>
            </div>
          </li>
          {this.renderBreadcrumbItem(
            this.state.creation ? "New User" : "User Details"
          )}
        </ol>
      </nav>
    );
  };

  renderBreadcrumbItem = (title: String) => {
    return (
      <li className="flex">
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <p className="ml-4 text-sm font-medium text-gray-500">{title}</p>
        </div>
      </li>
    );
  };

  renderContent = () => {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    let defaultWebUrl =
      "https://firebasestorage.googleapis.com/v0/b/arusoil-web-dev.appspot.com/o/users%2F";
    if (isProd) {
      defaultWebUrl =
        "https://firebasestorage.googleapis.com/v0/b/arusoil-web.appspot.com/o/users%2F";
    }

    if (this.state.creation) {
      const emailInput = this.state.userState.email;
      return (
        <div className="bg-white shadow-xl border-t border-gray-200 py-2 sm:p-0 sm:rounded-lg grid grid-cols-1 lg:grid-cols-2">
          <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <Input
                  id="name"
                  className="mt-1"
                  placeholder="Name"
                  value={this.state.userState.name}
                  error={this.state.userStateError.nameError}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <TextInput
                  ref={this.mobileInputV2}
                  id="mobileNo"
                  label="Mobile Number"
                  type="mobile"
                  mobileWhitelist={["BN", "MY"]}
                  placeholder="Mobile Number"
                  value={this.state.userState.mobileNo}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <TextInput
                  ref={this.emailInputV2}
                  id="email"
                  label={"Email" + (!emailInput ? " (Optional)" : "")}
                  type="email"
                  placeholder="Email"
                  value={this.state.userState.email}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <TextInput
                  ref={this.passwordInputV2}
                  id="password"
                  label={"Password" + (!emailInput ? " (Optional)" : "")}
                  type="password"
                  placeholder="Password"
                  value={this.state.userState.password}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Promo Code Redeemption
                </label>
                <Input
                  id="promoCode"
                  className="mt-1"
                  placeholder="Promotion Code"
                  value={this.state.userState.promoCode}
                  error={this.state.userStateError.promoCodeError}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="sm:col-span-2 sm:flex sm:justify-end mt-5">
                <Button
                  className="w-full sm:w-auto"
                  text="Submit"
                  type="normal"
                  onClick={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            {this.state.selectedUser.image && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Profile Picture
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <img
                    className="h-8 w-8 rounded-full object-cover"
                    src={`${defaultWebUrl}${this.state.selectedUser.id}%2Fprofile.jpg?alt=media&token=${this.state.selectedUser.image}`}
                    alt=""
                  />
                </dd>
              </div>
            )}
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.selectedUser.email}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.selectedUser.name}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Creation Date
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {moment(
                  this.state.selectedUser.createdAt.seconds * 1000
                ).format("DD-MMMM-YYYY")}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Gender</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {Gender[this.state.selectedUser.gender]}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Phone Number
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.selectedUser.mobileNo}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Age</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {Age[this.state.selectedUser.age]}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.selectedUser.address?.name ?? "Has not been added"}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                How Often Cook at Home
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {CookFrequency[this.state.selectedUser.cookFrequency]}
              </dd>
            </div>
            {this.state.selectedUser.profession === "OTHERS" ? (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Profession
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.selectedUser.professionOther}
                </dd>
              </div>
            ) : (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Profession Sector
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {ProfessionSector[this.state.selectedUser.profession]}
                </dd>
              </div>
            )}
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Number of Family Members
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {FamilyMembers[this.state.selectedUser.familyMembers]}
              </dd>
            </div>
            {this.state.selectedUser.enterprise && (
              <>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Enterprise SSM
                  </dt>
                  <dd className="mt-1 font-bold text-sm sm:mt-0 sm:col-span-2">
                    <a
                      href={`${defaultWebUrl}${this.state.selectedUser.id}%2F${this.state.selectedUser.enterprise}?alt=media&token=${this.state.selectedUser.enterpriseToken}`}
                      target="_blank"
                      rel="noreferrer"
                      className="cursor-pointer text-arusgreen-600 hover:text-arusgreen-300"
                    >
                      Download PDF
                    </a>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Enterprise Status
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {
                      EnterpriseStatus[
                        this.state.selectedUser.enterpriseStatus ?? "PEND"
                      ]
                    }
                    {this.state.selectedUser.enterpriseStatus !== "ACC" ? (
                      <span
                        className="text-arusgreen-600 cursor-pointer text-sm mt-1 ml-12 font-bold"
                        onClick={this.handleUpdateEnterpriseStatus.bind(
                          this,
                          "ACC"
                        )}
                      >
                        Approve
                      </span>
                    ) : (
                      <span
                        className="text-arusgreen-600 cursor-pointer text-sm mt-1 ml-12 font-bold"
                        onClick={this.handleUpdateEnterpriseStatus.bind(
                          this,
                          "REJ"
                        )}
                      >
                        Reject
                      </span>
                    )}
                  </dd>
                </div>
              </>
            )}
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created At</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {moment(
                  this.state.selectedUser.createdAt.seconds * 1000
                ).format("DD/MM/YY")}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Bank Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.selectedUser.bankName
                  ? BankType[this.state.selectedUser.bankName]
                  : "-"}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Bank Account ID
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.selectedUser.bankAccountNo ?? "-"}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Bank Account Name
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.selectedUser.bankAccountName ?? "-"}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Collection Scheduled
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.userScheduleAnalytics.totalSchedule} time(s)
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Average Weight of UCO per Collection
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.userScheduleAnalytics.totalEstimatedWeight} kg
              </dd>
            </div>
            {this.state.selectedUser.jerryCanRedeem && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Redeemed Jerry Can
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {moment(
                    this.state.selectedUser.jerryCanRedeem?.seconds * 1000
                  ).format("DD/MM/YY")}
                </dd>
              </div>
            )}
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Promo Code</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.selectedUser.promotions &&
                this.state.selectedUser.promotions?.length > 0
                  ? this.state.selectedUser.promotions[
                      this.state.selectedUser.promotions.length - 1
                    ].id
                  : "No promotion applied"}
                <span
                  className="text-sm font-medium text-arusgreen-500 ml-4 cursor-pointer"
                  onClick={this.props.handleOpenPromoModal.bind(this, true)}
                >
                  Update
                </span>
              </dd>
            </div>
          </dl>
        </div>
      );
    }
  };

  renderInventoryList = () => {
    const list: Feed[] = [];
    this.state.selectedUser.items?.map((eachItems) => {
      list.push({
        step: "",
        iconClass: "bg-arusgreen-600",
        iconType: <CubeIcon className="h-5 w-5 text-white" />,
        content: `
          ${
            this.props.inventoryList.find(
              (eachItem) => eachItem.id === eachItems.inventoryId
            ).itemName ?? eachItems.inventoryId
          } x ${eachItems.quantity}`,
      });
      return null;
    });
    return list;
  };

  renderFeedsContent = () => {
    let promotionList: Feed[] = [];
    if (
      this.state.selectedUser.promotions &&
      this.props.promotionStore.promotions
    ) {
      const promotionLength = this.state.selectedUser.promotions.length;
      this.state.selectedUser.promotions.map((eachUserPromotion, index) => {
        this.props.promotionStore.promotions.map((eachPromotion) => {
          const rateContent =
            eachPromotion.type === "FLAT"
              ? `RM${eachPromotion.fixedPriceRate} of fixed rate`
              : `${eachPromotion.increasePercentRate}% increase of selling rates`;
          const description = `Enjoy ${rateContent} for ${
            eachPromotion.durationAppliedInMonths
          } month(s) - Applied on ${moment(
            eachUserPromotion.appliedDate.seconds * 1000
          ).format("DD/MM/YY")} `;
          if (eachUserPromotion.id === eachPromotion.id) {
            promotionList.push({
              iconClass:
                promotionLength === index + 1
                  ? "bg-arusgreen-600"
                  : "bg-red-600",
              iconType:
                promotionLength === index + 1 ? (
                  <GiftIcon className="h-5 w-5 text-white" />
                ) : (
                  <XMarkIcon className="h-5 w-5 text-white" />
                ),
              content: `Promotion Code - ${
                eachUserPromotion.id
              } is applied on ${moment(
                eachUserPromotion.appliedDate.seconds * 1000
              ).format("DD/MM/YY")}`,
              description: description,
              step: "",
            });
          }
          return "";
        });
        return "";
      });
    }
    return promotionList;
  };

  renderInventoriesOption = () => {
    const inventoryOption: Option[] = [];
    this.props.inventoryList.map((eachInventories) => {
      inventoryOption.push({
        key: eachInventories.id,
        label: eachInventories.itemName,
      });
      return null;
    });
    return inventoryOption;
  };

  renderInventoriesContent = () => {
    return (
      <>
        <div className="grid grid-cols-1 gap-y-2 z-10">
          <>
            <Select
              id="itemName"
              type="default"
              label="Inventory"
              options={this.renderInventoriesOption()}
              value={this.state.userInventoryState.itemName}
              onSearch={(value) => {
                if (value) this.props.getInventoryWithPagination(value);
              }}
              onChange={this.handleInventorySelectChange}
            />
            <span className="text-red-500 text-sm">
              {this.state.userInventoryStateError.itemNameError}
            </span>
          </>
          <>
            <TextInput
              id="quantity"
              label="Quantity"
              value={
                this.state.userInventoryState.quantity === 0
                  ? ""
                  : this.state.userInventoryState.quantity
              }
              placeholder=""
              type="number"
              onChange={this.handleInventoryInputChange}
            />
            <span className="text-red-500 text-sm">
              {this.state.userInventoryStateError.quantityError}
            </span>
          </>
        </div>
        <div className="flex flex-row justify-end mt-10">
          <Button
            text="Cancel"
            type={"light"}
            className="mx-4"
            onClick={this.handleCloseInventory}
          />
          <Button
            text="Confirm"
            type="normal"
            onClick={this.handleUpdateInventory}
          />
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal
          title="Update Inventory"
          open={this.props.inventoryModalVisible}
          renderContent={this.renderInventoriesContent}
          // onClose={this.props.handleOpenInventoryVisible.bind(this, false)}
        />
        <ConfirmationModal
          onClose={this.props.handleOpenConfirmationModal}
          title="Jerry Can Redeemed Confirmation"
          open={this.props.confirmationModalVisible}
          content={`Are you sure that this user's jerry can has been redeemed?`}
          onClick={this.handleRedeemJerryCan}
        />
        <PromoModal
          open={this.props.updatePromoModalVisible}
          selectedUser={this.state.selectedUser}
          selectedUserPromotion={this.state.selectedUser.promotions ?? []}
          handleGetSelectedUser={this.handleGetSelectedUser}
          onClose={this.props.handleOpenPromoModal}
        />
        <Overlay loading={this.state.loading} text="Creating User" />
        {this.renderBreadcrumb()}
        <div className="mt-8">
          <div className="block mt-2">
            <div className="max-w-6xl mx-auto flex flex-col px-4 sm:px-6 lg:px-8">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {this.state.creation ? "New User" : "User Details"}
                  </h3>
                </div>
                {this.renderContent()}
              </div>
            </div>
          </div>
        </div>
        {!this.state.creation &&
          this.state.selectedUser.promotions &&
          this.state.selectedUser.promotions.length > 0 && (
            <div className="mt-8">
              <div className="block mt-2">
                <div className="max-w-6xl mx-auto flex flex-col px-4 sm:px-6 lg:px-8">
                  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Promo Code Details
                      </h3>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <div className="p-5 flow-root">
                        <Feeds content={this.renderFeedsContent()} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {this.state.selectedUser.items &&
          this.state.selectedUser?.items?.length > 0 && (
            <div className="max-w-6xl mx-auto flex flex-col px-4 sm:px-6 lg:px-8 mt-5">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Inventory Lists
                  </h3>
                </div>
                <div className="border-t border-gray-200 sm:p-0">
                  <div className="p-5 flow-root">
                    <Feeds content={this.renderInventoryList()} />
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    promotionStore: state.promotionStore,
    userStore: state.userStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getPromotionList: (selectedUser: UserModel) =>
      dispatch(getPromotionList(selectedUser)),
    clearPromotion: () => dispatch(clearPromotion()),
    updateSelectedUserJerry: (id: string, jerryCanRedeem: Date | undefined) =>
      dispatch(updateSelectedUserJerry(id, jerryCanRedeem)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditor);
