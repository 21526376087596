import React, { Component } from "react";
import { connect } from "react-redux";
import Table, { TableHeader } from "../base/Table";
import { rewardDataLimit } from "../store/actions/rewardActions";
import { rewardStateInterface } from "../store/reducers/rewardReducer";

interface Props {
  history: any;
  pageIndex: number;
  rewardStore: rewardStateInterface;
  handleUpdatePageIndex: (pageIndex: number) => void;
  getRewardWithPagination: (paginationStartAt: string | Date) => void;
}

class Reward extends Component<Props> {
  handleSelectedData = (id: string) => {
    this.props.history.push(`/dashboard/rewardEditor?data=${id}`);
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      //INFO : Check whether need to get new data from firebase
      if (
        this.props.rewardStore.lastCursor &&
        this.props.pageIndex * rewardDataLimit + 1 >
          this.props.rewardStore.reward.length
      ) {
        this.props.getRewardWithPagination(this.props.rewardStore.lastCursor);
      }

      this.props.handleUpdatePageIndex(this.props.pageIndex + 1);
    } else {
      this.props.handleUpdatePageIndex(this.props.pageIndex - 1);
    }
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (this.props.rewardStore.reward?.length > 0) {
      this.props.rewardStore.reward.map((eachReward) => {
        listView.push({
          id: eachReward.id,
          name: eachReward.name,
          description: eachReward.description.substring(0, 40),
          validity: eachReward.validity,
          oneliner: eachReward.oneliner,
          importance: eachReward.importance,
          website: eachReward.website,
          rewardLogo: eachReward.rewardLogo,
          rewardBanner: eachReward.rewardBanner,
          value: `RM ${eachReward.value}`,
          category: eachReward.category,
          tag: eachReward.tag,
        });
        return null;
      });
    }
    return listView;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "name",
        title: "Name",
      },
      {
        key: "description",
        title: "Description",
      },
      {
        key: "validity",
        title: "Validity",
      },
      {
        key: "category",
        title: "Category",
      },
      {
        key: "value",
        title: "Value",
      },
    ];
    return (
      <div className="mt-8">
        <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
          Reward List
        </h2>
        <Table
          loading={this.props.rewardStore.loading}
          header={headers}
          pageIndex={this.props.pageIndex}
          lastCursor={this.props.rewardStore.lastCursor}
          data={this.renderFilteredData()}
          dataLimit={rewardDataLimit}
          onClickRow={this.handleSelectedData}
          handlePage={this.handlePagination}
          newTabUrl="/dashboard/rewardEditor?data="
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    rewardStore: state.rewardStore,
  };
};

export default connect(mapStateToProps)(Reward);
