import firebase from "firebase";
import moment from "moment";
import { ActivityLog, Flag, Restaurant } from "../../../models/Restaurant";
import {
  SearchState,
  SearchStateError,
} from "../../controller/OperationController";
import {
  UpdateLogAttribute,
  UpdateLogAttributeError,
} from "../../dashboard/UpdateOperationModal";

export const operationDataLimit = 10;

export const getRestaurantWithPagination = (
  paginationStartAfter: string | Date,
  paginationFlag: keyof typeof Flag | string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let restaurantSnapshot;
      let restaurantCollectionQuery;

      if (paginationFlag) {
        restaurantCollectionQuery = firebase
          .firestore()
          .collection("restaurants")
          .where("flag", "==", paginationFlag)
          .orderBy("createdAt", "desc")
          .limit(operationDataLimit);
      } else {
        restaurantCollectionQuery = firebase
          .firestore()
          .collection("restaurants")
          .orderBy("createdAt", "desc")
          .limit(operationDataLimit);
      }

      let restaurantList: Restaurant[] = getState().operationStore.restaurants;
      const newRestaurantList: Restaurant[] = [];

      updateRestaurantLoadingState(dispatch, true);

      if (paginationStartAfter) {
        restaurantSnapshot = await restaurantCollectionQuery
          .startAfter(paginationStartAfter)
          .get();
      } else {
        restaurantSnapshot = await restaurantCollectionQuery.get();
      }

      if (restaurantSnapshot) {
        restaurantSnapshot.forEach((eachDoc: any) => {
          const eachRestaurant = eachDoc.data() as Restaurant;
          newRestaurantList.push(eachRestaurant);
        });
      }
      if (newRestaurantList.length > 0) {
        let lastCursor: string | Date = "";
        if (newRestaurantList.length === operationDataLimit) {
          lastCursor = newRestaurantList[operationDataLimit - 1].createdAt;
        }

        dispatch({
          type: "UPDATE_RESTAURANT_LIST",
          payload: {
            restaurants: restaurantList.concat(newRestaurantList),
            lastCursor: lastCursor,
          },
        });
      } else {
        dispatch({
          type: "UPDATE_RESTAURANT_LIST",
          payload: {
            restaurants: restaurantList,
            lastCursor: "",
          },
        });
      }

      updateRestaurantLoadingState(dispatch, false);
    } catch (err: any) {
      updateRestaurantLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const updatePagination = (pagination: any | null) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_PAGINATION",
      payload: {
        pagination: pagination,
      },
    });
  };
};

export const handleSearchStateCondition = (
  searchState: SearchState,
  searchError: SearchStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "radius":
        if (searchState.radius <= 0) {
          searchError["radiusError"] =
            "Please enter the correct amount of radius";
        } else {
          searchError["radiusError"] = "";
        }
        break;
      case "address":
        if (
          searchState.address.name.replace(/\s/g, "").length <= 0 ||
          !searchState.address.lat ||
          !searchState.address.lng
        ) {
          searchError["addressError"] = "Please select a valid location";
        } else {
          searchError["addressError"] = "";
        }
        break;
      case "searchType":
        if (searchState.searchType.replace(/\s/g, "").length <= 0) {
          searchError["typeError"] = "Please select a valid location";
        } else {
          searchError["typeError"] = "";
        }
        break;
    }
    return null;
  });
};

export const handleUpdateLogCondition = (
  logState: UpdateLogAttribute,
  logError: UpdateLogAttributeError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "activityType":
        if (logState.activityType.replace(/\s/g, "").length <= 0) {
          logError["activityTypeError"] = "Please select an activity";
        } else {
          logError["activityTypeError"] = "";
        }
        break;
      case "comment":
        if (logState.comment.replace(/\s/g, "").length <= 0) {
          logError["commentError"] =
            "Please enter the comment for this activity";
        } else {
          logError["commentError"] = "";
        }
        break;
    }
    return "";
  });
};

export const createRestaurant = async (
  selectedRestaurant: Restaurant,
  modalType: string,
  attribute?: string,
  flag?: keyof typeof Flag,
  log?: ActivityLog
) => {
  try {
    const restaurantRef = firebase
      .firestore()
      .collection("restaurants")
      .doc(selectedRestaurant.id);

    const restaurantSnapshot = await restaurantRef.get();

    if (restaurantSnapshot.exists) {
      const restaurantData = restaurantSnapshot.data();

      if (modalType === "flag") {
        await restaurantRef.update({
          flag,
          createdAt: moment().toDate(),
        });
      } else if (modalType === "website") {
        await restaurantRef.update({
          website: attribute,
          createdAt: moment().toDate(),
        });
      } else if (modalType === "phoneNumber") {
        await restaurantRef.update({
          phoneNumber: attribute,
          createdAt: moment().toDate(),
        });
      } else if (modalType === "email") {
        await restaurantRef.update({
          email: attribute,
          createdAt: moment().toDate(),
        });
      } else if (modalType === "logs") {
        if (log) {
          if (restaurantData) {
            restaurantData.activityLogs.push(log);
            await restaurantRef.update({
              activityLogs: restaurantData.activityLogs,
              createdAt: moment().toDate(),
            });
          }
        }
      }
    } else {
      let restaurantModel: Restaurant = {
        id: selectedRestaurant.id,
        name: selectedRestaurant.name,
        address: selectedRestaurant.address,
        businessStatus: selectedRestaurant.businessStatus,
        createdAt: moment().toDate(),
        activityLogs: [],
        email: selectedRestaurant.email ?? "",
        phoneNumber: selectedRestaurant.phoneNumber ?? "",
        website: selectedRestaurant.website ?? "",
      };

      if (modalType === "flag") {
        restaurantModel.flag = flag;
      } else if (modalType === "website") {
        restaurantModel.website = attribute;
      } else if (modalType === "phoneNumber") {
        restaurantModel.phoneNumber = attribute;
      } else if (modalType === "email") {
        restaurantModel.email = attribute;
      } else if (modalType === "logs") {
        if (log) {
          restaurantModel.activityLogs.push(log);
        }
      }
      restaurantRef.set(restaurantModel);
    }
  } catch (err) {}
};

export const getFilteredRestaurants = (placeIds: string[]) => {
  return async (dispatch: any, getState: any) => {
    try {
      const newRestaurantList: Restaurant[] = [];
      const placeIdList = JSON.parse(JSON.stringify(placeIds));
      if (placeIdList.length > 0) {
        while (placeIdList.length > 0) {
          const splicePlaceIdList = placeIdList.splice(0, operationDataLimit);
          const restaurantSnapshot = await firebase
            .firestore()
            .collection("restaurants")
            .where("id", "in", splicePlaceIdList)
            .get();
          if (restaurantSnapshot) {
            restaurantSnapshot.forEach((eachRestaurant: any) => {
              const eachRestaurantData = eachRestaurant.data() as Restaurant;
              newRestaurantList.push(eachRestaurantData);
            });
          }
        }
      }
      dispatch({
        type: "UPDATE_SEARCH_RESTAURANT_LIST",
        payload: {
          searchRestaurants: newRestaurantList,
        },
      });
    } catch (err) {}
  };
};

const updateRestaurantLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_RESTAURANT_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const clearRestaurant = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_RESTAURANT_LIST",
      payload: {
        restaurants: [],
        lastCursor: "",
      },
    });
  };
};

export const clearSearchRestaurants = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_SEARCH_RESTAURANT_LIST",
      payload: {
        searchRestaurants: [],
      },
    });
  };
};
