import { HomeIcon } from "@heroicons/react/24/outline";
import React, { Component } from "react";
import { connect } from "react-redux";
import Ngo, { Address } from "../../models/Ngo";
import { BankType } from "../../models/User";
import Button from "../base/Button";
import Input from "../base/Input";
import Overlay from "../base/Overlay";
import Select, { Option } from "../base/Select";
import UploadInput from "../base/UploadInput";
import {
  createNgo,
  deleteNgo,
  getNgo,
  handleNGOCondition,
  updateNgo,
  updateSelectedNgo,
} from "../store/actions/ngoActions";
import { getDistrictList, getStateList } from "../utils/districtList";
import ConfirmationModal from "./ConfirmationModal";

interface Props {
  history: any;
  confirmationModalVisible: boolean;
  handleOpenConfirmationModal: (visible: boolean) => void;
  updateSelectedNgo: (selectedNgo: Ngo | null) => void;
}

export interface NgoStateAttribute {
  id: string;
  name: string;
  description: string;
  ros: string;
  personInChargeName: string;
  personInChargeMobile: string;
  oneliner: string;
  targetBeneficiaries: string;
  purpose: string;
  ngoLogo: "" | File;
  ngoBanner: "" | File;
  address: Address;
  importance: number;
  bankName: keyof typeof BankType | "";
  bankAccountName: string;
  bankAccountNo: string;
  website?: string;
}

export interface NgoStateError {
  nameError: string;
  descriptionError: string;
  rosError: string;
  personInChargeNameError: string;
  personInChargeMobileError: string;
  onelinerError: string;
  targetBeneficiariesError: string;
  purposeError: string;
  ngoLogoError: string;
  ngoBannerError: string;
  addressStreetError: string;
  addressPoscodeError: string;
  importanceError: string;
  websiteError: string;
  logoTokenError: string;
  bannerTokenError: string;
  bankNameError: string;
  bankAccountNameError: string;
  bankAccountNoError: string;
}

interface State {
  creation: boolean;
  loading: boolean;
  ngoState: NgoStateAttribute;
  ngoStateError: NgoStateError;
}

const bankOption: Option[] = Object.keys(BankType).map((eachBankKey) => {
  return {
    key: eachBankKey,
    title: BankType[eachBankKey as keyof typeof BankType],
  };
});

class NgoEditor extends Component<Props> {
  state: State = {
    creation: false,
    loading: false,
    ngoState: {
      id: "",
      name: "",
      description: "",
      ros: "",
      personInChargeName: "",
      personInChargeMobile: "",
      oneliner: "",
      purpose: "",
      targetBeneficiaries: "",
      ngoLogo: "",
      ngoBanner: "",
      address: {
        district: "Batu Pahat",
        poscode: "",
        state: "Johor",
        street: "",
      },
      importance: 0,
      bankAccountName: "",
      bankAccountNo: "",
      bankName: "AFFIN",
      website: "",
    },
    ngoStateError: {
      nameError: "",
      descriptionError: "",
      rosError: "",
      personInChargeNameError: "",
      personInChargeMobileError: "",
      onelinerError: "",
      purposeError: "",
      targetBeneficiariesError: "",
      ngoLogoError: "",
      ngoBannerError: "",
      addressStreetError: "",
      addressPoscodeError: "",
      importanceError: "",
      websiteError: "",
      logoTokenError: "",
      bannerTokenError: "",
      bankAccountNameError: "",
      bankAccountNoError: "",
      bankNameError: "",
    },
  };

  componentDidMount = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId && selectedId !== "new") {
      const ngoData = await getNgo(selectedId);
      if (typeof ngoData === "string") {
        this.handleBack();
      } else {
        this.setState(
          {
            ngoState: ngoData,
          },
          () => {
            this.props.updateSelectedNgo(ngoData);
          }
        );
      }
    } else if (selectedId === "new") {
      this.setState({ creation: true });
    } else {
      this.handleBack();
    }
  };

  componentWillUnmount() {
    this.props.updateSelectedNgo(null);
  }

  handleCloneNgoState = () => {
    const currentLogoImage = this.state.ngoState.ngoLogo;
    const currentBannerImage = this.state.ngoState.ngoBanner;
    const clonedNgoState = JSON.parse(JSON.stringify(this.state.ngoState));

    clonedNgoState["ngoLogo"] = currentLogoImage;
    clonedNgoState["ngoBanner"] = currentBannerImage;
    return clonedNgoState;
  };

  handleInputChange = (e: any) => {
    const clonedNgoState = this.handleCloneNgoState();
    clonedNgoState[e.target.id] = e.target.value;
    this.setState({
      ngoState: clonedNgoState,
    });
  };

  handleSelectChangeAddress = (id: string, key: string) => {
    const clonedNgoState = this.handleCloneNgoState();
    clonedNgoState["address"][id] = key;
    this.setState({
      ngoState: clonedNgoState,
    });
  };

  handleSelectChange = (id: string, key: string) => {
    const clonedNgoState = this.handleCloneNgoState();

    clonedNgoState[id] = key;
    this.setState({
      ngoState: clonedNgoState,
    });
  };

  handleAddressInputChange = (attribute: string, e: any) => {
    const clonedNgoState = this.handleCloneNgoState();
    clonedNgoState[e.target.id][attribute] = e.target.value;
    this.setState({
      ngoState: clonedNgoState,
    });
  };

  handleUploadChange = (id: string, e: any) => {
    const clonedNgoState = this.handleCloneNgoState();
    clonedNgoState[id] = e.target.files[0];
    this.setState({
      ngoState: clonedNgoState,
    });
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleBack = () => {
    this.props.history.push("/dashboard/ngos");
  };

  handleDeleteNgo = async () => {
    await deleteNgo(this.state.ngoState.id);
    this.props.history.push("/dashboard/ngos?data=r");
  };

  handleSubmit = (type?: string) => {
    let typeList: string[] = [
      "name",
      "description",
      "personInChargeName",
      "personInChargeMobile",
      "addressStreet",
      "addressPostcode",
      "importance",
      "oneliner",
      "targetBeneficiaries",
      "purpose",
      "bankName",
      "bankAccountName",
      "bankAccountNo",
    ];

    if (this.state.creation) {
      typeList.push("ngoLogo");
      typeList.push("ngoBanner");
    }

    const clonedNgoError = JSON.parse(JSON.stringify(this.state.ngoStateError));
    handleNGOCondition(this.state.ngoState, clonedNgoError, typeList);

    this.setState(
      {
        ngoStateError: clonedNgoError,
      },
      async () => {
        const logoCreationError =
          this.state.creation && this.state.ngoStateError.logoTokenError;
        const bannerCreationError =
          this.state.creation && this.state.ngoStateError.bannerTokenError;
        if (
          !logoCreationError &&
          !bannerCreationError &&
          !this.state.ngoStateError.nameError &&
          !this.state.ngoStateError.descriptionError &&
          !this.state.ngoStateError.rosError &&
          !this.state.ngoStateError.personInChargeNameError &&
          !this.state.ngoStateError.personInChargeMobileError &&
          !this.state.ngoStateError.ngoLogoError &&
          !this.state.ngoStateError.ngoBannerError &&
          !this.state.ngoStateError.addressStreetError &&
          !this.state.ngoStateError.addressPoscodeError &&
          !this.state.ngoStateError.importanceError &&
          !this.state.ngoStateError.onelinerError &&
          !this.state.ngoStateError.purposeError &&
          !this.state.ngoStateError.targetBeneficiariesError &&
          !this.state.ngoStateError.bankNameError &&
          !this.state.ngoStateError.bankAccountNameError &&
          !this.state.ngoStateError.bankAccountNoError
        ) {
          this.handleLoading(true);

          let creationError = "";
          if (this.state.creation) {
            creationError = await createNgo(this.state.ngoState);
          } else {
            creationError = await updateNgo(this.state.ngoState);
          }
          if (creationError) {
            const clonedNgoError = JSON.parse(
              JSON.stringify(this.state.ngoStateError)
            );
            clonedNgoError["nameError"] = creationError;
            this.setState({
              ngoStateError: clonedNgoError,
            });
            this.handleLoading(false);
          } else {
            this.props.history.push("/dashboard/ngos?data=r");
          }
        }
      }
    );
  };

  renderBreadcrumb = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    return (
      <nav
        className="bg-white border-b border-gray-200 flex mt-0.5 mx-auto w-full"
        aria-label="Breadcrumb"
      >
        <ol className="max-w-6xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8 ">
          <li className="flex">
            <div className="flex items-center">
              <p
                className="text-gray-600 hover:text-arusgreen-500 cursor-pointer"
                onClick={() => {
                  this.props.history.push("/dashboard/ngos");
                }}
              >
                <HomeIcon className="flex-shrink-0 h-5 w-5" />
              </p>
            </div>
          </li>
          {selectedId === "new"
            ? this.renderBreadcrumbItem(`Add A NGO`)
            : this.renderBreadcrumbItem(`NGO Details`)}
        </ol>
      </nav>
    );
  };

  renderAddressOptions = (type: string) => {
    const optionList: Option[] = [];
    if (type === "district") {
      getDistrictList(this.state.ngoState.address.state).map(
        (eachDistrict: string) => {
          optionList.push({
            key: eachDistrict,
            title: eachDistrict,
          });
          return null;
        }
      );
    } else {
      getStateList().map((eachState: string) => {
        optionList.push({
          key: eachState,
          title: eachState,
        });
        return null;
      });
    }

    return optionList;
  };

  renderBreadcrumbItem = (title: String) => {
    return (
      <li className="flex">
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <p className="ml-4 text-sm font-medium text-gray-500">{title}</p>
        </div>
      </li>
    );
  };

  renderTitle = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId === "new") {
      return "Add A NGO";
    }
  };

  renderContent = () => {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    let defaultWebUrl =
      "https://firebasestorage.googleapis.com/v0/b/arusoil-web-dev.appspot.com/o/ngos%2F";
    if (isProd) {
      defaultWebUrl =
        "https://firebasestorage.googleapis.com/v0/b/arusoil-web.appspot.com/o/ngos%2F";
    }
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");

    return (
      <div className="bg-white shadow-xl grid grid-cols-1 lg:grid-cols-2">
        <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
          <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                NGO Logo
              </label>
              {selectedId === "new" ? (
                <UploadInput
                  accept=".png,.jpg,.jpeg"
                  onChange={this.handleUploadChange.bind(this, "ngoLogo")}
                  file={this.state.ngoState.ngoLogo}
                  error={this.state.ngoStateError.ngoLogoError}
                />
              ) : (
                <img
                  className="mt-2 h-20 w-30 object-contain"
                  src={`${defaultWebUrl}${this.state.ngoState.id}%2Flogo.jpg?alt=media&token=${this.state.ngoState.ngoLogo}`}
                  alt=""
                />
              )}
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                NGO Banner
              </label>
              {selectedId === "new" ? (
                <UploadInput
                  accept=".png,.jpg,.jpeg"
                  onChange={this.handleUploadChange.bind(this, "ngoBanner")}
                  file={this.state.ngoState.ngoBanner}
                  error={this.state.ngoStateError.ngoBannerError}
                />
              ) : (
                <img
                  className="mt-2 h-30 w-42 object-contain"
                  src={`${defaultWebUrl}${this.state.ngoState.id}%2Fbanner.jpg?alt=media&token=${this.state.ngoState.ngoBanner}`}
                  alt=""
                />
              )}
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                NGO Name
              </label>
              <Input
                id="name"
                className="mt-1"
                placeholder="Name"
                value={this.state.ngoState.name}
                error={this.state.ngoStateError.nameError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                NGO ROS(Optional)
              </label>
              <Input
                id="ros"
                className="mt-1"
                placeholder="XXX-XXX"
                value={this.state.ngoState.ros}
                error={this.state.ngoStateError.rosError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                NGO Description
              </label>
              <Input
                id="description"
                className="mt-1"
                placeholder="Description"
                value={this.state.ngoState.description}
                error={this.state.ngoStateError.descriptionError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                NGO Website (Optional)
              </label>
              <Input
                id="website"
                className="mt-1"
                placeholder="https://arusoil.com"
                value={this.state.ngoState.website}
                error={this.state.ngoStateError.websiteError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                One Liner
              </label>
              <Input
                id="oneliner"
                className="mt-1"
                placeholder="One sentence of description"
                value={this.state.ngoState.oneliner}
                error={this.state.ngoStateError.onelinerError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Purpose of Fundraising
              </label>
              <Input
                id="purpose"
                className="mt-1"
                placeholder="Purpose of Fundraising"
                value={this.state.ngoState.purpose}
                error={this.state.ngoStateError.purposeError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Target Beneficiaries
              </label>
              <Input
                id="targetBeneficiaries"
                className="mt-1"
                placeholder="Separate multiple items with comma"
                value={this.state.ngoState.targetBeneficiaries}
                error={this.state.ngoStateError.targetBeneficiariesError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Importance
              </label>
              <Input
                id="importance"
                className="mt-1"
                placeholder="importance"
                type="number"
                value={this.state.ngoState.importance.toString()}
                error={this.state.ngoStateError.importanceError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Person In Charge Name
              </label>
              <Input
                id="personInChargeName"
                className="mt-1"
                placeholder="Person In Charge Name"
                value={this.state.ngoState.personInChargeName}
                error={this.state.ngoStateError.personInChargeNameError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Person In Charge Mobile
              </label>
              <Input
                id="personInChargeMobile"
                className="mt-1"
                placeholder="+60123456789"
                value={this.state.ngoState.personInChargeMobile}
                error={this.state.ngoStateError.personInChargeMobileError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                NGO Address - Street
              </label>
              <Input
                id="address"
                className="mt-1"
                placeholder="Street"
                value={this.state.ngoState.address.street}
                error={this.state.ngoStateError.addressStreetError}
                onChange={this.handleAddressInputChange.bind(this, "street")}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                NGO Address - Poscode
              </label>
              <Input
                id="address"
                className="mt-1"
                placeholder="Poscode"
                value={this.state.ngoState.address.poscode}
                error={this.state.ngoStateError.addressPoscodeError}
                onChange={this.handleAddressInputChange.bind(this, "poscode")}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                NGO Address - State
              </label>
              <Select
                id="state"
                placeholder={"State"}
                value={this.state.ngoState.address.state}
                options={this.renderAddressOptions("state")}
                onChange={this.handleSelectChangeAddress}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                NGO Address - District
              </label>
              <Select
                id="district"
                placeholder={"District"}
                value={this.state.ngoState.address.district}
                options={this.renderAddressOptions("district")}
                onChange={this.handleSelectChangeAddress}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Bank Name
              </label>
              <Select
                id="bankName"
                placeholder="Select your bank"
                value={this.state.ngoState.bankName}
                options={bankOption}
                onChange={this.handleSelectChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Bank Account Name
              </label>
              <Input
                id="bankAccountName"
                className="mt-1"
                placeholder="Bank Account Name"
                value={this.state.ngoState.bankAccountName}
                error={this.state.ngoStateError.bankAccountNameError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Bank Account Number
              </label>
              <Input
                id="bankAccountNo"
                className="mt-1"
                placeholder="Bank Account Number"
                value={this.state.ngoState.bankAccountNo}
                error={this.state.ngoStateError.bankAccountNoError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="sm:col-span-2 sm:flex sm:justify-end mt-5">
              <Button
                className="w-full sm:w-auto"
                text={this.state.creation ? "Create" : "Update"}
                type="normal"
                onClick={this.handleSubmit}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <Overlay
          loading={this.state.loading}
          text={this.state.creation ? "Creating Ngo" : "Updating Ngo"}
        />
        <ConfirmationModal
          onClose={this.props.handleOpenConfirmationModal}
          title="Delete NGO Confirmation"
          open={this.props.confirmationModalVisible}
          content="Are you sure that you want delete this NGO? "
          onClick={this.handleDeleteNgo}
        />
        {this.renderBreadcrumb()}
        <div className="mt-8">
          <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
            {this.renderTitle()}
          </h2>

          <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
            {this.renderContent()}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectedNgo: (selectedNgo: Ngo | null) =>
      dispatch(updateSelectedNgo(selectedNgo)),
  };
};

export default connect(null, mapDispatchToProps)(NgoEditor);
