import React, { Component } from "react";
import { connect } from "react-redux";
import NgoEditor from "../dashboard/NgoEditor";
import Ngos from "../dashboard/Ngos";
import { clearNgo, getNgoWithPagination } from "../store/actions/ngoActions";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  authStore: authStateInterface;
  history: any;
  pathName: string;
  confirmationModalVisible: boolean;
  handleOpenConfirmationModal: (visible: boolean) => void;
  getNgoWithPagination: (paginationStartAt: string | Date) => void;
  clearNgo: () => void;
}

interface State {
  pageIndex: number;
}

class NgoController extends Component<Props> {
  state: State = {
    pageIndex: 1,
  };

  componentDidMount = () => {
    this.props.getNgoWithPagination("");
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.pathName !== this.props.pathName) {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedId = searchParams.get("data");
      if (selectedId === "r") {
        this.handleUpdatePageIndex(1);
        this.props.clearNgo();
        this.props.getNgoWithPagination("");
      }
    }
  }

  componentWillUnmount() {
    this.props.clearNgo();
  }

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({
      pageIndex,
    });
  };

  render() {
    if (this.props.authStore.user?.role !== "SUPER") {
      return null;
    }

    if (this.props.pathName === "/dashboard/ngos") {
      return (
        <Ngos
          history={this.props.history}
          pageIndex={this.state.pageIndex}
          handleUpdatePageIndex={this.handleUpdatePageIndex}
          getNgoWithPagination={this.props.getNgoWithPagination}
        />
      );
    } else if (this.props.pathName === "/dashboard/ngoEditor") {
      return (
        <NgoEditor
          history={this.props.history}
          confirmationModalVisible={this.props.confirmationModalVisible}
          handleOpenConfirmationModal={this.props.handleOpenConfirmationModal}
        />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearNgo: () => dispatch(clearNgo()),
    getNgoWithPagination: (paginationStartAt: string | Date) =>
      dispatch(getNgoWithPagination(paginationStartAt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NgoController);
