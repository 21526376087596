import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Table, { TableHeader } from "../base/Table";
import { ngoDataLimit } from "../store/actions/ngoActions";
import { ngoStateInterface } from "../store/reducers/ngoReducer";

interface Props {
  history: any;
  pageIndex: number;
  ngoStore: ngoStateInterface;
  handleUpdatePageIndex: (pageIndex: number) => void;
  getNgoWithPagination: (paginationStartAt: string | Date) => void;
}

class Ngos extends Component<Props> {
  handleSelectedData = (id: string) => {
    this.props.history.push(`/dashboard/ngoEditor?data=${id}`);
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      //INFO : Check whether need to get new data from firebase
      if (
        this.props.ngoStore.lastCursor &&
        this.props.pageIndex * ngoDataLimit + 1 >
          this.props.ngoStore.ngos.length
      ) {
        this.props.getNgoWithPagination(this.props.ngoStore.lastCursor);
      }

      this.props.handleUpdatePageIndex(this.props.pageIndex + 1);
    } else {
      this.props.handleUpdatePageIndex(this.props.pageIndex - 1);
    }
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (this.props.ngoStore.ngos?.length > 0) {
      this.props.ngoStore.ngos.map((eachNgo) => {
        listView.push({
          id: eachNgo.id,
          name: eachNgo.name,
          description: eachNgo.description.substring(0, 40),
          ros: eachNgo.ros,
          totalDonation: `RM ${eachNgo.totalDonation}`,
          createdAt: moment(eachNgo.createdAt.seconds * 1000).format(
            "DD/MM/YY"
          ),
        });
        return null;
      });
    }
    return listView;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "name",
        title: "Name",
      },
      {
        key: "description",
        title: "Description",
      },
      {
        key: "ros",
        title: "ROS",
      },
      {
        key: "totalDonation",
        title: "Total Donation",
      },
      {
        key: "createdAt",
        title: "Created At",
      },
    ];
    return (
      <div className="mt-8">
        <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
          NGO List
        </h2>
        <Table
          loading={this.props.ngoStore.loading}
          header={headers}
          pageIndex={this.props.pageIndex}
          lastCursor={this.props.ngoStore.lastCursor}
          data={this.renderFilteredData()}
          dataLimit={ngoDataLimit}
          onClickRow={this.handleSelectedData}
          handlePage={this.handlePagination}
          newTabUrl="/dashboard/scheduleEditor?data="
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    ngoStore: state.ngoStore,
  };
};

export default connect(mapStateToProps)(Ngos);
