import React, { Component } from "react";
import { connect } from "react-redux";
import { TransactionType } from "../../models/Transaction";
import TransactionEditor from "../dashboard/TransactionEditor";
import Transactions from "../dashboard/Transactions";
import { clearSchedule } from "../store/actions/schedulesAction";
import {
  clearTransaction,
  getTransactionWithPagination,
} from "../store/actions/transactionAction";
import { clearUsers } from "../store/actions/usersActions";
import { authStateInterface } from "../store/reducers/authReducer";

enum ButtonGroupType {
  "DEBIT" = "Enter user's account",
  "CREDIT" = "Leave user's account",
}

interface Props {
  history: any;
  trigger: boolean;
  pathName: string;
  authStore: authStateInterface;
  getTransactionWithPagination: (
    paginationStartAt: string | Date,
    paginationType: keyof typeof TransactionType,
    selectedUser: string
  ) => void;
  clearTransaction: () => void;
  clearSchedule: () => void;
  clearUsers: () => void;
}

interface State {
  pageIndex: number;
  selectedUser: string;
  selectedButton: keyof typeof ButtonGroupType;
}

class TransactionController extends Component<Props> {
  state: State = {
    pageIndex: 1,
    selectedUser: "",
    selectedButton: "DEBIT",
  };

  componentDidMount() {
    this.props.getTransactionWithPagination(
      "",
      this.state.selectedButton,
      this.state.selectedUser
    );
  }

  componentWillUnmount() {
    this.handleClearData();
  }

  handleClearData = () => {
    this.props.clearTransaction();
    this.props.clearUsers();
    this.props.clearSchedule();
  };

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex });
  };

  handleUpdateSelectedUser = (selectedUser: string) => {
    this.setState({ selectedUser }, () => {
      this.handleClearData();
      this.handleUpdatePageIndex(1);
      this.props.getTransactionWithPagination(
        "",
        this.state.selectedButton,
        this.state.selectedUser
      );
    });
  };

  handleUpdateSelectedButton = (
    selectedButton: keyof typeof ButtonGroupType
  ) => {
    this.setState({ selectedButton }, () => {
      this.handleClearData();
      this.handleUpdatePageIndex(1);
      this.props.getTransactionWithPagination(
        "",
        this.state.selectedButton,
        this.state.selectedUser
      );
    });
  };

  render() {
    if (this.props.authStore.user?.role !== "SUPER") {
      return null;
    }

    if (this.props.pathName === "/dashboard/transaction") {
      return (
        <Transactions
          history={this.props.history}
          pageIndex={this.state.pageIndex}
          selectedButton={this.state.selectedButton}
          selectedUser={this.state.selectedUser}
          handleUpdatePageIndex={this.handleUpdatePageIndex}
          handleUpdateSelectedButton={this.handleUpdateSelectedButton}
          handleUpdateSelectedUser={this.handleUpdateSelectedUser}
        />
      );
    } else if (this.props.pathName === "/dashboard/transactionEditor") {
      return (
        <TransactionEditor
          history={this.props.history}
          trigger={this.props.trigger}
        />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getTransactionWithPagination: (
      paginationStartAt: string | Date,
      paginationType: keyof typeof TransactionType,
      selectedUser: string
    ) =>
      dispatch(
        getTransactionWithPagination(
          paginationStartAt,
          paginationType,
          selectedUser
        )
      ),
    clearTransaction: () => dispatch(clearTransaction()),
    clearUsers: () => dispatch(clearUsers()),
    clearSchedule: () => dispatch(clearSchedule()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionController);
