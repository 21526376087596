import axios from "axios";
import { EnterpriseStatus, UserModel } from "../../../models/User";
import { ScheduleState } from "../../dashboard/SchedulesEditor";
import {
  UserInventoryState,
  UserInventoryStateError,
  UserState,
  UserStateError,
} from "../../dashboard/UserEditor";
import firebase from "../../utils/firebase";

export const userDataLimit: number = 10;
export const inventoryDataLimit: number = 10;

export const getUsersWithPagination = (
  paginationStartAfter: string | Date,
  paginationSearch: string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let userSnapshot;
      let userCollectionQuery = firebase
        .firestore()
        .collection("users")
        .limit(userDataLimit);

      if (paginationSearch) {
        if (paginationSearch.includes("+"))
          userCollectionQuery = userCollectionQuery.orderBy("mobileNo");
        else userCollectionQuery = userCollectionQuery.orderBy("email");
      } else {
        userCollectionQuery = userCollectionQuery.orderBy("createdAt", "desc");
      }

      let userList: UserModel[] = getState().userStore.users;
      const newUserList: UserModel[] = [];

      if (!paginationStartAfter) {
        userList = [];
      }

      updateUserLoadingState(dispatch, true);

      if (paginationSearch) {
        userSnapshot = await userCollectionQuery
          .startAt(paginationSearch)
          .endAt(paginationSearch + "\uf8ff")
          .get();
      } else {
        if (paginationStartAfter) {
          userSnapshot = await userCollectionQuery
            .startAfter(paginationStartAfter)
            .get();
        } else {
          userSnapshot = await userCollectionQuery.get();
        }
      }

      if (userSnapshot) {
        userSnapshot.forEach((eachDoc: any) => {
          const eachUser = eachDoc.data() as UserModel;
          newUserList.push(eachUser);
        });
      }

      if (newUserList.length > 0) {
        let lastCursor: string | Date = "";
        if (newUserList.length === userDataLimit) {
          lastCursor = newUserList[userDataLimit - 1].createdAt;
        }

        if (paginationSearch) {
          lastCursor = "";
        }

        dispatch({
          type: "UPDATE_USER_LIST",
          payload: {
            users: userList.concat(newUserList),
            lastCursor: lastCursor,
          },
        });
      } else {
        dispatch({
          type: "UPDATE_USER_LIST",
          payload: {
            users: userList,
            lastCursor: "",
          },
        });
      }

      updateUserLoadingState(dispatch, false);
    } catch (err: any) {
      updateUserLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const getUser = async (selectedId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      let userQuery = await firebase
        .firestore()
        .collection("users")
        .doc(selectedId)
        .get();

      if (userQuery.exists) {
        let userScheduleSnapshot = await firebase
          .firestore()
          .collection("schedules")
          .where("userId", "==", selectedId)
          .get();

        let totalSchedule: number = userScheduleSnapshot.docs.length;
        let totalEstimatedWeight: number = 0;
        userScheduleSnapshot.forEach((eachSchedule: any) => {
          totalEstimatedWeight = eachSchedule.data().estimatedWeight;
        });

        return {
          user: userQuery.data() as UserModel,
          userScheduleAnalytics: {
            totalSchedule: totalSchedule,
            totalEstimatedWeight: totalEstimatedWeight,
          },
        };
      } else {
        return "User cannot be found";
      }
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const getUserName = async (selectedId: string) => {
  try {
    let userQuery = await firebase
      .firestore()
      .collection("users")
      .doc(selectedId)
      .get();

    if (userQuery.exists) {
      const userData = userQuery.data() as UserModel;
      return userData.email ? userData.email : userData.mobileNo;
    } else {
      return selectedId;
    }
  } catch (err) {
    return selectedId;
  }
};

export const getUserSuggestions = async (preview: string) => {
  try {
    let userSnapshot;
    let userCollectionQuery = firebase.firestore().collection("users");
    if (preview.includes("+"))
      userCollectionQuery = userCollectionQuery.orderBy("mobileNo") as any;
    else userCollectionQuery = userCollectionQuery.orderBy("email") as any;
    userCollectionQuery = userCollectionQuery
      .startAt(preview)
      .endAt(preview + "\uf8ff")
      .limit(10) as any;

    const userList: UserModel[] = [];
    userSnapshot = await userCollectionQuery.get();

    if (userSnapshot) {
      userSnapshot.forEach((eachDoc: any) => {
        const eachUser = eachDoc.data() as UserModel;
        userList.push(eachUser);
      });
      return userList;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const checkUserExist = async (appointmentState: ScheduleState) => {
  try {
    const appointmentSnapshot = await firebase
      .firestore()
      .collection("users")
      .where("mobileNo", "==", appointmentState.phone)
      .get();
    let userId = "";
    if (appointmentSnapshot) {
      appointmentSnapshot.forEach((eachDoc: any) => {
        const eachUser = eachDoc.data() as UserModel;
        userId = eachUser.id;
      });
    }
    return userId;
  } catch (err) {
    return "";
  }
};

export const createUser = async (user: UserState) => {
  try {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    let defaultWebAPI =
      "https://asia-southeast2-arusoil-web-dev.cloudfunctions.net/createUser";
    if (isProd) {
      defaultWebAPI =
        "https://asia-southeast2-arusoil-web.cloudfunctions.net/createUser";
    }

    const createUser: any = {
      name: user.name,
      mobileNo: user.mobileNo,
      email: user.email,
      password: user.password,
    };
    if (user.promoCode) {
      createUser["promotion"] = user.promoCode;
    }
    const userRes = await axios.post(defaultWebAPI, {
      user: createUser,
    });

    return {
      userId: userRes.data.id,
    };
  } catch (err) {
    return {
      error:
        "Unknown error, please contact developer at info@arusoil.com if this continues",
    };
  }
};

export const clearUsers = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_USER_LIST",
      payload: {
        users: [],
        lastCursor: "",
      },
    });
  };
};

export const updateSelectedUserJerry = (
  id: string,
  jerryCanRedeem: Date | undefined
) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_SELECTED_USER",
      payload: {
        selectedUser: {
          id: id,
          jerryCanRedeem: jerryCanRedeem,
        },
      },
    });
  };
};

const updateUserLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_USER_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const updateUserBalance = async (userId: string, balance: number) => {
  try {
    await firebase.firestore().collection("users").doc(userId).update({
      balance: balance,
    });
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const updateEnterpriseStatus = async (
  userId: string,
  status: keyof typeof EnterpriseStatus
) => {
  try {
    await firebase.firestore().collection("users").doc(userId).update({
      enterpriseStatus: status,
    });
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const handleUserCondition = (
  userAttributeState: UserState,
  userAttributeError: UserStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "name":
        if (userAttributeState.name.replace(/\s/g, "").length <= 0) {
          userAttributeError["nameError"] = "Please enter your name";
        } else {
          userAttributeError["nameError"] = "";
        }
        break;
      // case "mobileNo":
      //   const phoneFilter = /^(\+60)-*[0-9]{8,10}$/;
      //   if (!phoneFilter.test(userAttributeState.mobileNo)) {
      //     userAttributeError["mobileNoError"] =
      //       "Please enter your contact number in the correct format of +60123456789 (Example)";
      //   } else {
      //     userAttributeError["mobileNoError"] = "";
      //   }
      //   break;
      // case "email":
      //   /* eslint-disable */
      //   const filter =
      //     /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      //   /* eslint-enable */

      //   if (!filter.test(userAttributeState.email.replace(/\s/g, ""))) {
      //     userAttributeError["emailError"] =
      //       "Please enter your email in the correct format";
      //   } else {
      //     userAttributeError["emailError"] = "";
      //   }
      //   break;
      // case "password":
      //   if (userAttributeState.password.replace(/\s/g, "").length < 6) {
      //     userAttributeError["passwordError"] =
      //       "Please enter at least 6 characters";
      //   } else {
      //     userAttributeError["passwordError"] = "";
      //   }
      //   break;
      default:
        break;
    }
    return null;
  });
};

export const getInventoryWithPagination = (paginationSearch: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      let inventorySnapshot;
      let inventoryCollectionQuery = firebase
        .firestore()
        .collection("inventories")
        .limit(inventoryDataLimit)
        .orderBy("itemName", "asc");

      let inventoryList: UserModel[] = getState().inventoryStore.inventories;
      const newInventoryList: UserModel[] = [];

      updateInventoryLoadingState(dispatch, true);

      if (paginationSearch) {
        inventorySnapshot = await inventoryCollectionQuery
          .startAt(paginationSearch)
          .endAt(paginationSearch + "\uf8ff")
          .get();
      } else {
        inventorySnapshot = await inventoryCollectionQuery.get();
      }

      if (inventorySnapshot) {
        inventorySnapshot.forEach((eachDoc: any) => {
          const eachInventory = eachDoc.data() as any;
          newInventoryList.push(eachInventory);
        });
      }

      if (newInventoryList.length > 0) {
        dispatch({
          type: "UPDATE_INVENTORIES_LIST",
          payload: {
            inventories: inventoryList.concat(newInventoryList),
          },
        });
      } else {
        dispatch({
          type: "UPDATE_INVENTORIES_LIST",
          payload: {
            inventories: inventoryList,
          },
        });
      }

      updateInventoryLoadingState(dispatch, false);
    } catch (err: any) {
      updateInventoryLoadingState(dispatch, false);
      return err.message;
    }
  };
};

const updateInventoryLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_INVENTORY_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const clearInventory = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_INVENTORIES_LIST",
      payload: {
        inventories: [],
      },
    });
  };
};

export const updateInventory = async (userId: string, items: any) => {
  try {
    await firebase.firestore().collection("users").doc(userId).update({
      items,
    });
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const handleInventoryCondition = (
  userInventoryState: UserInventoryState,
  userInventoryError: UserInventoryStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "itemName":
        if (userInventoryState.itemName.replace(/\s/g, "").length <= 0) {
          userInventoryError["itemNameError"] = "Please select your item name";
        } else {
          userInventoryError["itemNameError"] = "";
        }
        break;
      case "quantity":
        if (Number(userInventoryState.quantity) >= 1) {
          userInventoryError["quantityError"] = "";
        } else {
          userInventoryError["quantityError"] =
            "Your item quantity has be to at least 1";
        }
        break;
      default:
        break;
    }
    return null;
  });
};
