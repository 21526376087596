import { HomeIcon } from "@heroicons/react/24/outline";
import {
  ChatBubbleLeftIcon,
  DocumentIcon,
  PaperAirplaneIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";
import firebase from "firebase";
import moment from "moment";
import React, { Component } from "react";
import {
  ActivityLog,
  ActivityType,
  OperationalType,
  Restaurant,
} from "../../models/Restaurant";
import Button from "../base/Button";
import Feeds, { Feed } from "../base/Feeds";
import OperationModal from "./UpdateOperationModal";

interface Props {
  history: any;
}

interface State {
  selectedRestaurant: Restaurant;
  visible: boolean;
  modalType: string;
}

export default class OperationEditor extends Component<Props> {
  state: State = {
    selectedRestaurant: {
      id: "",
      address: {
        lat: 0,
        lng: 0,
        name: "",
      },
      createdAt: moment().toDate(),
      phoneNumber: "",
      businessStatus: "OPERATIONAL",
      name: "",
      website: "",
      email: "",
      activityLogs: [],
    },
    modalType: "",
    visible: false,
  };

  componentDidMount = async () => {
    this.handleGetPreviewData();
  };

  handleGetPreviewData = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId) {
      const restaurantSnapshot = await firebase
        .firestore()
        .collection("restaurants")
        .doc(selectedId)
        .get();
      if (restaurantSnapshot.exists) {
        const restaurantData = restaurantSnapshot.data();
        this.setState({
          selectedRestaurant: restaurantData,
        });
      } else {
        const request = {
          placeId: selectedId,
          fields: [
            "name",
            "place_id",
            "formatted_phone_number",
            "geometry",
            "formatted_address",
            "business_status",
          ],
        };

        const service = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
        await service.getDetails(request, (place: any, status: any) =>
          this.detailsCallback(place, status)
        );
      }
    } else {
      this.handleBack();
    }
  };

  detailsCallback(place: any, status: any) {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      this.setState({
        selectedRestaurant: {
          ...this.state.selectedRestaurant,
          id: place.place_id,
          address: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            name: place.formatted_address,
          },
          phoneNumber: place.formatted_phone_number,
          businessStatus: place.business_status,
          name: place.name,
          website: place.website,
        },
      });
    }
  }

  handleBack = () => {
    this.props.history.push("/dashboard/operation");
  };

  handleModalVisible = (visible: boolean, modalType: string) => {
    this.setState({
      visible: visible,
      modalType: modalType,
    });
  };

  handleUpdateState = (attribute: any) => {
    if (this.state.modalType === "logs") {
      const clonedAttribute = JSON.parse(JSON.stringify(attribute));
      clonedAttribute["creationDate"] = {
        seconds: moment(attribute.creationDate).unix(),
      };
      this.state.selectedRestaurant.activityLogs.push(clonedAttribute);
    } else {
      const selectedRestaurant = JSON.parse(
        JSON.stringify(this.state.selectedRestaurant)
      );
      selectedRestaurant[this.state.modalType] = attribute;
      this.setState({
        selectedRestaurant,
      });
    }
  };

  renderBreadcrumb = () => {
    return (
      <nav
        className="bg-white border-b border-gray-200 flex mt-0.5 mx-auto w-full"
        aria-label="Breadcrumb"
      >
        <ol className="max-w-6xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8 ">
          <li className="flex">
            <div className="flex items-center">
              <p
                className="text-gray-600 hover:text-arusgreen-500 cursor-pointer"
                onClick={this.handleBack}
              >
                <HomeIcon className="flex-shrink-0 h-5 w-5" />
              </p>
            </div>
          </li>
          {this.renderBreadcrumbItem(`Restaurant Details`)}
        </ol>
      </nav>
    );
  };

  renderBreadcrumbItem = (title: String) => {
    return (
      <li className="flex">
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <p className="ml-4 text-sm font-medium text-gray-500">{title}</p>
        </div>
      </li>
    );
  };

  renderActivityLogIcon = (activityType: keyof typeof ActivityType) => {
    if (activityType === "CONTACTED") {
      return <PhoneIcon className="h-5 w-5 text-white" />;
    } else if (activityType === "COMMENT") {
      return <ChatBubbleLeftIcon className="h-5 w-5 text-white" />;
    } else {
      return <PaperAirplaneIcon className="h-5 w-5 text-white" />;
    }
  };

  renderActivityContent(activity: ActivityLog) {
    if (activity.activityType === "CONTACTED") {
      return `Contacted at ${moment(
        activity.creationDate.seconds * 1000
      ).format("DD-MM-YYYY")}`;
    } else if (activity.activityType === "COMMENT") {
      return `Commented: at ${moment(
        activity.creationDate.seconds * 1000
      ).format("DD-MM-YYYY")}`;
    } else {
      return `Sent Email at ${moment(
        activity.creationDate.seconds * 1000
      ).format("DD-MM-YYYY")}`;
    }
  }

  renderActivityLog = () => {
    let activityLogList: Feed[] = [];
    if (this.state.selectedRestaurant.activityLogs.length > 0) {
      this.state.selectedRestaurant.activityLogs.map((eachActivity) => {
        activityLogList.push({
          iconClass: "bg-arusgreen-600",
          iconType: this.renderActivityLogIcon(eachActivity.activityType),
          content: this.renderActivityContent(eachActivity),
          description: eachActivity.comment,
          step: "",
        });
        return "";
      });
    } else {
      activityLogList.push({
        iconClass: "bg-arusgreen-600",
        iconType: <DocumentIcon className="h-5 w-5 text-white" />,
        content: "No log has been updated",
        description: "",
        step: "",
      });
    }
    return activityLogList;
  };

  renderContent = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId) {
      return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Place Details
            </h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Place ID</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.selectedRestaurant.id}
                </dd>
              </div>
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.selectedRestaurant.name}
                </dd>
              </div>
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.selectedRestaurant.email
                    ? this.state.selectedRestaurant.email
                    : "No email available"}
                  <span
                    className="text-sm font-medium text-arusgreen-500 ml-4 cursor-pointer"
                    onClick={this.handleModalVisible.bind(this, true, "email")}
                  >
                    Update
                  </span>
                </dd>
              </div>
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Contact</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.selectedRestaurant.phoneNumber
                    ? this.state.selectedRestaurant.phoneNumber
                    : "No contact number available"}
                  <span
                    className="text-sm font-medium text-arusgreen-500 ml-4 cursor-pointer"
                    onClick={this.handleModalVisible.bind(
                      this,
                      true,
                      "phoneNumber"
                    )}
                  >
                    Update
                  </span>
                </dd>
              </div>
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Website</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.selectedRestaurant.website
                    ? this.state.selectedRestaurant.website
                    : "No website available"}
                  <span
                    className="text-sm font-medium text-arusgreen-500 ml-4 cursor-pointer"
                    onClick={this.handleModalVisible.bind(
                      this,
                      true,
                      "website"
                    )}
                  >
                    Update
                  </span>
                </dd>
              </div>
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Operation Status
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {
                    OperationalType[
                      this.state.selectedRestaurant
                        .businessStatus as keyof typeof OperationalType
                    ]
                  }
                </dd>
              </div>
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Address</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.selectedRestaurant.address.name}
                </dd>
              </div>
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Coordinate
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {`Lat: ${this.state.selectedRestaurant.address.lat.toFixed(
                    3
                  )} Lng: ${this.state.selectedRestaurant.address.lng.toFixed(
                    3
                  )}`}
                </dd>
              </div>
            </dl>
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Flag</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.selectedRestaurant.flag !== null
                    ? this.state.selectedRestaurant.flag
                    : "No flag assigned"}
                  <span
                    className="text-sm font-medium text-arusgreen-500 ml-4 cursor-pointer"
                    onClick={this.handleModalVisible.bind(this, true, "flag")}
                  >
                    Update
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <>
        {this.renderBreadcrumb()}
        <div className="mt-8">
          <OperationModal
            selectedRestaurant={this.state.selectedRestaurant}
            modalType={this.state.modalType}
            open={this.state.visible}
            onClose={this.handleModalVisible}
            handleUpdateState={this.handleUpdateState}
          />

          <div className="block mt-2">
            <div className="max-w-6xl mx-auto flex flex-col px-4 sm:px-6 lg:px-8">
              {this.renderContent()}

              <div className="py-4 px-4 sm:flex sm:flex-row mt-8 justify-center items-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Activity Logs
                </h3>
                <div className="flex flex-grow"></div>
                <Button
                  className="w-full sm:w-auto"
                  text="Update Log"
                  type="normal"
                  onClick={this.handleModalVisible.bind(this, true, "logs")}
                ></Button>
              </div>

              <div className="bg-white shadow border-t border-gray-200 sm:rounded-lg">
                <div
                  className=" px-4 flex items-center justify-between border-t"
                  aria-label="Pagination"
                >
                  <div className="flow-root my-6 w-full">
                    <Feeds content={this.renderActivityLog()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
