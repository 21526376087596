import React, { Component } from "react";
import { connect } from "react-redux";
import PendingTransaction from "../dashboard/PendingTransaction";
import PendingTransactionEditor from "../dashboard/PendingTransactionEditor";
import {
  clearPendTransaction,
  getPendingTransactionWithPagination,
} from "../store/actions/pendTransactionActions";
import { clearUsers } from "../store/actions/usersActions";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  authStore: authStateInterface;
  history: any;
  pathName: string;
  confirmationModalVisible: boolean;
  handleOpenConfirmationModal: (visible: boolean) => void;
  getPendingTransactionWithPagination: (
    paginationStartAt: string | Date
  ) => void;
  clearPendTransaction: () => void;
  clearUsers: () => void;
}

interface State {
  pageIndex: number;
}

class PendingTransactionController extends Component<Props> {
  state: State = { pageIndex: 1 };

  componentDidMount() {
    this.props.getPendingTransactionWithPagination("");
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.pathName !== this.props.pathName) {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedId = searchParams.get("data");
      if (selectedId === "r") {
        this.handleUpdatePageIndex(1);
        this.props.clearPendTransaction();
        this.props.clearUsers();
        this.props.getPendingTransactionWithPagination("");
      }
    }
  }

  componentWillUnmount() {
    this.props.clearPendTransaction();
    this.props.clearUsers();
  }

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex });
  };

  render() {
    if (this.props.authStore.user?.role !== "SUPER") {
      return null;
    }

    if (this.props.pathName === "/dashboard/pendTransactions") {
      return (
        <PendingTransaction
          history={this.props.history}
          pageIndex={this.state.pageIndex}
          handleUpdatePageIndex={this.handleUpdatePageIndex}
        />
      );
    } else if (this.props.pathName === "/dashboard/pendTransactionEditor") {
      return (
        <PendingTransactionEditor
          history={this.props.history}
          confirmationModalVisible={this.props.confirmationModalVisible}
          handleOpenConfirmationModal={this.props.handleOpenConfirmationModal}
        />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearUsers: () => dispatch(clearUsers()),
    clearPendTransaction: () => dispatch(clearPendTransaction()),
    getPendingTransactionWithPagination: (paginationStartAt: string | Date) =>
      dispatch(getPendingTransactionWithPagination(paginationStartAt)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingTransactionController);
