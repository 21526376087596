import {
  BookmarkIcon,
  CheckIcon,
  GiftIcon,
  HomeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CalendarIcon, TruckIcon } from "@heroicons/react/24/solid";
import { TextInput } from "blixify-ui-web/lib";
import moment from "moment";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  PreferredTime,
  PreferrredDay,
  ScheduleModel,
  Status,
} from "../../models/Schedules";
import { Address, UserModel } from "../../models/User";
import AddressInput from "../base/AddressInput";
import Button from "../base/Button";
import Feeds, { Feed } from "../base/Feeds";
import Input from "../base/Input";
import Overlay from "../base/Overlay";
import Select, { Option } from "../base/Select";
import SmartSelect from "../base/SmartSelect";
import UploadInput from "../base/UploadInput";
import { updateJobAssignId } from "../store/actions/jobActions";
import {
  clearPromotion,
  getPromotionList,
} from "../store/actions/promotionActions";
import {
  clearSelectedSchedule,
  createSchedule,
  getSchedule,
  handleCreateScheduleCondition,
  updateSelectedSchedule,
} from "../store/actions/schedulesAction";
import { checkUserExist, createUser } from "../store/actions/usersActions";
import { sleep } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { promotionStateInterface } from "../store/reducers/promotionReducer";
import { scheduleStateInterface } from "../store/reducers/scheduleReducer";
import ConfirmationModal from "./ConfirmationModal";
import AddressModal from "./UpdateAddressModal";
import Modal from "./UpdateScheduleModal";

interface Props {
  authStore: authStateInterface;
  history: any;
  scheduleStore: scheduleStateInterface;
  promotionStore: promotionStateInterface;
  updateAddressModalVisible: boolean;
  updateScheduleModalVisible: boolean;
  updateScheduleModalType: string;
  forceCreation?: boolean;
  agentView?: boolean;
  confirmationModalVisible?: boolean;
  handleOpenConfirmationModal?: (visible: boolean) => void;
  handleOpenAddressModal: (visible: boolean) => void;
  handleOpenScheduleUpdateModal: (visible: boolean, type?: string) => void;
  getPromotionList: (selectedUser: UserModel) => void;
  updateSelectedSchedule: (schedule: ScheduleModel) => void;
  clearSelectedSchedule: () => void;
  clearPromotion: () => void;
}

const preferredDayOptions: Option[] = Object.keys(PreferrredDay).map(
  (eachPreferredDayKey) => {
    return {
      key: eachPreferredDayKey,
      title: PreferrredDay[eachPreferredDayKey as keyof typeof PreferrredDay],
    };
  }
);

const preferredTimeOptions: Option[] = Object.keys(PreferredTime).map(
  (eachPreferredTimeKey) => {
    return {
      key: eachPreferredTimeKey,
      title: PreferredTime[eachPreferredTimeKey as keyof typeof PreferredTime],
    };
  }
);

export interface ScheduleState {
  email: string[];
  phone: string;
  address: Address;
  image: File | "";
  preferredDay: keyof typeof PreferrredDay | "";
  preferredTime: keyof typeof PreferredTime | "";
  additionRemark: string;
  estimatedWeight: number;
}

export interface ScheduleStateError {
  emailError: string;
  phoneError: string;
  nameError: string;
  addressError: string;
  imageError: string;
  preferredDayError: string;
  preferredTimeError: string;
  estimatedWeightError: string;
}

interface State {
  refresh: boolean;
  loading: boolean;
  creation: boolean;
  customerName: string;
  selectedUser: UserModel;
  scheduleState: ScheduleState;
  scheduleStateError: ScheduleStateError;
  updateScheduleModalType: string;
}

class SchedulesEditor extends Component<Props> {
  mobileInputV2: any = createRef();
  state: State = {
    refresh: false,
    loading: false,
    creation: false,
    customerName: "",
    selectedUser: {
      id: "",
      email: "",
      name: "",
      gender: "M",
      age: "M",
      profession: "ACC",
      mobileNo: "",
      familyMembers: "S",
      cookFrequency: "AVERAGE",
      createdAt: new Date(),
    },
    scheduleState: {
      email: [],
      phone: "",
      address: {
        name: "",
        lat: 0,
        lng: 0,
      },
      image: "",
      preferredDay: "",
      preferredTime: "",
      additionRemark: "",
      estimatedWeight: 0,
    },
    scheduleStateError: {
      emailError: "",
      phoneError: "",
      nameError: "",
      addressError: "",
      imageError: "",
      preferredDayError: "",
      preferredTimeError: "",
      estimatedWeightError: "",
    },
    updateScheduleModalType: "",
  };

  componentDidMount = () => {
    this.handleGetSelectedSchedule();
  };

  componentWillUnmount = () => {
    this.props.clearSelectedSchedule();
    this.props.clearPromotion();
  };

  handleLoading = (loading: boolean) => {
    this.setState({ loading });
  };

  handleGetSelectedSchedule = async (ignoreModal?: boolean) => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    const modalStatus = searchParams.get("modal");
    if (!ignoreModal) {
      if (modalStatus === "true") {
        this.props.handleOpenScheduleUpdateModal(true);
      }
    }

    if (selectedId && selectedId !== "new") {
      const scheduleData = await getSchedule(selectedId);
      if (typeof scheduleData === "string") {
        this.handleBack();
      } else {
        this.props.updateSelectedSchedule(scheduleData.schedule);
        this.setState(
          {
            selectedUser: scheduleData.user,
          },
          () => {
            this.props.getPromotionList(this.state.selectedUser);
          }
        );
      }
    } else if (selectedId && selectedId === "new") {
      this.setState({ creation: true });
    }
  };

  handleBack = () => {
    let defaultUrl = "/dashboard/schedules";
    if (this.props.agentView) {
      defaultUrl = "/dashboard/jobs";
    }
    if (this.state.refresh) {
      this.props.history.push(`${defaultUrl}?data=r`);
    } else {
      this.props.history.push(defaultUrl);
    }
  };

  handleRefreshNav = (refresh: boolean) => {
    this.setState({ refresh });
  };

  handleCloneScheduleState = () => {
    const currentImage = this.state.scheduleState.image;
    const clonedScheduleState = JSON.parse(
      JSON.stringify(this.state.scheduleState)
    );
    clonedScheduleState["image"] = currentImage;
    return clonedScheduleState;
  };

  handleInputChange = (e: any) => {
    const clonedScheduleState = this.handleCloneScheduleState();
    clonedScheduleState[e.target.id] = e.target.value;
    this.setState({
      scheduleState: clonedScheduleState,
    });
  };

  handleUploadChange = (e: any) => {
    const clonedScheduleState = this.handleCloneScheduleState();
    clonedScheduleState["image"] = e.target.files[0];
    this.setState({
      scheduleState: clonedScheduleState,
    });
  };

  handleUseCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const clonedScheduleState = this.handleCloneScheduleState();
      clonedScheduleState["address"] = {
        name: "Current Location",
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      this.setState({
        scheduleState: clonedScheduleState,
      });
    });
  };

  handleChange = (address: Address) => {
    const clonedScheduleState = this.handleCloneScheduleState();
    clonedScheduleState["address"] = address;
    this.setState({
      scheduleState: clonedScheduleState,
    });
  };

  handleSelectChange = (id: string, key: string) => {
    const clonedScheduleState = this.handleCloneScheduleState();
    clonedScheduleState[id] = key;
    this.setState({
      scheduleState: clonedScheduleState,
    });
  };

  handleMultiSelectChange = (id: string, key: string) => {
    const clonedScheduleState = this.handleCloneScheduleState();
    clonedScheduleState[id] = key ? [key] : [];
    this.setState({
      scheduleState: clonedScheduleState,
    });
  };

  handleUpdateJobAssignment = async () => {
    const error = await updateJobAssignId(
      this.props.scheduleStore.selectedSchedule.id,
      this.props.authStore.user?.id ?? ""
    );
    if (!error) {
      this.handleGetSelectedSchedule();
    }
  };

  handleSubmit = async () => {
    this.handleLoading(true);
    let conditionsList: string[] = [
      "phone",
      "preferredTime",
      "preferredDay",
      "estimatedWeight",
      "image",
      "address",
    ];
    let mobileInputCondition = false;
    if (this.mobileInputV2.current)
      mobileInputCondition = await this.mobileInputV2.current.handleSubmit();

    const clonedScheduleError = JSON.parse(
      JSON.stringify(this.state.scheduleStateError)
    );
    const hasEmailInput = this.state.scheduleState.email.length > 0;
    if (!hasEmailInput) {
      if (this.state.customerName.length === 0)
        clonedScheduleError["nameError"] = "Please enter your customer name";
      else clonedScheduleError["nameError"] = "";
    }
    handleCreateScheduleCondition(
      this.state.scheduleState,
      clonedScheduleError,
      conditionsList
    );

    this.setState(
      {
        scheduleStateError: clonedScheduleError,
      },
      async () => {
        if (
          mobileInputCondition &&
          !this.state.scheduleStateError.nameError &&
          !this.state.scheduleStateError.emailError &&
          !this.state.scheduleStateError.preferredTimeError &&
          !this.state.scheduleStateError.preferredDayError &&
          !this.state.scheduleStateError.estimatedWeightError &&
          !this.state.scheduleStateError.imageError &&
          !this.state.scheduleStateError.addressError
        ) {
          if (!hasEmailInput) {
            const userExist = await checkUserExist(this.state.scheduleState);
            let userId = "";
            if (!userExist) {
              const createUserResponse = await createUser({
                email: "",
                password: "",
                name: this.state.customerName,
                mobileNo: this.state.scheduleState.phone,
                promoCode: "",
              });
              if (createUserResponse.error) this.handleLoading(false);
              else userId = createUserResponse.userId;
            } else userId = userExist;

            this.setState({
              scheduleState: {
                ...this.state.scheduleState,
                email: [userId],
              },
            });
            await sleep(500);
          }

          const creationResp = await createSchedule(this.state.scheduleState);
          if (creationResp.error) {
            const clonedScheduleErrorCreation = JSON.parse(
              JSON.stringify(this.state.scheduleStateError)
            );
            clonedScheduleErrorCreation["preferredDayError"] =
              creationResp.error;
            this.setState({
              scheduleStateError: clonedScheduleErrorCreation,
            });
          } else if (creationResp.scheduleId) {
            let defaultUrl = `/dashboard/scheduleEditor?data=${creationResp.scheduleId}&modal=true`;
            if (this.props.agentView) {
              defaultUrl = "/dashboard/jobs?data=r";
            }
            this.props.history.push(`${defaultUrl}`);
            if (!this.props.agentView) {
              await sleep(300);
              this.setState({ creation: false });
              this.handleGetSelectedSchedule();
            }
          }
          this.handleLoading(false);
        }
        this.handleLoading(false);
      }
    );
  };

  renderBreadcrumb = () => {
    const creation = this.props.forceCreation ?? this.state.creation;
    return (
      <nav
        className="bg-white border-b border-gray-200 flex mt-0.5 mx-auto w-full"
        aria-label="Breadcrumb"
      >
        <ol className="max-w-6xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8 ">
          <li className="flex">
            <div className="flex items-center">
              <p
                className="text-gray-600 hover:text-arusgreen-500 cursor-pointer"
                onClick={this.handleBack}
              >
                <HomeIcon className="flex-shrink-0 h-5 w-5" />
              </p>
            </div>
          </li>
          {this.renderBreadcrumbItem(creation ? "New Schedule" : "Logistic ID")}
        </ol>
      </nav>
    );
  };

  renderBreadcrumbItem = (title: String) => {
    return (
      <li className="flex">
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <p className="ml-4 text-sm font-medium text-gray-500">{title}</p>
        </div>
      </li>
    );
  };

  renderFeedContent = () => {
    let processList: Feed[] = [];
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    let defaultWebUrl =
      "https://firebasestorage.googleapis.com/v0/b/arusoil-web-dev.appspot.com/o/schedules%2F";
    if (isProd) {
      defaultWebUrl =
        "https://firebasestorage.googleapis.com/v0/b/arusoil-web.appspot.com/o/schedules%2F";
    }

    if (this.props.scheduleStore.selectedSchedule && this.state.selectedUser) {
      this.props.scheduleStore.selectedSchedule?.processes.map(
        (eachProcess) => {
          const eachUser = this.state.selectedUser;
          switch (eachProcess.type) {
            case "CREATED":
              processList.push({
                iconClass: "bg-arusgreen-600",
                iconType: <BookmarkIcon className="h-5 w-5 text-white" />,
                content: `${eachUser.name} has scheduled an appointment to collect his/her UCO at ${this.props.scheduleStore.selectedSchedule.address.name}`,
                step: `${moment(eachProcess.date.seconds * 1000).format(
                  "DD/MM/YY"
                )}`,
                image: `${defaultWebUrl}${this.props.scheduleStore.selectedSchedule.id}%2Fpreview.jpg?alt=media&token=${this.props.scheduleStore.selectedSchedule.image}`,
              });
              break;
            case "CANCELLED":
              processList.push({
                iconClass: "bg-red-500",
                iconType: <XMarkIcon className="h-5 w-5 text-white" />,
                content: `${eachUser.name} has cancelled his/her appointment`,
                step: `${moment(eachProcess.date.seconds * 1000).format(
                  "DD/MM/YY"
                )}`,
              });
              break;
            case "DECLINED":
              processList.push({
                iconClass: "bg-red-500",
                iconType: <XMarkIcon className="h-5 w-5 text-white" />,
                content: `You have declined this appointment with the reason of "${eachProcess.description}"`,
                step: `${moment(eachProcess.date.seconds * 1000).format(
                  "DD/MM/YY"
                )}`,
              });
              break;
            case "DISPATCHED":
              processList.push({
                iconClass: "bg-arusgreen-600",
                iconType: <TruckIcon className="h-5 w-5 text-white" />,
                content: `A collection vehicle (${eachProcess.description}) have been dispatched to collect your UCO on today`,
                step: `${moment(eachProcess.date.seconds * 1000).format(
                  "DD/MM/YY"
                )}`,
              });
              break;
            case "SCHEDULED":
              processList.push({
                iconClass: "bg-arusgreen-600",
                iconType: <CalendarIcon className="h-5 w-5 text-white" />,
                content: `Appointment has been scheduled on ${eachProcess.description}`,
                step: `${moment(eachProcess.date.seconds * 1000).format(
                  "DD/MM/YY"
                )}`,
              });
              break;
            case "COMPLETED":
              processList.push({
                iconClass: "bg-arusgreen-600",
                iconType: <CheckIcon className="h-5 w-5 text-white" />,
                content: `${
                  eachUser.name
                }('s) UCO has been successfully collected. ${
                  eachProcess.description
                }kg of UCO is collected which costs RM${eachProcess.amount?.toFixed(
                  2
                )}`,
                step: `${moment(eachProcess.date.seconds * 1000).format(
                  "DD/MM/YY"
                )}`,
              });
              break;
          }
          return "";
        }
      );
    }
    return processList;
  };

  renderPromoCodeContent = () => {
    let promotionList: Feed[] = [];
    if (
      this.state.selectedUser.promotions &&
      this.props.promotionStore.promotions
    ) {
      const promotionLength = this.state.selectedUser.promotions.length;
      this.state.selectedUser.promotions.map((eachUserPromotion, index) => {
        this.props.promotionStore.promotions.map((eachPromotion) => {
          const rateContent =
            eachPromotion.type === "FLAT"
              ? `RM${eachPromotion.fixedPriceRate} of fixed rate`
              : `${eachPromotion.increasePercentRate}% increase of selling rates`;
          const description = `Enjoy ${rateContent} for ${
            eachPromotion.durationAppliedInMonths
          } month(s) - Applied on ${moment(
            eachUserPromotion.appliedDate.seconds * 1000
          ).format("DD/MM/YY")} `;
          if (eachUserPromotion.id === eachPromotion.id) {
            promotionList.push({
              iconClass:
                promotionLength === index + 1
                  ? "bg-arusgreen-600"
                  : "bg-red-600",
              iconType:
                promotionLength === index + 1 ? (
                  <GiftIcon className="h-5 w-5 text-white" />
                ) : (
                  <XMarkIcon className="h-5 w-5 text-white" />
                ),
              content: `Promotion Code - ${
                eachUserPromotion.id
              } is applied on ${moment(
                eachUserPromotion.appliedDate.seconds * 1000
              ).format("DD/MM/YY")}`,
              description: description,
              step: "",
            });
          }
          return "";
        });
        return "";
      });
    }
    return promotionList;
  };

  renderContent = () => {
    const creation = this.props.forceCreation ?? this.state.creation;
    if (creation) {
      return (
        <div className="block mt-2 px-4 sm:px-6 lg:px-8 max-w-6xl mx-auto">
          <div className="bg-white shadow overflow-hidden p-6 py-10 sm:rounded-lg">
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  User's email/mobile (Optional)
                </label>
                <SmartSelect
                  id="email"
                  placeholder="Search user's email/mobile"
                  value={
                    this.state.scheduleState.email.length > 0
                      ? this.state.scheduleState.email[0]
                      : ""
                  }
                  error={this.state.scheduleStateError.emailError}
                  onChange={this.handleMultiSelectChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Preferred Day of Collection
                </label>
                <Select
                  id="preferredDay"
                  placeholder="Select the Preferred Day"
                  value={this.state.scheduleState.preferredDay}
                  options={preferredDayOptions}
                  onChange={this.handleSelectChange}
                  error={this.state.scheduleStateError.preferredDayError}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Preferred Time for Collection
                </label>
                <Select
                  id="preferredTime"
                  placeholder="Select the Preferred Day"
                  value={this.state.scheduleState.preferredTime}
                  options={preferredTimeOptions}
                  onChange={this.handleSelectChange}
                  error={this.state.scheduleStateError.preferredTimeError}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <TextInput
                  ref={this.mobileInputV2}
                  id="phone"
                  label="Mobile Number"
                  type="mobile"
                  mobileWhitelist={["BN", "MY"]}
                  placeholder="Mobile Number"
                  value={this.state.scheduleState.phone}
                  onChange={this.handleInputChange}
                />
              </div>
              {this.state.scheduleState.email.length <= 0 && (
                <div className="col-span-2 sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Customer Name
                  </label>
                  <Input
                    id="name"
                    type="text"
                    placeholder="Name"
                    value={this.state.customerName}
                    error={this.state.scheduleStateError.nameError}
                    onChange={(e) => {
                      this.setState({ customerName: e.target.value });
                    }}
                  />
                </div>
              )}
              <div className="col-span-2 sm:col-span-1 ">
                <AddressInput
                  id="address"
                  className="mt-1"
                  value={this.state.scheduleState.address}
                  error={this.state.scheduleStateError.addressError}
                  onChange={this.handleChange}
                />
                <span
                  className="text-sm font-medium text-arusgreen-500 cursor-pointer mt-4"
                  onClick={this.handleUseCurrentLocation}
                >
                  Use Current Location?
                </span>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Upload an image of the collected UCO
                </label>
                <UploadInput
                  accept=".png,.jpg,.jpeg"
                  onChange={this.handleUploadChange}
                  file={this.state.scheduleState.image}
                  error={this.state.scheduleStateError.imageError}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Estimate Weight of UCO (kg)
                </label>
                <Input
                  id="estimatedWeight"
                  className="mt-1"
                  placeholder="Enter your estimated weight"
                  type="number"
                  value={this.state.scheduleState.estimatedWeight.toString()}
                  error={this.state.scheduleStateError.estimatedWeightError}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Additional Remarks
                </label>
                <Input
                  id="additionRemark"
                  className="mt-1"
                  placeholder="Enter your additional remarks"
                  type="textarea"
                  value={this.state.scheduleState.additionRemark}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="sm:col-span-2 sm:flex sm:justify-end mt-5">
                <Button
                  className="w-full sm:w-auto"
                  text="Submit"
                  type="normal"
                  onClick={this.handleSubmit}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="block mt-2 px-4 sm:px-6 lg:px-8 max-w-6xl mx-auto">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Logistic Details
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {this.state.selectedUser.email} has scheduled this logistic for
                collection.
              </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.props.scheduleStore.selectedSchedule.address.name}
                    {!this.props.agentView && (
                      <span
                        className="text-sm font-medium text-arusgreen-500 ml-4 cursor-pointer"
                        onClick={this.props.handleOpenAddressModal.bind(
                          this,
                          true
                        )}
                      >
                        Update
                      </span>
                    )}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Phone Number
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.props.scheduleStore.selectedSchedule.phone}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Customer Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.selectedUser.name}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Status</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {Status[this.props.scheduleStore.selectedSchedule.status]}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Estimated Weight
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.props.scheduleStore.selectedSchedule.estimatedWeight}{" "}
                    kg
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Preferred Day for collection
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {
                      PreferrredDay[
                        this.props.scheduleStore.selectedSchedule.preferredDay
                      ]
                    }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Preferred Time for collection
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {
                      PreferredTime[
                        this.props.scheduleStore.selectedSchedule.preferredTime
                      ]
                    }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Additional Remarks
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.props.scheduleStore.selectedSchedule.additionRemark
                      ? this.props.scheduleStore.selectedSchedule.additionRemark
                      : `${this.state.selectedUser.name} didn't place any remarks`}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Distance Travelled(km)
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.props.scheduleStore.selectedSchedule.travelDistance ??
                      0}
                    <span
                      className="text-sm font-medium text-arusgreen-500 ml-4 cursor-pointer"
                      onClick={this.props.handleOpenScheduleUpdateModal.bind(
                        this,
                        true,
                        "distance"
                      )}
                    >
                      Update
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Start Point
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.props.scheduleStore.selectedSchedule.startPoint?.lat
                      ? `${this.props.scheduleStore.selectedSchedule.startPoint.name.substring(
                          0,
                          20
                        )} - Lat: 
                        ${this.props.scheduleStore.selectedSchedule.startPoint.lat.toFixed(
                          3
                        )} Lng: ${this.props.scheduleStore.selectedSchedule.startPoint.lng.toFixed(
                          3
                        )}`
                      : "No Starting Point"}
                    <span
                      className="text-sm font-medium text-arusgreen-500 ml-4 cursor-pointer"
                      onClick={this.props.handleOpenScheduleUpdateModal.bind(
                        this,
                        true,
                        "startPoint"
                      )}
                    >
                      Update
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    End Point
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.props.scheduleStore.selectedSchedule.endPoint?.lat
                      ? `${this.props.scheduleStore.selectedSchedule.endPoint.name.substring(
                          0,
                          20
                        )} - Lat: 
                        ${this.props.scheduleStore.selectedSchedule.endPoint.lat.toFixed(
                          3
                        )} Lng: ${this.props.scheduleStore.selectedSchedule.endPoint.lng.toFixed(
                          3
                        )}`
                      : "No Ending Point"}
                    <span
                      className="text-sm font-medium text-arusgreen-500 ml-4 cursor-pointer"
                      onClick={this.props.handleOpenScheduleUpdateModal.bind(
                        this,
                        true,
                        "endPoint"
                      )}
                    >
                      Update
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Sorting Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {moment(
                      this.props.scheduleStore.selectedSchedule.updatedAt
                        .seconds * 1000
                    ).format("DD/MM/YYYY")}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          {this.state.selectedUser && (
            <div className="bg-white mt-5 shadow border-t border-gray-200 sm:rounded-lg">
              <div className="py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Activity Log
                </h3>
              </div>
              <div
                className=" px-4 flex items-center justify-between border-t"
                aria-label="Pagination"
              >
                <div className="flow-root my-6 w-full">
                  <Feeds content={this.renderFeedContent()} />
                </div>
              </div>
            </div>
          )}

          {this.state.selectedUser &&
            this.state.selectedUser.promotions &&
            this.state.selectedUser.promotions.length > 0 && (
              <div className="bg-white mt-5 shadow border-t border-gray-200 sm:rounded-lg">
                <div className="py-4 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Promo Code Details
                  </h3>
                </div>
                <div
                  className=" px-4 flex items-center justify-between border-t"
                  aria-label="Pagination"
                >
                  <div className="flow-root my-6 w-full">
                    <Feeds content={this.renderPromoCodeContent()} />
                  </div>
                </div>
              </div>
            )}
        </div>
      );
    }
  };

  render() {
    const creation = this.props.forceCreation ?? this.state.creation;
    return (
      <>
        {this.renderBreadcrumb()}
        <Overlay loading={this.state.loading} text="Creating Schedule" />
        <ConfirmationModal
          onClose={
            this.props.handleOpenConfirmationModal
              ? this.props.handleOpenConfirmationModal
              : () => {}
          }
          title="Accept Job Confirmation"
          open={this.props.confirmationModalVisible ?? false}
          content={`Are you sure that you want to accept this job? Once you have accepted the job, you are assigned to update the job status.`}
          onClick={this.handleUpdateJobAssignment}
        />
        <Modal
          open={this.props.updateScheduleModalVisible}
          agentView={this.props.agentView}
          selectedId={this.props.scheduleStore.selectedSchedule.id}
          updateScheduleModalType={this.props.updateScheduleModalType}
          process={this.props.scheduleStore.selectedSchedule.processes}
          selectedUser={this.state.selectedUser}
          selectedUserPromotion={this.state.selectedUser.promotions ?? []}
          balance={this.state.selectedUser.balance ?? 0}
          startPoint={this.props.scheduleStore.selectedSchedule.startPoint}
          endPoint={this.props.scheduleStore.selectedSchedule.endPoint}
          handleGetSelectedSchedule={this.handleGetSelectedSchedule}
          onClose={this.props.handleOpenScheduleUpdateModal}
          updateRefresh={this.handleRefreshNav}
          currentUserId={this.props.authStore.user?.id ?? ""}
        />
        <AddressModal
          selectedUser={this.state.selectedUser}
          open={this.props.updateAddressModalVisible}
          selectedSchedule={this.props.scheduleStore.selectedSchedule}
          handleGetSelectedSchedule={this.handleGetSelectedSchedule}
          onClose={this.props.handleOpenAddressModal}
        />
        <div className="mt-8">
          <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
            {creation ? "New Schedule" : "Schedule"}
          </h2>
          {this.renderContent()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    scheduleStore: state.scheduleStore,
    promotionStore: state.promotionStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectedSchedule: (schedule: ScheduleModel) =>
      dispatch(updateSelectedSchedule(schedule)),
    getPromotionList: (selectedUser: UserModel) =>
      dispatch(getPromotionList(selectedUser)),
    clearSelectedSchedule: () => dispatch(clearSelectedSchedule()),
    clearPromotion: () => dispatch(clearPromotion()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SchedulesEditor);
