import { Address } from "./User";

export enum OperationalType {
  OPERATIONAL = "Open",
  CLOSED_TEMPORARILY = "Closed",
  CLOSED_PERMANENTLY = "Ceased",
}

export enum ActivityType {
  SENT_EMAIL = "Sent Email",
  CONTACTED = "Contacted",
  COMMENT = "Comment",
}

export enum Flag {
  RED = "Red",
  ORANGE = "Orange",
  YELLOW = "Yellow",
  GREEN = "Green",
  BLUE = "Blue",
  INDIGO = "Indigo",
  VIOLET = "Violet",
}

export interface ActivityLog {
  activityType: keyof typeof ActivityType;
  comment: string;
  creationDate: Date;
}

/**
 * @collection restaurants
 * @description Restaurant List of Arus Oil Operation Dashboard
 *
 * id - GoogleMap API place id
 * name: Name of the restaurant;
 * businessStatus: Current operational status of the restaurant;
 * address: Address of the restaurant with lng and lat;
 * activityLogs: Activity log of the admin;
 * createdAt : Date of creation of Restaurant model
 * flag: Flag colour assign to the restaurant (Optional - Rainbow Color) ;
 * phoneNumber: Contact number of restaurant (Optional);
 * email: Email of the restaurant (Optional);
 * website: Website of the restaurant (Optional);
 */

export interface Restaurant {
  id: string;
  name: string;
  businessStatus: keyof typeof OperationalType;
  address: Address;
  activityLogs: ActivityLog[];
  createdAt: Date;
  flag?: keyof typeof Flag;
  phoneNumber?: string;
  email?: string;
  website?: string;
}
