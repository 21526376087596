import {
  BellIcon,
  CheckIcon,
  HandThumbUpIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import axios from "axios";
import {
  Button,
  EmptyState,
  Feed,
  Feeds,
  Form,
  Link,
  Loading,
  Select,
} from "blixify-ui-web/lib";
import moment from "moment";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import DailyAnalyticsModel from "../../models/DailyAnalytics";
import Table, { TableHeader } from "../base/Table";
import {
  getDailyAnalytics,
  getWeeklyAnalytics,
} from "../store/actions/analyticsAction";
import { authStateInterface } from "../store/reducers/authReducer";
import { Modal } from "../base/Modal";

interface Props {
  authStore: authStateInterface;
}

interface State {
  analytics: DailyAnalyticsModel;
  weeklyAnalytics: DailyAnalyticsModel[];
  reportLoading: boolean;
  reportModal: boolean;
  reportLink: string;
  reportInput: {
    type: string;
    promoCode: string;
  };
}

const feeds: Feed[] = [
  {
    iconClass: "bg-gray-400",
    iconType: <UserIcon className="h-5 w-5 text-white" />,
    content: "Accept a Job to begin your journey",
    step: "Step 1",
    addContent: (
      <p className="mt-2 text-sm text-gray-500">
        Navigate to the "Job" page, view each job's details, and accept it if
        you can collect it from the stated address. Once accepted, only the job
        will be assigned to your account and hidden from others.(Only jobs under
        your promotion code will be available to be accepted)
      </p>
    ),
  },
  {
    iconClass: "bg-blue-500",
    iconType: <HandThumbUpIcon className="h-5 w-5 text-white" />,
    content: "Update your Job Status",
    step: "Step 2",
    addContent: (
      <div>
        <p className="mt-2 text-sm text-gray-500">
          Update the job status as the following
          <br />- Scheduled - Schedule the collection date
          <br />- Dispatched - Place your vehicle no on the day of collection
          <br />- Completed - Completed the collection
        </p>
        <img
          className="mt-4 w-36 sm:w-56 h-auto"
          src="https://arusoil.com/static/oil_collection_drum-ddf6f26e6fbb1bc058bffb0faa722f51.webp"
          alt=""
        />
      </div>
    ),
  },
  {
    iconClass: "bg-green-500",
    iconType: <CheckIcon className="h-5 w-5 text-white" />,
    content: "Once collected, update the job as Completed status",
    step: "Step 3",
    addContent: (
      <p className="mt-2 text-sm text-gray-500">
        Upon collected the used cooking oil from the customer, update the job as
        completed and select the option of whether you are paying by cash or
        deducting LPG gas amount
      </p>
    ),
  },
];

const userPromoType = [
  {
    key: "promoPetronas",
    label: "Petronas",
  },
  {
    key: "promoOillax",
    label: "Oillax",
  },
  { key: "promoIPC", label: "IPC" },
  { key: "promoIOI", label: "IOI" },
  { key: "promoECO", label: "ECOWorld" },
  { key: "promoPUD", label: "PUD" },
];
const schedulePromoType = [
  { key: "petronas", label: "Petronas" },
  { key: "oillax", label: "Oillax" },
  { key: "ipc", label: "IPC" },
  { key: "ioi", label: "IOI" },
  { key: "eco", label: "ECOWorld" },
  { key: "pud", label: "PUD" },
  { key: "brunei", label: "Brunei" },
];

class Home extends Component<Props> {
  reportFormRef: any = createRef();
  reportTypeRef: any = createRef();
  reportPromoCodeRef: any = createRef();
  state: State = {
    analytics: {
      userNumber: 0,
      appointmentMade: 0,
      createdDate: moment().toDate(),
      dateString: "",
      dateStringWithTime: "",
    },
    weeklyAnalytics: [],
    reportModal: false,
    reportLoading: false,
    reportLink: "",
    reportInput: {
      type: "",
      promoCode: "",
    },
  };

  componentDidMount = async () => {
    if (this.props.authStore.user?.role !== "AGENT") {
      let analytics = await getDailyAnalytics();
      const weeklyAnalytics = await getWeeklyAnalytics();
      if (analytics && typeof analytics !== "string") {
        this.setState({
          analytics,
          weeklyAnalytics,
        });
      }
    }
  };

  handleReportModal = (value: boolean) => {
    this.setState({ reportModal: value, reportLink: "" });
    if (!value) {
      this.setState({
        reportLink: "",
        reportInput: {
          type: "",
          promoCode: "",
        },
      });
    }
  };

  handleReportInput = (id: string, value: string) => {
    const reportInput = JSON.parse(JSON.stringify(this.state.reportInput));
    reportInput[id] = value;
    if (id === "type") reportInput["promoCode"] = "";
    this.setState({ reportInput });
  };

  handleGenerateExcel = async () => {
    this.setState({ reportLoading: true });
    try {
      const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
      let endPoint = "getPartnerSchedules";
      if (this.state.reportInput.type === "user") endPoint = "getPartnerUsers";
      let defaultWebAPI = `https://asia-southeast2-arusoil-web-dev.cloudfunctions.net/${endPoint}`;
      if (isProd)
        defaultWebAPI = `https://asia-southeast2-arusoil-web.cloudfunctions.net/${endPoint}`;

      const reportRes = await axios.post(defaultWebAPI, {
        type: this.state.reportInput.promoCode,
        token: process.env.REACT_APP_ADMIN_TOKEN,
      });
      if (reportRes.data) {
        this.setState({ reportLink: reportRes.data.link });
      } else throw "Error";
    } catch (err) {}
    this.setState({ reportLoading: false });
  };

  renderDashboardContent = () => {
    const content = [
      {
        icon: <UserGroupIcon className="h-5 w-5 text-white" />,
        title: "Registered Users",
        content: `${this.state.analytics.userNumber}`,
        span: `Updated on: ${moment(
          this.state.analytics.createdDate.seconds * 1000
        ).format("DD/MM/YYYY ha")}`,
        fontSize: "text-lg",
      },
      {
        icon: <BellIcon className="h-5 w-5 text-white" />,
        title: "Scheduled Appointment",
        content: `${this.state.analytics.appointmentMade}`,
        span: `Updated on: ${moment(
          this.state.analytics.createdDate.seconds * 1000
        ).format("DD/MM/YYYY ha")}`,
        fontSize: "text-lg",
        viewBox: "0 0 24 24",
        inverted: true,
      },
    ];

    const contentViewList: any = [];

    content.map((eachContent, key: number) => {
      contentViewList.push(
        <div className="bg-white overflow-hidden shadow rounded-lg" key={key}>
          <div className="p-5">
            <div className="flex">
              <div className="flex-shrink-0">
                <span className="flex p-2 mt-1 rounded-lg bg-arusgreen-600">
                  {eachContent.icon}
                </span>
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-md font-medium text-gray-500 truncate">
                    {eachContent.title}
                  </dt>
                  <dd>
                    <p className="text-xs font-medium text-gray-500 truncate">
                      {eachContent.span}
                    </p>
                    <div
                      className={`${eachContent.fontSize} font-medium text-gray-900 mt-2`}
                    >
                      {eachContent.content}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );
      return null;
    });

    return contentViewList;
  };

  renderWeeklyAnalytics = () => {
    const tableData: any[] = [];
    if (this.state.weeklyAnalytics.length > 0) {
      const weeklyAnalyticsLength = this.state.weeklyAnalytics.length;
      this.state.weeklyAnalytics.map((eachDailyAnalytics, index) => {
        if (index + 1 !== weeklyAnalyticsLength) {
          const nextDayAnalytics = this.state.weeklyAnalytics[index + 1];
          const userGrowth =
            eachDailyAnalytics.userNumber - nextDayAnalytics.userNumber;
          const appointmentGrowth =
            eachDailyAnalytics.appointmentMade -
            nextDayAnalytics.appointmentMade;
          tableData.push({
            id: index,
            activity: `You have increased ${userGrowth} users and ${appointmentGrowth} appointments today`,
            date: moment(eachDailyAnalytics.createdDate).format("DD MMMM YY"),
          });
        }
        return null;
      });
    }
    return tableData;
  };

  renderReportModal = () => {
    if (this.state.reportLink) {
      return (
        <EmptyState
          className="mt-4"
          icon={<CheckCircleIcon className="w-12 h-12 text-primary-600" />}
          text={`Data have been successfully exported on ${this.state.reportLink}`}
          callToAction={
            <div className="flex flex-row">
              <Button
                className="mr-3"
                text="Cancel"
                size="small"
                type="light"
                onClick={this.handleReportModal.bind(this, false)}
              />
              <Link href={this.state.reportLink} linkType="external">
                <Button text="Download" type="normal" size="small" />
              </Link>
            </div>
          }
        />
      );
    }
    if (this.state.reportLoading) {
      return (
        <div className="w-full mt-5 flex flex-col items-center">
          <Loading />
          <p className="mt-2">Generating Excel...</p>
        </div>
      );
    }
    return (
      <Form
        ref={this.reportFormRef}
        refList={[this.reportPromoCodeRef, this.reportTypeRef]}
        handleComplete={this.handleGenerateExcel}
      >
        <p className="text-sm text-gray-600">
          Generate a MATCH report by selecting the below options. A generated
          report link will be sent to your email
        </p>
        <Select
          ref={this.reportTypeRef}
          containerClassName="mt-3"
          value={this.state.reportInput.type}
          label="Report Type"
          options={[
            {
              key: "user",
              label: "Users",
            },
            {
              key: "schedule",
              label: "Schedules",
            },
          ]}
          onChange={(value: string) => {
            this.handleReportInput("type", value);
          }}
        />
        <Select
          ref={this.reportPromoCodeRef}
          containerClassName="mt-3"
          value={this.state.reportInput.promoCode}
          label="Promo Type"
          options={
            this.state.reportInput.type
              ? this.state.reportInput.type === "user"
                ? userPromoType
                : schedulePromoType
              : []
          }
          onChange={(value: string) => {
            this.handleReportInput("promoCode", value);
          }}
        />

        <div className="mt-52 flex items-end justify-end">
          <Button
            text="Cancel"
            size="small"
            type="light"
            onClick={this.handleReportModal.bind(this, false)}
          />
          <Button
            className="ml-3"
            text="Generate"
            size="small"
            type="normal"
            onClick={() => {
              this.reportFormRef.current?.handleSubmit();
            }}
          />
        </div>
      </Form>
    );
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "activity",
        title: "Activity",
      },
      {
        key: "date",
        title: "Date",
      },
    ];

    return (
      <div className="mt-8">
        <Modal
          open={this.state.reportModal}
          title="Generate Excel"
          renderContent={this.renderReportModal}
        />
        {this.props.authStore.user?.role !== "AGENT" ? (
          <>
            <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
              <div className="rounded-md bg-green-50 flex p-4">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-arusgreen-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-green-700">
                    Generate Excel from Arus Oil Database
                  </p>
                  <p
                    className="cursor-pointer mt-3 text-sm text-green-700 hover:text-green-600 md:ml-6 md:mt-0"
                    onClick={this.handleReportModal.bind(this, true)}
                  >
                    Generate
                  </p>
                </div>
              </div>
            </div>

            <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Overview
              </h2>
              <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2">
                {this.renderDashboardContent()}
              </div>
            </div>

            <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
              Recent activities
            </h2>
            <Table
              pageIndex={1}
              lastCursor=""
              header={headers}
              data={this.renderWeeklyAnalytics()}
              dataLimit={10}
            />
          </>
        ) : (
          <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
            <h2 className="text-lg leading-6 font-medium text-gray-900 pb-2">
              Welcome To Arus Oil Driver Dashboard
            </h2>
            <div className="bg-white overflow-hidden shadow rounded-lg p-6">
              <Feeds content={feeds} bold={true} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(Home);
