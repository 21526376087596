import { HomeIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import stampSrc from "../../images/my_protech_stamp.jpg";
import imageSrc from "../../images/protech_receipt.jpg";
import { ScheduleModel } from "../../models/Schedules";
import { TransactionModel } from "../../models/Transaction";
import { UserModel } from "../../models/User";
import { getSchedule } from "../store/actions/schedulesAction";
import { getTransactionWithScheduleId } from "../store/actions/transactionAction";

interface Props {
  history: any;
  containerRef: any;
  agentView?: boolean;
}

interface State {
  dataParamId: string;
  signatureImageToURL: string;
  selectedUser: UserModel;
  scheduleData: ScheduleModel;
  selectedTransaction: TransactionModel;
}

class Receipt extends Component<Props> {
  timeout: any = null;
  state: State = {
    dataParamId: "",
    signatureImageToURL: "",
    selectedUser: {
      id: "",
      email: "",
      name: "",
      gender: "M",
      age: "M",
      profession: "ACC",
      mobileNo: "",
      familyMembers: "S",
      cookFrequency: "AVERAGE",
      createdAt: new Date(),
    },
    scheduleData: {
      phone: "",
      address: {
        name: "",
        lat: 0,
        lng: 0,
      },
      image: "",
      preferredDay: "START",
      preferredTime: "MORNING",
      additionRemark: "",
      estimatedWeight: 0,
      id: "",
      userId: "",
      status: "PEND",
      processes: [],
      updatedAt: moment().toDate(),
    },
    selectedTransaction: {
      id: "",
      type: "DEBIT",
      date: moment().toDate(),
      userId: "",
      scheduleId: "",
      receipt: "",
      amount: 0,
    },
  };

  componentDidMount() {
    this.handleGetSelectedReceiptData();
  }

  handleGetSelectedReceiptData = async () => {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    let defaultWebUrl =
      "https://firebasestorage.googleapis.com/v0/b/arusoil-web-dev.appspot.com/o/schedules%2F";
    if (isProd) {
      defaultWebUrl =
        "https://firebasestorage.googleapis.com/v0/b/arusoil-web.appspot.com/o/schedules%2F";
    }

    try {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedId = searchParams.get("data");
      this.setState({ dataParamId: selectedId });
      if (selectedId) {
        const scheduleData = await getSchedule(selectedId);
        const transactionData = await getTransactionWithScheduleId(selectedId);
        if (transactionData) {
          this.setState({
            selectedTransaction: transactionData,
          });
        }
        if (typeof scheduleData === "string") {
          this.handleBack();
        } else {
          this.setState({
            scheduleData: scheduleData.schedule,
            selectedUser: scheduleData.user,
          });
          if (this.state.scheduleData.signature) {
            const signatureUrl: any = await this.handleLoadSignatureImage(
              `${defaultWebUrl}${this.state.scheduleData.id}%2Fsignature.jpg?alt=media&token=${this.state.scheduleData.signature}`
            );
            this.setState(
              {
                signatureImageToURL: signatureUrl,
              },
              () => {
                this.handleGetReceipt();
              }
            );
          } else {
            this.handleGetReceipt();
          }
        }
      }
    } catch (err) {}
  };

  handleLoadSignatureImage = async (imageUrl: string) => {
    try {
      const response = await axios.get(imageUrl, {
        responseType: "arraybuffer",
      });
      const imageBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const imageUrlObject = URL.createObjectURL(imageBlob);
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
          URL.revokeObjectURL(imageUrlObject);
          const canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          const context = canvas.getContext("2d");
          context?.drawImage(image, 0, 0);
          //INFO: Convert the loaded image to a data URL
          const dataUrl = canvas.toDataURL(response.headers["content-type"]);
          resolve(dataUrl);
        };
        image.onerror = (error) => {
          reject(error);
        };
        image.src = imageUrlObject;
      });
    } catch (error) {
      throw error;
    }
  };

  handleBack = (type?: string) => {
    let defaultUrl = `/dashboard/schedules`;
    if (this.props.agentView) {
      defaultUrl = "/dashboard/jobs";
    }
    if (type && type === "editor") {
      defaultUrl = `/dashboard/scheduleEditor?data=${this.state.dataParamId}`;
    }
    this.props.history.push(defaultUrl);
  };

  handleLoadImage = async (url: string) => {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.onload = () => {
        resolve(image);
      };

      image.onerror = (error) => {
        reject(error);
      };

      image.src = url;
    });
  };

  handleGetReceipt = async () => {
    const canvas = this.props.containerRef.current;
    if (!canvas) return null;

    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    if (this.state.scheduleData.processes) {
      let receiptPrice = 0;
      let receiptAmount = "";
      this.state.scheduleData.processes.map((eachProcess) => {
        if (eachProcess.type === "COMPLETED") {
          receiptPrice = eachProcess.amount
            ? parseFloat(eachProcess.amount.toFixed(2))
            : 0;
          receiptAmount = eachProcess.description
            ? parseFloat(eachProcess.description).toFixed(2)
            : "";
        }
        return null;
      });
      const image = await this.handleLoadImage(imageSrc);
      const stampImage = await this.handleLoadImage(stampSrc);
      let signatureImage: any = null;
      if (this.state.signatureImageToURL) {
        signatureImage = await this.handleLoadImage(
          this.state.signatureImageToURL
        );
      }

      //INFO : Images
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      ctx.font = "13px Arial";
      ctx.drawImage(stampImage, 1000, 670, 80, 80);
      if (signatureImage) ctx.drawImage(signatureImage, 700, 650, 80, 80);
      ctx.fillText(this.state.selectedTransaction.id, 1000, 65);
      ctx.fillText(1, 30, 250);

      //INFO : Receipt Price
      ctx.fillText(receiptAmount, 150, 250);
      ctx.fillText(
        (receiptPrice / parseFloat(receiptAmount)).toFixed(2),
        320,
        250
      );
      ctx.fillText(receiptPrice, 510, 250);
      ctx.fillText(receiptPrice, 510, 440);

      //INFO : Point of Origin
      ctx.fillText(this.state.selectedUser.name, 250, 530);
      ctx.fillText(this.state.scheduleData.address.name, 250, 570);
      ctx.fillText(
        "Lat: " +
          this.state.scheduleData.address.lat +
          " , " +
          "Long: " +
          this.state.scheduleData.address.lng,
        250,
        610
      );
      ctx.fillText("Malaysia", 250, 650);
      ctx.fillText("✓", 230, 700);

      //INFO : Arus Signature
      ctx.fillText("+60137311007", 1050, 765);
      ctx.fillText(
        moment(this.state.scheduleData.updatedAt.seconds * 1000).format(
          "DD/MM/YYYY"
        ),
        1050,
        785
      );
      //INFO : User Signature
      ctx.fillText(this.state.scheduleData.phone, 750, 765);
      ctx.fillText(this.state.selectedUser.name, 750, 745);
      ctx.fillText(
        moment(this.state.scheduleData.updatedAt.seconds * 1000).format(
          "DD/MM/YYYY"
        ),
        750,
        785
      );
    }
  };

  renderBreadcrumb = () => {
    return (
      <nav
        className="bg-white border-b border-gray-200 flex mt-0.5 mx-auto w-full"
        aria-label="Breadcrumb"
      >
        <ol className="max-w-6xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8 ">
          <li className="flex">
            <div className="flex items-center">
              <p
                className="text-gray-600 hover:text-arusgreen-500 cursor-pointer"
                onClick={() => this.handleBack("home")}
              >
                <HomeIcon className="flex-shrink-0 h-5 w-5" />
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <p
                className="text-gray-600 hover:text-arusgreen-500 cursor-pointer"
                onClick={() => this.handleBack("editor")}
              >
                {this.renderBreadcrumbItem("Logistic ID")}
              </p>
            </div>
          </li>
          {this.renderBreadcrumbItem("Receipt")}
        </ol>
      </nav>
    );
  };

  renderBreadcrumbItem = (title: String) => {
    return (
      <li className="flex">
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <p className="ml-4 text-sm font-medium text-gray-500">{title}</p>
        </div>
      </li>
    );
  };

  render() {
    return (
      <>
        {this.renderBreadcrumb()}
        <div className="m-5">
          <h2 className="my-5 text-lg leading-6 font-medium text-gray-900 ">
            Preview Receipt
          </h2>
          <canvas
            ref={this.props.containerRef}
            width={1200}
            height={800}
            className="border border-grey-900 w-full lg:w-10/12"
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    transactionStore: state.transactionStore,
    scheduleStore: state.scheduleStore,
  };
};

export default connect(mapStateToProps)(Receipt);
