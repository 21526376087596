import { HomeIcon } from "@heroicons/react/24/outline";
import { TextInput, WriteQuery } from "blixify-ui-web/lib";
import firebase from "firebase";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import PromotionModel, { PromotionType } from "../../models/Promotion";
import Button from "../base/Button";
import Input from "../base/Input";
import Select, { Option } from "../base/Select";
import {
  createPromotionCode,
  deletePromotion,
  getPromotion,
  handlePromotionCondition,
  updateSelectedPromotion,
} from "../store/actions/promotionActions";
import { promotionStateInterface } from "../store/reducers/promotionReducer";
import ConfirmationModal from "./ConfirmationModal";
import { Modal } from "../base/Modal";

interface Props {
  history: any;
  promotionStore: promotionStateInterface;
  confirmationModalVisible: boolean;
  handleOpenConfirmationModal: (visible: boolean) => void;
  updateSelectedPromotion: (selectedPromotion: PromotionModel) => void;
}

export interface PromotionStateAttribute {
  id: string;
  increasePercentRate: number;
  fixedPriceRate: number;
  promotionType: keyof typeof PromotionType;
  redemptionLimit: number;
  durationAppliedInMonths: number;
}

export interface PromotionStateError {
  idError: string;
  redemptionLimitError: string;
  increasePercentRateError: string;
  fixedPriceRateError: string;
  durationAppliedInMonthsError: string;
}

interface State {
  promoEditModal: boolean;
  promoDuration: number;
  promotionState: PromotionStateAttribute;
  promotionStateError: PromotionStateError;
}

const promotionTypeOption: Option[] = Object.keys(PromotionType).map(
  (eachPromotionKey) => {
    return {
      key: eachPromotionKey,
      title: PromotionType[eachPromotionKey as keyof typeof PromotionType],
    };
  }
);

class PromotionEditor extends Component<Props> {
  state: State = {
    promoEditModal: false,
    promoDuration: 0,
    promotionState: {
      id: "",
      promotionType: "GENERIC",
      increasePercentRate: 0,
      fixedPriceRate: 0,
      redemptionLimit: 0,
      durationAppliedInMonths: 0,
    },
    promotionStateError: {
      idError: "",
      redemptionLimitError: "",
      increasePercentRateError: "",
      fixedPriceRateError: "",
      durationAppliedInMonthsError: "",
    },
  };

  componentDidMount = () => {
    this.handleGetPreviewData();
  };

  componentWillUnmount() {
    this.props.updateSelectedPromotion({
      id: "",
      type: "GENERIC",
      increasePercentRate: 0,
      expiryDate: moment().toDate(),
      durationAppliedInMonths: 0,
      createdAt: moment().toDate(),
    });
  }

  handleGetPreviewData = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId && selectedId !== "new") {
      const selectedPromotion = await getPromotion(selectedId);
      if (typeof selectedPromotion !== "string" && selectedPromotion?.id) {
        this.props.updateSelectedPromotion(selectedPromotion);
      } else {
        this.props.history.push("/dashboard/promotion");
      }
    }
  };

  handleInputChange = (e: any) => {
    const clonedPromotionState = JSON.parse(
      JSON.stringify(this.state.promotionState)
    );
    clonedPromotionState[e.target.id] = e.target.value;
    this.setState({
      promotionState: clonedPromotionState,
    });
  };

  handlePromoChange = (e: any) => {
    this.setState({
      promoDuration: Number(e.target.value),
    });
  };

  handleSelectChange = (id: string, key: string) => {
    const clonedPromotionAttribute = JSON.parse(
      JSON.stringify(this.state.promotionState)
    );
    clonedPromotionAttribute[id] = key;
    this.setState({
      promotionState: clonedPromotionAttribute,
    });
  };

  handleUpdatePromoCode = async () => {
    if (this.state.promoDuration > 0) {
      try {
        const selectedPromotion = this.props.promotionStore.selectedPromotion;
        const assignQuery = new WriteQuery({
          dbModule: firebase,
          dbType: "firebase",
          dbCollection: "promotion",
        });
        const res = await assignQuery.call("update", {
          id: selectedPromotion.id,
          data: { durationAppliedInMonths: this.state.promoDuration },
        });
        if (res) {
          this.setState({ promoDuration: 0 });
          this.handlePromoModal(false);
          this.handleGetPreviewData();
        }
      } catch (err) {}
    }
  };

  handleDeletePromotion = async () => {
    let deleteError = await deletePromotion(
      this.props.promotionStore.selectedPromotion.id
    );
    if (deleteError) {
    } else {
      this.props.history.push("/dashboard/promotion?data=r");
    }
  };

  handlePromoModal = (open: boolean) => {
    this.setState({
      promoEditModal: open,
    });
  };

  handleSubmit = () => {
    let conditionsList: string[] = [
      "id",
      "redemptionLimit",
      "durationAppliedInMonths",
    ];
    if (this.state.promotionState.promotionType === "FLAT") {
      conditionsList.push("fixedPriceRate");
    } else {
      conditionsList.push("increasePercentRate");
    }

    const clonedPromotionError = JSON.parse(
      JSON.stringify(this.state.promotionStateError)
    );

    handlePromotionCondition(
      this.state.promotionState,
      clonedPromotionError,
      conditionsList
    );
    this.setState(
      {
        promotionStateError: clonedPromotionError,
      },
      async () => {
        if (
          !this.state.promotionStateError.idError &&
          !this.state.promotionStateError.durationAppliedInMonthsError &&
          !this.state.promotionStateError.redemptionLimitError
        ) {
          let promotionAbleToCreate = false;
          if (
            this.state.promotionState.promotionType === "FLAT" &&
            !this.state.promotionStateError.fixedPriceRateError
          ) {
            promotionAbleToCreate = true;
          } else if (
            this.state.promotionState.promotionType === "GENERIC" &&
            !this.state.promotionStateError.increasePercentRateError
          ) {
            promotionAbleToCreate = true;
          }

          if (promotionAbleToCreate) {
            const creationError = await createPromotionCode(
              this.state.promotionState
            );
            if (creationError) {
              const clonedPromotionError = JSON.parse(
                JSON.stringify(this.state.promotionStateError)
              );
              clonedPromotionError["idError"] = creationError;
              this.setState({
                promotionStateError: clonedPromotionError,
              });
            } else {
              this.props.history.push("/dashboard/promotion?data=r");
            }
          }
        }
      }
    );
  };

  renderBreadcrumb = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    return (
      <nav
        className="bg-white border-b border-gray-200 flex mt-0.5 mx-auto w-full"
        aria-label="Breadcrumb"
      >
        <ol className="max-w-6xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8 ">
          <li className="flex">
            <div className="flex items-center">
              <p
                className="text-gray-600 hover:text-arusgreen-500 cursor-pointer"
                onClick={() => {
                  this.props.history.push("/dashboard/promotion");
                }}
              >
                <HomeIcon className="flex-shrink-0 h-5 w-5" />
              </p>
            </div>
          </li>
          {selectedId === "new"
            ? this.renderBreadcrumbItem(`Add Promotions`)
            : this.renderBreadcrumbItem(`Promotion Details`)}
        </ol>
      </nav>
    );
  };

  renderBreadcrumbItem = (title: String) => {
    return (
      <li className="flex">
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <p className="ml-4 text-sm font-medium text-gray-500">{title}</p>
        </div>
      </li>
    );
  };

  renderTitle = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId === "new") {
      return "Add Promotion";
    }
  };

  renderContent = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    const selectedPromotion = this.props.promotionStore.selectedPromotion;

    if (selectedId !== "new") {
      return (
        <>
          <div className="flex flex-col mt-2">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Promotion Details
                </h3>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Promotion Code
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {selectedPromotion.id}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Type</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {selectedPromotion.type}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      {selectedPromotion.type === "FLAT"
                        ? "Fixed Price Rate"
                        : "Increase Percent Rate"}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {selectedPromotion.type === "FLAT"
                        ? `RM${selectedPromotion.fixedPriceRate}`
                        : `${selectedPromotion.increasePercentRate} %`}
                    </dd>
                  </div>
                  {selectedPromotion.type === "GENERIC" ||
                  selectedPromotion.type === "FLAT" ? (
                    <>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          User Redeemption Limit
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {selectedPromotion.genericStats?.redemptionLimit}
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          User Redeem Counter
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {selectedPromotion.genericStats?.redemptionCounter}
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Duration Applied in Months
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {selectedPromotion.durationAppliedInMonths} Months
                          <span
                            className="text-sm font-medium text-arusgreen-500 ml-4 cursor-pointer"
                            onClick={this.handlePromoModal.bind(this, true)}
                          >
                            Update
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Created At
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {moment(
                            selectedPromotion.createdAt.seconds * 1000
                          ).format("DD/MM/YY")}
                        </dd>
                      </div>
                    </>
                  ) : (
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        User Redeemption
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {selectedPromotion.uniqueStats?.redeemed
                          ? "Redeemed"
                          : "Not yet redeem"}
                      </dd>
                    </div>
                  )}
                </dl>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="bg-white shadow-xl grid grid-cols-1 lg:grid-cols-2">
          <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
            <form
              action="#"
              method="POST"
              className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Promotion Id
                </label>
                <Input
                  id="id"
                  className="mt-1"
                  placeholder="Promotion Id"
                  value={this.state.promotionState.id}
                  error={this.state.promotionStateError.idError}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Promotion Type
                </label>
                <Select
                  id="promotionType"
                  value={this.state.promotionState.promotionType}
                  options={promotionTypeOption}
                  onChange={this.handleSelectChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Duration Applied In Months
                </label>
                <Input
                  id="durationAppliedInMonths"
                  className="mt-1"
                  placeholder="Duration (Months)"
                  type="number"
                  value={this.state.promotionState.durationAppliedInMonths?.toString()}
                  error={
                    this.state.promotionStateError.durationAppliedInMonthsError
                  }
                  onChange={this.handleInputChange}
                />
              </div>
              {this.state.promotionState.promotionType === "FLAT" ? (
                <div className="col-span-2 sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Fixed Price Rate
                  </label>
                  <Input
                    id="fixedPriceRate"
                    type="number"
                    className="mt-1"
                    placeholder="Fixed Price Rate"
                    value={this.state.promotionState.fixedPriceRate?.toString()}
                    error={this.state.promotionStateError.fixedPriceRateError}
                    onChange={this.handleInputChange}
                  />
                </div>
              ) : (
                <div className="col-span-2 sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Increase Percent Rate
                  </label>
                  <Input
                    id="increasePercentRate"
                    type="number"
                    className="mt-1"
                    placeholder="Increase Percent Rate"
                    value={this.state.promotionState.increasePercentRate?.toString()}
                    error={
                      this.state.promotionStateError.increasePercentRateError
                    }
                    onChange={this.handleInputChange}
                  />
                </div>
              )}

              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  User Redemption Limit
                </label>
                <Input
                  id="redemptionLimit"
                  className="mt-1"
                  placeholder="Duration (Months)"
                  type="number"
                  value={this.state.promotionState.redemptionLimit?.toString()}
                  error={this.state.promotionStateError.redemptionLimitError}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="sm:col-span-2 sm:flex sm:justify-end mt-5">
                <Button
                  className="w-full sm:w-auto"
                  text="Submit"
                  type="normal"
                  onClick={this.handleSubmit}
                ></Button>
              </div>
            </form>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <Modal
          title="Modal Title"
          open={this.state.promoEditModal}
          onClose={this.handlePromoModal.bind(this, false)}
          renderContent={() => (
            <>
              <div className="mt-2 mb-8 whitespace-pre-line">
                <TextInput
                  value={this.state.promoDuration}
                  type="number"
                  label="Duration Applied In Months"
                  placeholder="0"
                  onChange={this.handlePromoChange}
                />
              </div>
              <div className="flex flex-row justify-end">
                <Button
                  text="Cancel"
                  type="light"
                  className="mx-4"
                  onClick={this.handlePromoModal.bind(this, false)}
                />
                <Button
                  className="w-full sm:w-auto"
                  text="Submit"
                  type="normal"
                  onClick={this.handleUpdatePromoCode}
                />
              </div>
            </>
          )}
        ></Modal>
        {this.renderBreadcrumb()}
        <div className="mt-8">
          <ConfirmationModal
            content="Are you sure that you want to delete this promotion code?"
            open={this.props.confirmationModalVisible}
            title="Delete Promotion Confirmation"
            onClose={this.props.handleOpenConfirmationModal}
            onClick={this.handleDeletePromotion}
          />
          <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
            {this.renderTitle()}
          </h2>

          <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
            {this.renderContent()}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    promotionStore: state.promotionStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectedPromotion: (selectedPromotion: PromotionModel) =>
      dispatch(updateSelectedPromotion(selectedPromotion)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionEditor);
