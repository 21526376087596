export enum AdminRole {
  SUPER = "Super Admin",
  MANAGER = "Manager",
  DATA = "Data Admin",
  AGENT = "Agent Driver",
}

export enum States {
  JHR = "Johor",
  KDH = "Kedah",
  KLT = "Kelantan",
  MLK = "Melaka",
  MLC = "Malacca",
  NS = "Negeri Sembilan",
  PHG = "Pahang",
  PR = "Perak",
  PL = "Perlis",
  PN = "Penang",
  PNG = "Pulau Pinang",
  SBH = "Sabah",
  SLG = "Selangor",
  TRN = "Terengganu",
  KL = "Wilayah Persekutuan Kuala Lumpur",
  FT = "Federal Territory of Kuala Lumpur",
  LB = "Labuan Federal Territory",
  PT = "Putrajaya",
  BN = "Brunei",
}

/**
 * @collection admin
 * @description Admin model schema for Arus Oil Internal Team
 *
 * id - Auto generated on Firebase Auth
 * name - Name of the Admin
 * email - Email of the Admin
 * role - Admin Role interface
 * state - Exist for Manager Role
 * promo - Exist for Agent Driver
 */
export default interface Admin {
  id: string;
  name: string;
  email: string;
  role: keyof typeof AdminRole;
  state?: (keyof typeof States)[];
  promo?: string;
}
