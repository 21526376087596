import { HomeIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Ngo from "../../models/Ngo";
import Reward from "../../models/Reward";
import { ScheduleModel } from "../../models/Schedules";
import { TransactionModel } from "../../models/Transaction";
import { UserModel } from "../../models/User";
import Button from "../base/Button";
import Input from "../base/Input";
import Overlay from "../base/Overlay";
import { getSchedule } from "../store/actions/schedulesAction";
import {
  createCreditTransaction,
  getTransaction,
  handleTransactionCondition,
  updateSelectedTransaction,
} from "../store/actions/transactionAction";
import ConfirmationModal from "./ConfirmationModal";

interface Props {
  history: any;
  trigger: boolean;
  updateSelectedTransaction: (
    user: UserModel,
    transaction: TransactionModel
  ) => void;
}

export interface TransactionEditorState {
  email: string;
  amount: string;
  date: string;
}

export interface TransactionEditorError {
  emailError: string;
  amountError: string;
  dateError: string;
}

interface State {
  selectedUser: UserModel;
  selectedTransaction: TransactionModel;
  selectedNgo: Ngo | null;
  selectedReward: Reward | null;
  transaction: TransactionEditorState;
  transactionError: TransactionEditorError;
  selectedSchedule: ScheduleModel;
  loading: boolean;
  force: boolean;
  confirmationModalVisible: boolean;
}

class TransactionEditor extends Component<Props> {
  state: State = {
    selectedUser: {
      id: "",
      email: "",
      name: "",
      gender: "M",
      age: "M",
      profession: "ACC",
      mobileNo: "",
      familyMembers: "S",
      cookFrequency: "AVERAGE",
      createdAt: new Date(),
    },
    selectedReward: {
      id: "",
      name: "",
      validity: "",
      description: "",
      oneliner: "",
      category: "",
      rewardLogo: "",
      rewardBanner: "",
      importance: 0,
      value: 0,
      tag: "",
    },
    selectedTransaction: {
      id: "",
      type: "DEBIT",
      date: moment().toDate(),
      userId: "",
      scheduleId: "",
      receipt: "",
      amount: 0,
    },
    transaction: {
      email: "",
      amount: "",
      date: "",
    },
    selectedSchedule: {
      id: "",
      additionRemark: "",
      address: {
        lat: 0,
        lng: 0,
        name: "",
      },
      estimatedWeight: 0,
      image: "",
      phone: "",
      preferredDay: "ANY",
      preferredTime: "AFTERNOON",
      processes: [
        {
          date: moment().toDate(),
          type: "CREATED",
          amount: 0,
          description: "",
        },
      ],
      signature: "",
      status: "PEND",
      updatedAt: moment().toDate(),
      userId: "",
    },
    transactionError: {
      emailError: "",
      amountError: "",
      dateError: "",
    },
    selectedNgo: null,
    loading: false,
    force: false,
    confirmationModalVisible: false,
  };

  componentDidMount = async () => {
    this.props.updateSelectedTransaction(
      this.state.selectedUser,
      this.state.selectedTransaction
    );
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId && selectedId !== "new") {
      const userData = await getTransaction(selectedId);
      if (typeof userData === "string") {
        this.handleBack();
      } else {
        this.setState({
          selectedUser: userData.user,
          selectedTransaction: userData.transaction,
          selectedNgo: userData.ngo ?? null,
          selectedReward: userData.reward ?? null,
        });
        this.props.updateSelectedTransaction(
          userData.user,
          userData.transaction
        );
        if (userData.transaction.scheduleId) {
          const scheduleData = await getSchedule(
            userData.transaction.scheduleId
          );
          if (typeof scheduleData !== "string") {
            this.setState({
              selectedSchedule: scheduleData.schedule,
            });
          }
        }
      }
    } else if (selectedId === "new") {
    } else {
      this.handleBack();
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.trigger !== this.props.trigger && this.props.trigger) {
      const clonedTransaction = JSON.parse(
        JSON.stringify(this.state.transaction)
      );
      clonedTransaction["email"] = this.state.selectedUser.email;
      clonedTransaction["amount"] = this.state.selectedTransaction.amount;
      this.setState({
        transaction: clonedTransaction,
        force: true,
      });
      const clonedSelectedTransaction = JSON.parse(
        JSON.stringify(this.state.selectedTransaction)
      );
      clonedSelectedTransaction["type"] = "CREDIT";
      this.props.updateSelectedTransaction(
        this.state.selectedUser,
        clonedSelectedTransaction
      );
    }
  }

  handleBack = () => {
    this.props.history.push("/dashboard/transaction");
  };

  handleConfirmationModalVisible = (visible: boolean) => {
    this.setState({
      confirmationModalVisible: visible,
    });
  };

  handleInputChange = (e: any) => {
    const clonedTransaction = JSON.parse(
      JSON.stringify(this.state.transaction)
    );
    clonedTransaction[e.target.id] = e.target.value;
    this.setState({
      transaction: clonedTransaction,
    });
  };

  handleSubmit = () => {
    const conditionsList: string[] = ["email", "amount", "date"];
    const clonedTransactionError = JSON.parse(
      JSON.stringify(this.state.transactionError)
    );
    handleTransactionCondition(
      this.state.transaction,
      clonedTransactionError,
      conditionsList
    );
    this.setState(
      {
        transactionError: clonedTransactionError,
      },
      async () => {
        if (
          !this.state.transactionError.amountError &&
          !this.state.transactionError.emailError &&
          !this.state.transactionError.dateError
        ) {
          this.handleConfirmationModalVisible(true);
        }
      }
    );
  };

  handleCreateTransaction = async () => {
    this.setState({ loading: true });
    // TODO : Add Payment
    const creationError = await createCreditTransaction(
      true,
      this.state.transaction.email,
      Number(Number(this.state.transaction.amount).toFixed(2)),
      this.state.transaction.date,
      "email",
      "Bank"
    );

    if (creationError) {
      const clonedTransactionError = JSON.parse(
        JSON.stringify(this.state.transactionError)
      );
      clonedTransactionError["emailError"] = creationError;
      this.setState({
        transactionError: clonedTransactionError,
        loading: false,
      });
    } else {
      this.handleBack();
    }
  };

  renderBreadcrumb = () => {
    return (
      <nav
        className="bg-white border-b border-gray-200 flex mt-0.5 mx-auto w-full"
        aria-label="Breadcrumb"
      >
        <ol className="max-w-6xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8 ">
          <li className="flex">
            <div className="flex items-center">
              <p
                className="text-gray-600 hover:text-arusgreen-500 cursor-pointer"
                onClick={this.handleBack}
              >
                <HomeIcon className="flex-shrink-0 h-5 w-5" />
              </p>
            </div>
          </li>
          {this.renderBreadcrumbItem(`Transaction Details`)}
        </ol>
      </nav>
    );
  };

  renderBreadcrumbItem = (title: String) => {
    return (
      <li className="flex">
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <p className="ml-4 text-sm font-medium text-gray-500">{title}</p>
        </div>
      </li>
    );
  };

  renderAmountPaid = () => {
    let amountPaid = 0;
    const processLength = this.state.selectedSchedule.processes.length;
    const paid = Number(
      this.state.selectedSchedule.processes[processLength - 1].description
    );
    const amount = Number(
      this.state.selectedSchedule.processes[processLength - 1].amount
    );
    if (paid !== undefined && amount !== undefined) {
      amountPaid = amount / paid;
      return amountPaid.toFixed(2);
    } else {
      return "Unknown Error";
    }
  };

  renderContent = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    const donation = this.state.selectedTransaction.doneeId ? true : false;

    if (selectedId !== "new" && !this.state.force) {
      return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Transaction Details
            </h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Transaction ID
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.selectedTransaction.id}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Transaction Type
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.selectedTransaction.type}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Date</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {moment(
                    this.state.selectedTransaction.date.seconds * 1000
                  ).format("DD-MM-YY")}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.selectedUser.name}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.selectedUser.email}
                </dd>
              </div>
              {donation && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Donee Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.selectedNgo?.name}
                  </dd>
                </div>
              )}
              {this.state.selectedReward ? (
                <>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Rewards ID
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {this.state.selectedReward.id}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Rewards Name
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {this.state.selectedReward.name}
                    </dd>
                  </div>
                </>
              ) : (
                <>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      {donation ? "Donee Bank" : "Bank Name"}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {donation
                        ? this.state.selectedNgo?.bankName
                        : this.state.selectedUser.bankName}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      {donation ? "Donee Account Name" : "Account Name"}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {donation
                        ? this.state.selectedNgo?.bankAccountName
                        : this.state.selectedUser.bankAccountName}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      {donation ? "Donee Account Number" : "Account Number"}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {donation
                        ? this.state.selectedNgo?.bankAccountNo
                        : this.state.selectedUser.bankAccountNo}
                    </dd>
                  </div>
                </>
              )}

              {this.state.selectedSchedule.id && (
                <>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      UCO Collected(KG)
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {this.state.selectedSchedule.processes[
                        this.state.selectedSchedule.processes.length - 1
                      ].description ?? "No data found"}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Amount(RM)
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {this.state.selectedTransaction.amount}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Amount Paid per KG(RM)
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {this.renderAmountPaid()}
                    </dd>
                  </div>
                </>
              )}
            </dl>
          </div>
        </div>
      );
    } else {
      return (
        <div className="bg-white shadow-xl grid grid-cols-1 lg:grid-cols-2">
          <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  User's Email
                </label>
                <Input
                  id="email"
                  className="mt-1"
                  placeholder="User's Email"
                  value={this.state.transaction.email}
                  error={this.state.transactionError.emailError}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Transaction Date
                </label>
                <Input
                  id="date"
                  className="mt-1"
                  placeholder="DD-MM-YYYY"
                  value={this.state.transaction.date}
                  error={this.state.transactionError.dateError}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Credit Amount(RM)
                </label>
                <Input
                  id="amount"
                  className="mt-1"
                  placeholder="Amount"
                  value={this.state.transaction.amount}
                  error={this.state.transactionError.amountError}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="sm:col-span-2 sm:flex sm:justify-end mt-5">
                <Button
                  className="w-full sm:w-auto"
                  text="Submit"
                  type="normal"
                  onClick={this.handleSubmit}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <Overlay loading={this.state.loading} text="Creating Transaction" />
        <ConfirmationModal
          onClose={this.handleConfirmationModalVisible}
          title="Create Credit Transaction Confirmation"
          open={this.state.confirmationModalVisible}
          content={`Are you sure that you want to create a credit transaction (Arus Oil account -> User's bank account) with the following details? \n\n Email: ${
            this.state.transaction.email
          } \n Date: ${this.state.transaction.date} \n Amount: RM${Number(
            this.state.transaction.amount
          ).toFixed(2)}`}
          onClick={this.handleCreateTransaction}
        />
        {this.renderBreadcrumb()}
        <div className="mt-8">
          <div className="block mt-2">
            <div className="max-w-6xl mx-auto flex flex-col px-4 sm:px-6 lg:px-8">
              {this.renderContent()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectedTransaction: (
      user: UserModel,
      transaction: TransactionModel
    ) => dispatch(updateSelectedTransaction(user, transaction)),
  };
};

export default connect(null, mapDispatchToProps)(TransactionEditor);
