import { Dialog, DialogBackdrop, Transition } from "@headlessui/react";
import axios from "axios";
import {
  DataReference,
  InputDatePicker,
  UploadInput,
} from "blixify-ui-web/lib";
import moment from "moment";
import { Component, Fragment } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Process, Status } from "../../models/Schedules";
import { Address, PromotionApplied, UserModel } from "../../models/User";
import AddressInput from "../base/AddressInput";
import Button from "../base/Button";
import Input from "../base/Input";
import Select, { Option } from "../base/Select";
import {
  getEvents,
  handleScheduleCondition,
  handleUploadPaidByCashImage,
  updateScheduleDate,
  updateScheduleDistance,
  updateScheduleLocation,
  updateScheduleProcess,
  updateScheduleSignatureToken,
} from "../store/actions/schedulesAction";
import {
  activateReferral,
  calculatePayment,
  createCreditTransaction,
  createTNGPendingTransaction,
  createTransaction,
} from "../store/actions/transactionAction";
import { updateUserBalance } from "../store/actions/usersActions";

interface Props {
  open: boolean;
  agentView?: boolean;
  selectedId: string;
  selectedUser: UserModel;
  balance: number;
  updateScheduleModalType: string;
  process: Process[];
  selectedUserPromotion: PromotionApplied[];
  startPoint: Address | undefined;
  endPoint: Address | undefined;
  currentUserId: string;
  onClose: (visible: boolean) => void;
  updateRefresh: (refresh: boolean) => void;
  handleGetSelectedSchedule: (ignoreModal?: boolean) => void;
}

export enum UpdateProcessType {
  "SCHEDULED" = "Scheduled",
  "DECLINED" = "Declined",
  "DISPATCHED" = "Dispatched",
  "COMPLETED" = "Completed",
}

export interface UpdateScheduleProcessAttribute {
  processType: keyof typeof UpdateProcessType;
  desc: string;
  amount?: number;
}

export interface UpdateScheduleProcessAttributeError {
  processTypeError: string;
  descError: string;
  imageError: string;
}

interface State {
  updatedDate: string;
  confirmation: boolean;
  updateScheduleProcessAttribute: UpdateScheduleProcessAttribute;
  updateScheduleProcessAttributeError: UpdateScheduleProcessAttributeError;
  fileName: string | File;
  address: Address;
  addressError: string;
  travelDistance: number;
  travelDistanceError: string;
  creditAmount: number;
  purchaseAmount: number;
  selectedEvent: string;
  loading: boolean;
  dateError: string;
  signature: string;
  signatureError: string;
  paymentMethod: "Cash" | "Bank" | "Points" | "TNG";
}

const processTypeOption: Option[] = Object.keys(UpdateProcessType).map(
  (eachProcessKey) => {
    return {
      key: eachProcessKey,
      title:
        UpdateProcessType[eachProcessKey as keyof typeof UpdateProcessType],
    };
  }
);

export default class Modal extends Component<Props> {
  private canvasSignature: any = "";
  state: State = {
    updatedDate: "",
    updateScheduleProcessAttribute: {
      processType: "SCHEDULED",
      desc: moment().format("DD-MM-YYYY"),
      amount: 0,
    },
    updateScheduleProcessAttributeError: {
      processTypeError: "",
      descError: "",
      imageError: "",
    },
    fileName: "",
    address: {
      name: "",
      lat: 0,
      lng: 0,
    },
    addressError: "",
    creditAmount: 0,
    purchaseAmount: 0,
    travelDistance: 0,
    travelDistanceError: "",
    confirmation: false,
    selectedEvent: "",
    loading: false,
    dateError: "",
    signature: "",
    signatureError: "",
    paymentMethod: "Points",
  };

  componentDidUpdate = async (
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ) => {
    if (prevProps.open !== this.props.open && this.props.open) {
      this.setState({
        paymentMethod: this.props.selectedUser.tngSegment ? "TNG" : "Points",
      });
    }
    if (this.state.selectedEvent !== prevState.selectedEvent) {
      if (this.state.selectedEvent) {
        const data = await getEvents(this.state.selectedEvent);
        if (data?.events) {
          this.setState({
            purchaseAmount: data?.events?.price ?? 0,
          });
        }
      } else {
        this.setState({
          purchaseAmount: 0,
        });
      }
    }
  };

  handleCloseModal = (force?: boolean) => {
    if (force || !this.state.loading) {
      this.setState({
        updatedDate: "",
        updateScheduleProcessAttribute: {
          processType: "SCHEDULED",
          desc: moment().format("DD-MM-YYYY"),
          amount: 0,
        },
        updateScheduleProcessAttributeError: {
          processTypeError: "",
          descError: "",
          imageError: "",
        },
        address: {
          name: "",
          lat: 0,
          lng: 0,
        },
        addressError: "",
        creditAmount: 0,
        purchaseAmount: 0,
        travelDistance: 0,
        travelDistanceError: "",
        confirmation: false,
        dateError: "",
        selectedEvent: "",
        paymentMethod: "Points",
      });
      this.props.onClose(false);
    }
  };

  handleOnChangeDateInput = (e: any) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleInputChange = (e: any) => {
    const clonedUpdateScheduleAttribute = JSON.parse(
      JSON.stringify(this.state.updateScheduleProcessAttribute)
    );
    clonedUpdateScheduleAttribute[e.target.id] = e.target.value;

    this.setState({
      updateScheduleProcessAttribute: clonedUpdateScheduleAttribute,
    });
  };

  handleDateInputChange = (
    id: string,
    value: Date | undefined,
    type?: string
  ) => {
    const clonedUpdateScheduleAttribute = JSON.parse(
      JSON.stringify(this.state.updateScheduleProcessAttribute)
    );
    clonedUpdateScheduleAttribute["desc"] = moment(value).format("DD-MM-YYYY");

    this.setState({
      updateScheduleProcessAttribute: clonedUpdateScheduleAttribute,
    });
  };

  handleTravelDistanceChange = (e: any) => {
    this.setState({
      travelDistance: e.target.value,
    });
  };

  handlePurchaseAmountChange = (e: any) => {
    this.setState({
      purchaseAmount: e.target.value,
    });
  };

  handleUpdateScheduleDistance = async () => {
    this.handleLoading(true);
    if (Number(this.state.travelDistance) <= 0) {
      this.setState({
        travelDistanceError: "Please enter a valid travel distance",
      });
    } else {
      await updateScheduleDistance(
        this.props.selectedId,
        Number(this.state.travelDistance)
      );
      this.handleCloseModal(true);
      this.props.handleGetSelectedSchedule(true);
    }
    this.handleLoading(false);
  };

  handleUpdateScheduleLocation = async () => {
    this.handleLoading(true);

    if (this.state.address.name.replace(/\s/g, "").length <= 0) {
      this.setState({
        addressError: "Please enter a valid address",
      });
    } else {
      await updateScheduleLocation(
        this.props.selectedId,
        this.state.address,
        this.props.updateScheduleModalType
      );
      this.handleCloseModal(true);
      this.props.handleGetSelectedSchedule(true);
    }
    this.handleLoading(false);
  };

  handleSelectChange = (id: string, key: string) => {
    const clonedUpdateScheduleAttribute = JSON.parse(
      JSON.stringify(this.state.updateScheduleProcessAttribute)
    );
    clonedUpdateScheduleAttribute[id] = key;
    clonedUpdateScheduleAttribute["desc"] =
      key === "SCHEDULED" ? moment().format("DD-MM-YYYY") : "";
    this.setState({
      updateScheduleProcessAttribute: clonedUpdateScheduleAttribute,
      updateScheduleProcessAttributeError: {
        processTypeError: "",
        descError: "",
        imageError: "",
        amountError: "",
      },
    });
  };

  handleConfirmation = (confirmation: boolean) => {
    this.setState({
      confirmation: confirmation,
    });
    if (!confirmation) {
      this.setState({
        creditAmount: 0,
      });
    }
  };

  handleAddressChange = (address: Address) => {
    this.setState({
      address: address,
    });
  };

  handleCheckConfirmation = () => {
    try {
      let conditionsList: string[] = ["processType", "desc"];
      const updateScheduleProcessError = JSON.parse(
        JSON.stringify(this.state.updateScheduleProcessAttributeError)
      );
      handleScheduleCondition(
        this.state.updateScheduleProcessAttribute,
        updateScheduleProcessError,
        conditionsList,
        this.state.paymentMethod,
        this.state.fileName
      );

      this.setState(
        {
          updateScheduleProcessAttributeError: updateScheduleProcessError,
        },
        async () => {
          if (
            !this.state.updateScheduleProcessAttributeError.processTypeError &&
            !this.state.updateScheduleProcessAttributeError.descError
          ) {
            if (
              this.state.updateScheduleProcessAttribute.processType ===
                "COMPLETED" &&
              !this.state.updateScheduleProcessAttributeError.imageError
            ) {
              let amount = 0;
              if (this.state.purchaseAmount > 0)
                amount = Number(
                  (
                    this.state.purchaseAmount *
                    Number(this.state.updateScheduleProcessAttribute.desc)
                  ).toFixed(2)
                );
              else
                amount = await calculatePayment(
                  this.props.selectedUser.id,
                  Number(this.state.updateScheduleProcessAttribute.desc),
                  this.props.selectedUserPromotion,
                  this.props.selectedUser.tngSegment
                );
              this.setState({
                creditAmount: amount,
              });
            }
            this.handleConfirmation(true);
          }
        }
      );
    } catch (err) {}
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleUseCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        address: {
          name: "Current Location",
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
    });
  };

  handleCalculateDistance = (startPoint: Address, endPoint: Address) => {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(startPoint.lat - endPoint.lat); // deg2rad below
    var dLon = this.deg2rad(startPoint.lng - endPoint.lng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(startPoint.lat)) *
        Math.cos(this.deg2rad(endPoint.lat)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    this.setState({
      travelDistance: Number(d.toFixed(2)),
    });
    return "";
  };

  deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
  };

  handleUpdateDate = async () => {
    this.handleLoading(true);
    const newDate = moment(this.state.updatedDate, "DD/MM/YYYY");
    const response = await updateScheduleDate(
      this.props.selectedId,
      newDate.toDate()
    );
    if (response) {
      this.setState({
        dateError:
          "Update Fail, please ensure the date is valid (eg DD-MM-YYYY)",
      });
    } else {
      this.handleCloseModal(true);
      this.props.handleGetSelectedSchedule(true);
    }
    this.handleLoading(false);
  };

  handleIsCanvasBlank = (canvas: any) => {
    const context = canvas.getContext("2d");

    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );

    return !pixelBuffer.some((color) => color !== 0);
  };

  handleUpdateSchedule = async () => {
    let process: any = this.props.process;
    let amount = 0;

    if (this.state.updateScheduleProcessAttribute.processType === "COMPLETED") {
      const canvasResp = this.handleIsCanvasBlank(
        this.canvasSignature.getCanvas()
      );
      let dataURL: any = "";
      if (canvasResp) {
        this.setState({
          signatureError: "Please sign your signature before proceed",
        });
        return;
      } else {
        dataURL = this.canvasSignature.getCanvas().toDataURL();
        this.handleLoading(true);
        this.handleConfirmation(false);
        this.setState({
          signatureError: "",
        });
      }
      if (this.state.purchaseAmount > 0) {
        amount = Number(
          (
            Number(this.state.purchaseAmount) *
            Number(this.state.updateScheduleProcessAttribute.desc)
          ).toFixed(2)
        );
      } else {
        amount = await calculatePayment(
          this.props.selectedUser.id,
          Number(this.state.updateScheduleProcessAttribute.desc),
          this.props.selectedUserPromotion,
          this.props.selectedUser.tngSegment
        );
      }
      await updateScheduleSignatureToken(this.props.selectedId, dataURL);
      await createTransaction(
        this.props.selectedId,
        this.props.selectedUser.id,
        amount
      );
      if (
        this.state.paymentMethod === "Cash" ||
        this.state.paymentMethod === "Bank"
      ) {
        await createCreditTransaction(
          false,
          this.props.selectedUser.id,
          amount,
          moment().format("DD/MM/YYYY"),
          "id",
          this.state.paymentMethod
        );
      } else if (this.state.paymentMethod === "TNG") {
        await createTNGPendingTransaction(
          this.props.selectedUser.id,
          amount,
          Number(this.state.updateScheduleProcessAttribute.desc),
          moment().format("DD/MM/YYYY")
        );
      } else {
        const newBalance = Number((this.props.balance + amount).toFixed(2));
        await updateUserBalance(this.props.selectedUser.id, newBalance);
      }
      await activateReferral(this.props.selectedUser);
      let imageToken: any = "";
      if (this.state.fileName instanceof File) {
        imageToken = await handleUploadPaidByCashImage(
          this.props.selectedId,
          this.state.fileName
        );
      }

      if (imageToken) {
        // INFO : ID IS REQUIRED TO SHOW ON BLIXIFY SPACE NEXT
        process.push({
          id: this.props.selectedId,
          type: this.state.updateScheduleProcessAttribute.processType,
          description: this.state.updateScheduleProcessAttribute.desc,
          amount: amount,
          image: imageToken,
          date: moment().toDate(),
        });
      } else {
        process.push({
          id: this.props.selectedId,
          type: this.state.updateScheduleProcessAttribute.processType,
          description: this.state.updateScheduleProcessAttribute.desc,
          amount: amount,
          date: moment().toDate(),
        });
      }
    } else {
      process.push({
        type: this.state.updateScheduleProcessAttribute.processType,
        description: this.state.updateScheduleProcessAttribute.desc,
        date: moment().toDate(),
      });
    }
    let status: keyof typeof Status = "PEND";
    switch (this.state.updateScheduleProcessAttribute.processType) {
      case "COMPLETED":
        status = "COM";
        break;
      case "DECLINED":
        status = "DEC";
        break;
      case "DISPATCHED":
        status = "DISP";
        break;
      case "SCHEDULED":
        status = "PLAN";
        break;
    }

    let updateError = await updateScheduleProcess(
      this.props.selectedUser,
      this.props.selectedId,
      process,
      status,
      this.state.selectedEvent ?? "",
      this.state.paymentMethod
    );

    if (!updateError) {
      this.props.updateRefresh(true);
      this.handleCloseModal(true);
      this.props.handleGetSelectedSchedule(true);
    } else {
      const updateScheduleProcessError = JSON.parse(
        JSON.stringify(this.state.updateScheduleProcessAttributeError)
      );
      updateScheduleProcessError["processTypeError"] = updateError;
      this.setState({
        userStateError: updateScheduleProcessError,
      });
    }
    this.handleLoading(false);
  };

  renderTitle = () => {
    if (this.state.confirmation) {
      return "Confirmation Modal";
    } else {
      return "Update Schedule";
    }
  };

  renderDescription = () => {
    if (this.state.updateScheduleProcessAttribute.processType === "DECLINED") {
      return "Reason";
    } else if (
      this.state.updateScheduleProcessAttribute.processType === "SCHEDULED"
    ) {
      return "Scheduled Date";
    } else if (
      this.state.updateScheduleProcessAttribute.processType === "DISPATCHED"
    ) {
      return "Vehicle Details";
    } else if (
      this.state.updateScheduleProcessAttribute.processType === "COMPLETED"
    ) {
      return "UCO collected in kg";
    } else {
      return "Description";
    }
  };

  renderDescriptionPlaceholder = () => {
    if (this.state.updateScheduleProcessAttribute.processType === "DECLINED") {
      return "Rejection Reason";
    } else if (
      this.state.updateScheduleProcessAttribute.processType === "SCHEDULED"
    ) {
      return "DD-MM-YYYY";
    } else if (
      this.state.updateScheduleProcessAttribute.processType === "DISPATCHED"
    ) {
      return "White Lorry - WAB 1234";
    } else if (
      this.state.updateScheduleProcessAttribute.processType === "COMPLETED"
    ) {
      return "Number";
    } else {
      return "Description";
    }
  };

  renderPurchaseAmountInput = () => {
    if (
      this.state.updateScheduleProcessAttribute.processType === "COMPLETED" &&
      !this.props.agentView
    ) {
      return (
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Price Per Kg (Optional)
          </label>
          <Input
            id="purchaseAmount"
            type="number"
            className="mt-1"
            placeholder="Enter new purchase amount if any changes"
            value={this.state.purchaseAmount.toString()}
            onChange={this.handlePurchaseAmountChange}
          />
        </div>
      );
    }
  };

  renderPaymentMethod = () => {
    if (
      this.state.updateScheduleProcessAttribute.processType === "COMPLETED" &&
      !this.props.agentView
    ) {
      let paymentOptions = [
        {
          key: "Cash",
          title: "Cash",
        },
        {
          key: "Points",
          title: "Points",
        },
        {
          key: "Bank",
          title: "Bank",
        },
      ];
      if (this.props.selectedUser.tngSegment)
        paymentOptions = [
          {
            key: "TNG",
            title: "TNG",
          },
        ];

      return (
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Payment Method
          </label>
          <Select
            options={paymentOptions}
            id="paymentMethod"
            value={this.state.paymentMethod}
            onChange={(id: string, key: string) => {
              this.setState({
                paymentMethod: key,
              });
            }}
          />
        </div>
      );
    }
  };

  renderUploadInput = () => {
    if (this.state.paymentMethod === "Cash") {
      return (
        <>
          <UploadInput
            id="fileName"
            label="Image"
            lib={{
              axios,
            }}
            onChange={(e: any) => {
              this.setState({
                fileName: e.target.files[0],
              });
            }}
            accept="image/*"
            file={this.state.fileName}
          />
          <span className="text-red-500 text-sm">
            {this.state.updateScheduleProcessAttributeError.imageError}
          </span>
        </>
      );
    }
  };

  renderISCCDeclaration = () => {
    return (
      <p className="block text-sm text-gray-700 mt-5">
        <p className="text-base font-semibold">ISCC Declaration</p>
        <p className="text-gray-500">
          By signing this self-declaration, the signatory confirms the
          following:
        </p>
        <br /> 1. UCO refers to oil and fat of vegetable or animal origin which
        has been used to cook food for human consumption. Deliveries of UCO
        covered under this self-declaration consist entirely of UCO and are not
        mixed with any other oil or fat that doesn’t comply with the definition
        of UCO. <br />
        <br /> 2. UCO covered under this self-declaration meets the definition
        of a waste. This means the UCO is a material that the Point of Origin
        discards or intends or is required to discard and that the UCO was not
        intentionally modified or contaminated to meet this definition. <br />
        <br /> 3. Documentation of UCO quantities delivered is available.
        <br />
        <br /> 4. Applicable national legislation regarding waste prevention and
        management (e.g. for transport, supervision, etc.) are complied with.
        <br />
        <br /> 5. Auditors from certification bodies or from ISCC (may be
        accompanied by a representative of the Collecting Point) can examine
        on-site or by contacting the signatory (e.g. via telephone) whether the
        statements made in this self-declaration are correct. <br />
        <br /> 6. The information on this self-declaration can be forwarded to
        and reviewed by the certification body of the Collecting Point and by
        ISCC. <br />
        <br />
        Note: The certification body and ISCC keep all data provided on this
        self-declaration confidential.
      </p>
    );
  };

  renderConfirmationDescription = () => {
    let additionalContent = "";
    switch (this.state.updateScheduleProcessAttribute.processType) {
      case "COMPLETED":
        additionalContent = `credit RM${this.state.creditAmount} to ${
          this.props.selectedUser.email
        }'s account by confirming that you have collected ${
          this.state.updateScheduleProcessAttribute.desc
        }kg of used cooking oil${
          this.state.paymentMethod === "Cash"
            ? ". This transaction is being paid by cash where the users' credit will be automatically deducted"
            : ""
        }`;
        break;
      case "DECLINED":
        additionalContent = `decline this appoinment with the reason of "${this.state.updateScheduleProcessAttribute.desc}"`;
        break;
      case "DISPATCHED":
        additionalContent = `collect used cooking oil for this appointment today with the vehicle model of "${this.state.updateScheduleProcessAttribute.desc}"`;
        break;
      case "SCHEDULED":
        additionalContent = `schedule this appoinment for collection with the date of ${this.state.updateScheduleProcessAttribute.desc}`;
        break;
    }

    return `Are you sure that you want to ${additionalContent}`;
  };

  renderSelectedEventsInput = () => {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    if (this.state.updateScheduleProcessAttribute.processType === "COMPLETED") {
      if (this.props.selectedUser.tngSegment) return null;
      return (
        <DataReference
          value={this.state.selectedEvent}
          schema={{
            id: "name",
            type: "reference",
            name: "Collection Drive (Optional)",
            referenceLabel: "name",
            referenceSpaceId: "events",
          }}
          devSettings={{
            apiEndpoint: isProd
              ? "https://api.arusoil.com/api/data"
              : "https://api-dev.arusoil.com/api/data",
            apiEndpointType: "firebase",
            assetEndpoint: isProd
              ? "https://firebasestorage.googleapis.com/v0/b/arusoil-web.appspot.com/o/"
              : "https://firebasestorage.googleapis.com/v0/b/arusoil-web-dev.appspot.com/o/",
            apiUserToken: this.props.currentUserId,
          }}
          lib={{
            axios: axios,
          }}
          onChange={(value: any) => {
            this.setState({
              selectedEvent: value,
            });
          }}
        />
      );
    }
  };

  renderContent = () => {
    if (this.state.loading) {
      return (
        <div className="mt-6">
          <div className="mx-auto flex items-center justify-center h-10 w-10 rounded-full border-4 border-t-4 border-arusgreen-100 loading-spinner" />
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-title"
            >
              Loading
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Please wait while we updating the schedule.
              </p>
            </div>
          </div>
        </div>
      );
    } else if (this.state.confirmation) {
      return (
        <form className="grid grid-cols-1 gap-y-6">
          <div className="col-span-1 mt-2">
            <p className="block text-sm text-gray-700">
              {this.renderConfirmationDescription()}
            </p>
            <p className="block text-sm text-gray-500 mt-6">
              *Please take note of the information above. An email will be sent
              to the appointer once the update button has been pressed.
            </p>
            {this.state.updateScheduleProcessAttribute.processType ===
              "COMPLETED" && (
              <>
                <label className="block text-sm font-medium text-gray-700 my-3">
                  Signature for ISCC Declaration
                </label>
                <div className="border border-gray-400 w-72">
                  <SignatureCanvas
                    ref={(ref) => {
                      this.canvasSignature = ref;
                    }}
                    penColor="black"
                    canvasProps={{ width: 280, height: 200 }}
                  />
                </div>
                <p className="block text-sm text-red-500 mb-2 mt-1">
                  {this.state.signatureError}
                </p>
                {this.renderISCCDeclaration()}
              </>
            )}
          </div>
          <div className="mt-5 flex flex-col sm:flex-row">
            <div className="flex-grow" />
            <Button
              className="w-full sm:w-auto"
              text="Cancel"
              type="light"
              onClick={this.handleConfirmation.bind(this, false)}
            />
            <Button
              className="w-full mt-3 sm:mt-0 sm:w-auto sm:ml-3"
              text="Update"
              type="normal"
              onClick={this.handleUpdateSchedule}
            />
          </div>
        </form>
      );
    } else if (this.props.updateScheduleModalType === "distance") {
      return (
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Trip Distance (km)
          </label>
          <Input
            id="travelDistance"
            type="number"
            className="mt-1"
            placeholder={"Distance from starting point to destination"}
            value={this.state.travelDistance.toString()}
            error={this.state.travelDistanceError}
            onChange={this.handleTravelDistanceChange}
          />
          {this.props.startPoint && this.props.endPoint && (
            <div
              className="text-sm font-medium text-arusgreen-500 cursor-pointer mt-2"
              onClick={this.handleCalculateDistance.bind(
                this,
                this.props.startPoint,
                this.props.endPoint
              )}
            >
              Use suggested distance calculated from S.P to E.P
            </div>
          )}
          <div className="mt-5 flex flex-col sm:flex-row">
            <div className="flex-grow" />
            <Button
              className="w-full sm:w-auto"
              text="Cancel"
              type="light"
              onClick={this.handleCloseModal}
            />
            <Button
              className="w-full mt-3 sm:mt-0 sm:w-auto sm:ml-3"
              text="Update"
              type="normal"
              onClick={this.handleUpdateScheduleDistance}
            />
          </div>
        </div>
      );
    } else if (
      this.props.updateScheduleModalType === "startPoint" ||
      this.props.updateScheduleModalType === "endPoint"
    ) {
      return (
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            {this.props.updateScheduleModalType === "startPoint"
              ? "Start Point"
              : "End Point"}
          </label>
          <AddressInput
            id="address"
            className="mt-1"
            disableTitle={true}
            value={this.state.address}
            error={this.state.addressError}
            onChange={this.handleAddressChange}
          />
          <span
            className="text-sm font-medium text-arusgreen-500 cursor-pointer mt-4"
            onClick={this.handleUseCurrentLocation}
          >
            Use Current Location?
          </span>
          <div className="mt-5 flex flex-col sm:flex-row">
            <div className="flex-grow" />
            <Button
              className="w-full sm:w-auto"
              text="Cancel"
              type="light"
              onClick={this.handleCloseModal}
            />
            <Button
              className="w-full mt-3 sm:mt-0 sm:w-auto sm:ml-3"
              text="Update"
              type="normal"
              onClick={this.handleUpdateScheduleLocation}
            />
          </div>
        </div>
      );
    } else if (this.props.updateScheduleModalType === "updatedDate") {
      return (
        <div className="grid grid-cols-1 gap-y-6">
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              New Sorting Date
            </label>
            <Input
              id="updatedDate"
              className="mt-1"
              placeholder="DD-MM-YYYY"
              value={this.state.updatedDate}
              error={this.state.dateError}
              onChange={this.handleOnChangeDateInput}
            />
          </div>
          <div className="mt-5 flex justify-end">
            <Button
              className="w-full sm:w-auto mr-4"
              text="Cancel"
              type="light"
              onClick={this.handleCloseModal}
            />
            <Button
              className="w-full sm:w-auto"
              text="Update"
              type="normal"
              onClick={this.handleUpdateDate}
            />
          </div>
        </div>
      );
    } else {
      return (
        <form className="grid grid-cols-1 gap-y-6">
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Schedule Process
            </label>
            <Select
              id="processType"
              value={this.state.updateScheduleProcessAttribute.processType}
              options={processTypeOption}
              onChange={this.handleSelectChange}
              error={
                this.state.updateScheduleProcessAttributeError.processTypeError
              }
            />
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              {this.renderDescription()}
            </label>
            {this.state.updateScheduleProcessAttribute.processType ===
            "SCHEDULED" ? (
              <div className="pb-36">
                <InputDatePicker
                  title=""
                  onChange={this.handleDateInputChange}
                  value={moment(
                    this.state.updateScheduleProcessAttribute.desc,
                    "DD-MM-YYYY"
                  ).toDate()}
                />
                <span className="text-red-500 text-sm">
                  {this.state.updateScheduleProcessAttributeError.descError}
                </span>
              </div>
            ) : (
              <Input
                id="desc"
                className="mt-1"
                placeholder={this.renderDescriptionPlaceholder()}
                value={this.state.updateScheduleProcessAttribute.desc}
                error={this.state.updateScheduleProcessAttributeError.descError}
                onChange={this.handleInputChange}
              />
            )}
          </div>
          {this.renderPaymentMethod()}
          {this.renderPurchaseAmountInput()}
          {this.renderUploadInput()}
          {this.renderSelectedEventsInput()}
          <div className="mt-5 flex justify-end">
            <Button
              className="w-full sm:w-auto mr-4"
              text="Cancel"
              type="light"
              onClick={this.handleCloseModal}
            />
            <Button
              className="w-full sm:w-auto"
              text="Update"
              type="normal"
              onClick={this.handleCheckConfirmation}
            />
          </div>
        </form>
      );
    }
  };

  render() {
    return (
      <Transition.Root show={this.props.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.props.open}
          onClose={() => {}}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full align-bottom bg-white rounded-lg px-4 pt-5 pb-10 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:p-6">
                <div>
                  <div className=" border-b border-gray-200 pb-4">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {this.renderTitle()}
                    </Dialog.Title>
                  </div>
                  <div className="mt-2">{this.renderContent()}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
