import { ClockIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Flag, OperationalType, Restaurant } from "../../models/Restaurant";
import { Address } from "../../models/User";
import AddressInput from "../base/AddressInput";
import Button from "../base/Button";
import Input from "../base/Input";
import Select, { Option } from "../base/Select";
import Table, { TableHeader } from "../base/Table";
import {
  SearchState,
  SearchStateError,
  Type,
} from "../controller/OperationController";
import {
  clearRestaurant,
  clearSearchRestaurants,
  getFilteredRestaurants,
  handleSearchStateCondition,
  operationDataLimit,
  updatePagination,
} from "../store/actions/operationActions";
import { operationStateInterface } from "../store/reducers/operationReducer";

enum SearchType {
  RESTAURANT = "restaurant",
  NIGHTCLUB = "night_club",
  MEALTAKEAWAY = "meal_takeaway",
  MEALDELIVERY = "meal_delivery",
  CAFE = "cafe",
}

const headers: TableHeader[] = [
  {
    key: "id",
    title: "Id",
  },
  {
    key: "name",
    title: "Name",
  },
  {
    key: "flag",
    title: "Flag",
  },
  {
    key: "contact",
    title: "Updated",
  },
  {
    key: "business_status",
    title: "Operation",
  },
  {
    key: "vicinity",
    title: "Address",
  },
];

const pastRestaurantHeaders: TableHeader[] = [
  {
    key: "id",
    title: "Id",
  },
  {
    key: "name",
    title: "Name",
  },
  {
    key: "flag",
    title: "Flag",
  },
  {
    key: "contact",
    title: "Contact Number",
  },
  {
    key: "business_status",
    title: "Operation",
  },
  {
    key: "vicinity",
    title: "Address",
  },
];

const searchMapStyle = [
  {
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

interface Props {
  history: any;
  flag: keyof typeof Flag | string;
  type: Type;
  operationStore: operationStateInterface;
  pageIndex: number;
  pastResultPageIndex: number;
  searchResults: any[];
  searchState: SearchState;
  updatePagination: (pagination: any | null) => void;
  handleUpdatePageIndex: (pageIndex: number, type: string) => void;
  handleUpdateSearchResult: (results: any[]) => void;
  handleUpdateType: (type: Type) => void;
  handleUpdateSearchState: (searchState: SearchState) => void;
  handleSelectFlag: (id: string, key: string) => void;
  renderFlagOption: () => Option[];
  getRestaurantWithPagination: (
    paginationStartAt: string | Date,
    paginationFlag: keyof typeof Flag | string
  ) => void;
  getFilteredRestaurants: (placeIds: string[]) => void;
  clearSearchRestaurants: () => void;
  clearRestaurant: () => void;
}

interface State {
  loading: boolean;
  searchStateError: SearchStateError;
  markers: any;
  locationMarker: any;
  circleMarker: any;
}

const searchTypeOption: Option[] = Object.keys(SearchType).map(
  (eachSearchTypeKey) => {
    return {
      key: SearchType[eachSearchTypeKey as keyof typeof SearchType],
      title: eachSearchTypeKey,
    };
  }
);
const foodMarkerColor = "#166534";

const svgMarker = {
  path: "M16 6v8h3v8h2V2c-2.76 0-5 2.24-5 4zm-5 3H9V2H7v7H5V2H3v7c0 2.21 1.79 4 4 4v9h2v-9c2.21 0 4-1.79 4-4V2h-2v7z",
  fillColor: foodMarkerColor,
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 1,
};

class Operation extends Component<Props> {
  private map = createRef<HTMLDivElement>();
  private googleMap: any = "";

  state: State = {
    loading: false,
    searchStateError: {
      addressError: "",
      radiusError: "",
      typeError: "",
    },
    markers: [],
    locationMarker: null,
    circleMarker: null,
  };

  componentDidMount() {
    this.googleMap = new window.google.maps.Map(this.map.current, {
      center: new window.google.maps.LatLng(3.139, 101.6869),
      zoom: 15,
      disableDefaultUI: true,
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(prevProps.operationStore.restaurants) !==
      JSON.stringify(this.props.operationStore.restaurants)
    ) {
      this.handlePastResultBounds();
    }
  }

  handleTabClick = (type: Type) => {
    this.props.handleUpdateType(type);
    if (type === Type.PASTRESULT) {
      this.props.clearRestaurant();
      this.props.getRestaurantWithPagination("", "");
    }
  };

  handleAddressChange = (address: Address) => {
    const clonedSearchState = JSON.parse(
      JSON.stringify(this.props.searchState)
    );
    clonedSearchState["address"] = address;
    this.props.handleUpdateSearchState(clonedSearchState);
  };

  handleInputChange = (e: any) => {
    const clonedSearchState = JSON.parse(
      JSON.stringify(this.props.searchState)
    );
    clonedSearchState[e.target.id] = e.target.value;
    this.props.handleUpdateSearchState(clonedSearchState);
  };

  handleSelectChange = (id: string, key: string) => {
    const clonedSearchState = JSON.parse(
      JSON.stringify(this.props.searchState)
    );
    clonedSearchState[id] = key;
    this.props.handleUpdateSearchState(clonedSearchState);
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      if (
        this.props.operationStore.pagination &&
        this.props.pageIndex * operationDataLimit + 1 >
          this.props.searchResults.length
      ) {
        this.setState({ loading: true });
        this.props.operationStore.pagination.nextPage();
      }
      this.props.handleUpdatePageIndex(this.props.pageIndex + 1, "restaurant");
    } else {
      this.props.handleUpdatePageIndex(this.props.pageIndex - 1, "restaurant");
    }
  };

  handlePastResultPagination = (value: string) => {
    if (value === "next") {
      //INFO : Check whether need to get new data from firebase
      if (
        this.props.operationStore.lastCursor &&
        this.props.pastResultPageIndex * operationDataLimit + 1 >
          this.props.operationStore.restaurants.length
      ) {
        this.props.getRestaurantWithPagination(
          this.props.operationStore.lastCursor,
          this.props.flag
        );
      }
      this.props.handleUpdatePageIndex(
        this.props.pastResultPageIndex + 1,
        "pastresult"
      );
    } else {
      this.props.handleUpdatePageIndex(
        this.props.pastResultPageIndex - 1,
        "pastresult"
      );
    }
  };

  handlePastResultBounds = () => {
    this.handleClearMapAssets();
    this.props.operationStore.restaurants.map((eachResturant) => {
      const location = new window.google.maps.LatLng(
        Number(eachResturant.address.lat),
        Number(eachResturant.address.lng)
      );
      const restaurantMarker = new window.google.maps.Marker({
        position: location,
        icon: svgMarker,
        map: this.googleMap,
        label: {
          text: eachResturant.name,
          fontSize: "14px",
          className: "marker-position",
        },
      });
      this.state.markers.push(restaurantMarker);
      return "";
    });
    const bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < this.state.markers.length; i++) {
      bounds.extend(this.state.markers[i].position);
    }
    this.googleMap.fitBounds(bounds);
  };

  handleOnClickRow = (id: string) => {
    this.props.history.push(`/dashboard/operationEditor?data=${id}`);
  };

  handleClearMapAssets = () => {
    if (
      this.state.circleMarker ||
      this.state.locationMarker ||
      this.state.markers.length > 0
    ) {
      if (this.state.circleMarker) {
        this.state.circleMarker.setMap(null);
      }
      if (this.state.locationMarker) {
        this.state.locationMarker.setMap(null);
      }
      if (this.state.markers.length > 0) {
        this.state.markers.map((eachMarker: any) => {
          eachMarker.setMap(null);
          return null;
        });
      }
      this.setState({
        circleMarker: null,
        locationMarker: null,
        markers: [],
      });
    }
    this.googleMap.setOptions({ styles: searchMapStyle });
  };

  handleSearch = async () => {
    this.handleClearMapAssets();
    this.props.handleUpdatePageIndex(1, "restaurant");
    this.props.handleUpdateSearchResult([]);
    this.props.clearSearchRestaurants();
    this.setState({
      loading: true,
    });

    let conditionsList: string[] = ["address", "radius", "searchType"];

    const clonedSearchError = JSON.parse(
      JSON.stringify(this.state.searchStateError)
    );

    handleSearchStateCondition(
      this.props.searchState,
      clonedSearchError,
      conditionsList
    );

    this.setState(
      {
        searchStateError: clonedSearchError,
      },
      async () => {
        if (
          !this.state.searchStateError.addressError &&
          !this.state.searchStateError.radiusError &&
          !this.state.searchStateError.typeError
        ) {
          try {
            const currentLocation = new window.google.maps.LatLng(
              this.props.searchState.address.lat,
              this.props.searchState.address.lng
            );

            const request = {
              location: currentLocation,
              radius: Number(this.props.searchState.radius) * 1000,
              type: this.props.searchState.searchType,
            };

            const circleColor = "#86efac";
            const circlePolygon = new window.google.maps.Circle({
              strokeColor: circleColor,
              fillColor: circleColor,
              fillOpacity: 0.15,
              map: this.googleMap,
              center: currentLocation,
              radius: Number(this.props.searchState.radius * 1000),
            });
            this.setState({ circleMarker: circlePolygon });

            const service = new window.google.maps.places.PlacesService(
              this.googleMap
            );
            await service.nearbySearch(
              request,
              (results: any[], status: any, pagination: any | null) =>
                this.searchCallback(results, status, pagination)
            );
          } catch (err) {}
        }
      }
    );
  };

  searchCallback = (results: any[], status: any, pagination: any | null) => {
    let searchResultIds: string[] = [];
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      let searchResults: any[] = [];
      if (this.props.searchResults.length > 0) {
        searchResults = JSON.parse(JSON.stringify(this.props.searchResults));
        results.map((eachResult) => {
          searchResults.push(eachResult);
          return null;
        });
      } else {
        searchResults = results;
      }
      if (pagination.hasNextPage) {
        this.props.updatePagination(pagination);
      } else {
        this.props.updatePagination("");
      }

      searchResults.map((eachResult) => {
        searchResultIds.push(eachResult.place_id);
        return "";
      });
      this.props.getFilteredRestaurants(searchResultIds);
      this.props.handleUpdateSearchResult(searchResults);
      this.setState({ loading: false });
      this.renderMarker();
    }
  };

  renderMarker = () => {
    const map = this.googleMap;
    const defaultMarkerColor = "#6DBA62";

    const defaultMarker = {
      path: "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z",
      fillColor: defaultMarkerColor,
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
    };

    const locationMarker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(
        this.props.searchState.address.lat,
        this.props.searchState.address.lng
      ),
      map: map,
      icon: defaultMarker,
      label: "Selected Location",
    });
    this.setState({ locationMarker: locationMarker });

    this.props.searchResults.map((eachResult: any) => {
      if (!this.props.searchState.address.name.includes(eachResult.name))
        try {
          const location = new window.google.maps.LatLng(
            Number(eachResult.geometry.location.lat()),
            Number(eachResult.geometry.location.lng())
          );
          const restaurantMarker = new window.google.maps.Marker({
            position: location,
            icon: svgMarker,
            map,
            label: {
              text: eachResult.name,
              fontSize: "14px",
              className: "marker-position",
            },
          });
          this.state.markers.push(restaurantMarker);
        } catch (err) {}
      return "";
    });

    const bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < this.state.markers.length; i++) {
      bounds.extend(this.state.markers[i].position);
    }
    this.googleMap.fitBounds(bounds);
  };

  renderFilteredData = () => {
    let listView: any = [];
    this.props.searchResults.map((eachResult) => {
      let selectedRestaurant: Restaurant | undefined;
      this.props.operationStore.searchRestaurants.map((eachRestaurant) => {
        if (eachRestaurant.id === eachResult.place_id) {
          selectedRestaurant = eachRestaurant;
        }
        return "";
      });
      listView.push({
        id: eachResult.place_id,
        name: eachResult.name,
        flag: selectedRestaurant?.flag ?? "-",
        contact: selectedRestaurant ? "Y" : "-",
        business_status:
          OperationalType[
            eachResult.business_status as keyof typeof OperationalType
          ] ?? "-",
        vicinity: eachResult.vicinity,
      });
      return "";
    });

    return listView;
  };

  renderPastRestaurantFilteredData = () => {
    let listView: any = [];
    this.props.operationStore.restaurants.map((eachRestaurant: Restaurant) => {
      listView.push({
        id: eachRestaurant.id,
        name: eachRestaurant.name,
        flag: eachRestaurant.flag ?? "-",
        contact: eachRestaurant.phoneNumber ?? "-",
        business_status:
          OperationalType[
            eachRestaurant.businessStatus as keyof typeof OperationalType
          ] ?? "-",
        vicinity: eachRestaurant.address.name,
      });
      return "";
    });

    return listView;
  };

  renderActionBar = () => {
    const defaultTabClass =
      "flex flex-row items-center p-2 text-gray-400 hover:text-gray-500 cursor-pointer";
    const selectedTabClass =
      "flex flex-row items-center p-2 text-arusgreen-500 cursor-pointer border-arusgreen-500 border-b-2";

    return (
      <div className="px-4 pt-1 flex flex-row bg-white border-b-2 border-white sm:px-6 lg:px-8 lg:pt-0">
        <div
          className={
            this.props.type === Type.SEARCH ? selectedTabClass : defaultTabClass
          }
          onClick={this.handleTabClick.bind(this, Type.SEARCH)}
        >
          <MagnifyingGlassIcon className="w-5 h-5 mr-2" /> Search
        </div>
        <div
          className={
            this.props.type === Type.PASTRESULT
              ? selectedTabClass
              : defaultTabClass
          }
          onClick={this.handleTabClick.bind(this, Type.PASTRESULT)}
        >
          <ClockIcon className="w-5 h-5 ml-2 mr-2" /> Past Results
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderActionBar()}
        <div className="grid grid-cols-1 lg:grid-cols-2">
          {this.props.type === Type.SEARCH ? (
            <div>
              <div className="px-4 sm:px-6 lg:px-8">
                <h3 className="text-lg leading-6 font-medium text-gray-900 my-6">
                  Restaurant List Searched
                </h3>
                <AddressInput
                  className="flex-grow"
                  id="address"
                  disableTitle={true}
                  value={this.props.searchState.address}
                  error={this.state.searchStateError.addressError}
                  onChange={this.handleAddressChange}
                />
                <div className="flex flex-row mt-1">
                  <div className="mt-1">
                    <Input
                      id="radius"
                      placeholder="Radius(km)"
                      type="number"
                      value={
                        this.props.searchState.radius.toString() === "0"
                          ? ""
                          : this.props.searchState.radius.toString()
                      }
                      error={this.state.searchStateError.radiusError}
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="ml-2 w-80">
                    <Select
                      id="searchType"
                      value={this.props.searchState.searchType}
                      placeholder="Type"
                      options={searchTypeOption}
                      onChange={this.handleSelectChange}
                    />
                  </div>
                </div>
                <div className="flex mt-3 justify-end">
                  <Button
                    text="Search"
                    type="normal"
                    onClick={this.handleSearch}
                  />
                </div>
              </div>
              <div className="mb-8 mt-8 lg:mb-0">
                <Table
                  loading={this.state.loading}
                  header={headers}
                  pageIndex={this.props.pageIndex}
                  lastCursor={this.props.operationStore.pagination}
                  handlePage={this.handlePagination}
                  data={this.renderFilteredData()}
                  onClickRow={this.handleOnClickRow}
                  dataLimit={operationDataLimit}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex flex-row justify-center items-center">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 my-6">
                    Past Restaurants
                  </h3>
                  <div className="flex-grow" />
                  <p className="text-md leading-6 font-medium text-gray-900 mr-6">
                    Filter by:
                  </p>
                  <div className="w-44">
                    <Select
                      id="flag"
                      value={this.props.flag}
                      placeholder="Flag"
                      options={this.props.renderFlagOption()}
                      onChange={this.props.handleSelectFlag}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-8 mt-8 lg:mb-0">
                <Table
                  loading={this.props.operationStore.loading}
                  header={pastRestaurantHeaders}
                  pageIndex={this.props.pastResultPageIndex}
                  lastCursor={this.props.operationStore.lastCursor}
                  onClickRow={this.handleOnClickRow}
                  handlePage={this.handlePastResultPagination}
                  data={this.renderPastRestaurantFilteredData()}
                  dataLimit={operationDataLimit}
                />
              </div>
            </div>
          )}
          <div className="h-96 lg:min-h-screen lg:h-auto" ref={this.map}></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    operationStore: state.operationStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updatePagination: (pagination: any | null) =>
      dispatch(updatePagination(pagination)),
    getFilteredRestaurants: (placeIds: string[]) =>
      dispatch(getFilteredRestaurants(placeIds)),
    clearSearchRestaurants: () => dispatch(clearSearchRestaurants()),
    clearRestaurant: () => dispatch(clearRestaurant()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Operation);
