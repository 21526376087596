import { TransactionModel } from "../../../models/Transaction";
import { UserModel } from "../../../models/User";

export interface transactionStateInterface {
  transactions: TransactionModel[];
  lastCursor: string | Date;
  trigger: boolean;
  selectedTransaction: {
    user: UserModel;
    transaction: TransactionModel;
  };
}

const initState: transactionStateInterface = {
  transactions: [],
  lastCursor: "",
  trigger: false,
  selectedTransaction: {
    user: {
      id: "",
      email: "",
      name: "",
      gender: "M",
      age: "M",
      profession: "ACC",
      mobileNo: "",
      familyMembers: "S",
      cookFrequency: "AVERAGE",
      createdAt: new Date(),
    },
    transaction: {
      id: "",
      type: "DEBIT",
      date: new Date(),
      userId: "",
      scheduleId: "",
      receipt: "",
      amount: 0,
    },
  },
};

const transactionReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_TRANSACTIONS":
      return {
        ...state,
        transactions: action.payload.transactions,
        lastCursor: action.payload.lastCursor,
      };
    case "UPDATE_TRANSACTIONS_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "UPDATE_TRANSACTION_TRIGGER":
      return {
        ...state,
        trigger: action.payload.trigger,
      };
    case "UPDATE_SELECTED_TRANSACTION":
      return {
        ...state,
        selectedTransaction: action.payload.selectedTransaction,
      };
    default:
      return state;
  }
};

export default transactionReducer;
