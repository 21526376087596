import moment from "moment";
import { ScheduleModel } from "../../../models/Schedules";

export interface scheduleStateInterface {
  schedules: ScheduleModel[];
  selectedSchedule: ScheduleModel;
  lastCursor: string;
  loading: boolean;
}

const initState: scheduleStateInterface = {
  schedules: [],
  selectedSchedule: {
    id: "",
    userId: "",
    phone: "",
    address: {
      name: "",
      lat: 0,
      lng: 0,
    },
    image: "",
    preferredDay: "START",
    preferredTime: "MORNING",
    updatedAt: moment().toDate(),
    status: "PEND",
    signature: "",
    additionRemark: "",
    estimatedWeight: 0,
    processes: [],
  },
  lastCursor: "",
  loading: false,
};

const schedulesReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_SCHEDULES_LIST":
      return {
        ...state,
        schedules: action.payload.schedules,
        lastCursor: action.payload.lastCursor,
      };
    case "UPDATE_SCHEDULES_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "UPDATE_SELECTED_SCHEDULE":
      return {
        ...state,
        selectedSchedule: action.payload.selectedSchedule,
      };
    default:
      return state;
  }
};

export default schedulesReducer;
