import moment from "moment";
import { PendingTransactionModel } from "../../../models/PendingTransaction";
import { UserModel } from "../../../models/User";

export interface pendTransactionStateInterface {
  pendTransaction: PendingTransactionModel[];
  lastCursor: string | Date;
  loading: boolean;
  selectedTransaction: {
    user: UserModel;
    pendTransaction: PendingTransactionModel;
  };
}

const initState: pendTransactionStateInterface = {
  pendTransaction: [],
  lastCursor: "",
  loading: false,
  selectedTransaction: {
    user: {
      id: "",
      email: "",
      name: "",
      gender: "M",
      age: "M",
      profession: "ACC",
      mobileNo: "",
      familyMembers: "S",
      cookFrequency: "AVERAGE",
      createdAt: new Date(),
    },
    pendTransaction: {
      amount: 0,
      date: moment().toDate(),
      id: "",
      userId: "",
    },
  },
};

const pendTransactionReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_PEND_TRANSACTION_LIST":
      return {
        ...state,
        pendTransaction: action.payload.pendTransaction,
        lastCursor: action.payload.lastCursor,
      };
    case "UPDATE_PEND_TRANSACTIONS_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "UPDATE_SELECTED_PEND_TRANSACTION":
      return {
        ...state,
        selectedTransaction: action.payload.selectedTransaction,
      };
    default:
      return state;
  }
};

export default pendTransactionReducer;
