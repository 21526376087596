import firebase from "firebase";
import moment from "moment";
import PromotionModel, { GenericStats } from "../../../models/Promotion";
import { PromotionApplied, UserModel } from "../../../models/User";
import {
  PromotionStateAttribute,
  PromotionStateError,
} from "../../dashboard/PromotionEditor";

export const promotionDataLimit: number = 10;

export const handlePromotionCondition = (
  promotionState: PromotionStateAttribute,
  promotionError: PromotionStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "id":
        if (promotionState.id.replace(/\s/g, "").length <= 0) {
          promotionError["idError"] = "Please enter the promotion id";
        } else {
          promotionError["idError"] = "";
        }
        break;
      case "fixedPriceRate":
        if (promotionState.fixedPriceRate <= 0) {
          promotionError["fixedPriceRateError"] =
            "Please enter the correct amount of fixed price rate";
        } else {
          promotionError["fixedPriceRateError"] = "";
        }
        break;
      case "increasePercentRate":
        if (promotionState.increasePercentRate <= 0) {
          promotionError["increasePercentRateError"] =
            "Please enter the correct amount of percent rate";
        } else {
          promotionError["increasePercentRateError"] = "";
        }
        break;
      case "redemptionLimit":
        if (promotionState.redemptionLimit <= 0) {
          promotionError["redemptionLimitError"] =
            "Please enter the correct redemption limit";
        } else {
          promotionError["redemptionLimitError"] = "";
        }
        break;
      case "durationAppliedInMonths":
        if (
          promotionState.durationAppliedInMonths <= 0 ||
          promotionState.durationAppliedInMonths > 12
        ) {
          promotionError["durationAppliedInMonthsError"] =
            "Please enter the correct months";
        } else {
          promotionError["durationAppliedInMonthsError"] = "";
        }
        break;
    }
    return null;
  });
};

export const createPromotionCode = async (
  promotion: PromotionStateAttribute
) => {
  try {
    const promotionRef = firebase
      .firestore()
      .collection("promotion")
      .doc(promotion.id);
    const genericStats: GenericStats = {
      redemptionCounter: 0,
      redemptionLimit: promotion.redemptionLimit,
    };
    const promotionModel: PromotionModel = {
      id: promotion.id,
      type: promotion.promotionType,
      genericStats: genericStats,
      durationAppliedInMonths: promotion.durationAppliedInMonths,
      createdAt: moment().toDate(),
    };

    if (promotion.promotionType === "FLAT") {
      promotionModel["fixedPriceRate"] = promotion.fixedPriceRate;
    } else if (promotion.promotionType === "GENERIC") {
      promotionModel["increasePercentRate"] = promotion.increasePercentRate;
    }

    await promotionRef.set(promotionModel);
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const getPromotionWithPagination = (
  paginationStartAfter: string,
  paginationSearch: string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let promotionSnapshot;
      const promotionsCollectionQuery = firebase
        .firestore()
        .collection("promotion")
        .orderBy("id")
        .limit(promotionDataLimit);

      let promotionList: PromotionModel[] =
        getState().promotionStore.promotions;
      const newPromotionList: PromotionModel[] = [];
      updatePromotionLoadingState(dispatch, true);

      if (!paginationStartAfter) {
        promotionList = [];
      }

      if (paginationSearch) {
        promotionSnapshot = await promotionsCollectionQuery
          .startAt(paginationSearch)
          .endAt(paginationSearch + "\uf8ff")
          .get();
      } else {
        if (paginationStartAfter) {
          promotionSnapshot = await promotionsCollectionQuery
            .startAfter(paginationStartAfter)
            .get();
        } else {
          promotionSnapshot = await promotionsCollectionQuery.get();
        }
      }

      if (promotionSnapshot) {
        promotionSnapshot.forEach((eachDoc: any) => {
          const eachSchedule = eachDoc.data() as PromotionModel;
          newPromotionList.push(eachSchedule);
        });

        let lastCursor: string = "";

        if (newPromotionList.length > 0) {
          if (newPromotionList.length === promotionDataLimit) {
            lastCursor = newPromotionList[promotionDataLimit - 1].id;
          }

          if (paginationSearch) {
            lastCursor = "";
          }

          dispatch({
            type: "UPDATE_PROMO_LIST",
            payload: {
              promotions: promotionList.concat(newPromotionList),
              lastCursor: lastCursor,
            },
          });
        } else {
          dispatch({
            type: "UPDATE_PROMO_LIST",
            payload: {
              promotions: promotionList,
              lastCursor: lastCursor,
            },
          });
        }
      }

      updatePromotionLoadingState(dispatch, false);
    } catch (err: any) {
      updatePromotionLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const clearPromotion = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_PROMO_LIST",
      payload: {
        promotions: [],
        lastCursor: "",
      },
    });
  };
};

const updatePromotionLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_PROMO_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const getPromotion = async (selectedId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      let promotionQuery = await firebase
        .firestore()
        .collection("promotion")
        .doc(selectedId)
        .get();

      if (promotionQuery.exists) {
        const currentPromotion = promotionQuery.data() as PromotionModel;
        return currentPromotion;
      } else {
        return "Promotion cannot be found";
      }
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const deletePromotion = async (selectedId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      await firebase
        .firestore()
        .collection("promotion")
        .doc(selectedId)
        .delete();
      return "";
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const getPromotionList = (selectedUser: UserModel) => {
  return async (dispatch: any, getState: any) => {
    try {
      const promotionsId: string[] = [];
      if (selectedUser.promotions) {
        selectedUser.promotions.map((eachPromotion: any) => {
          promotionsId.push(eachPromotion.id);
          return "";
        });
      }

      const promotionList: PromotionModel[] = [];
      if (promotionsId.length > 0) {
        while (promotionsId.length > 0) {
          const splicePromotionList = promotionsId.splice(0, 10);
          const promotionSnapshot = await firebase
            .firestore()
            .collection("promotion")
            .where("id", "in", splicePromotionList)
            .get();
          if (promotionSnapshot) {
            promotionSnapshot.forEach((eachDoc: any) => {
              const eachPromotion = eachDoc.data() as PromotionModel;
              promotionList.push(eachPromotion);
            });
          }
        }
        dispatch({
          type: "UPDATE_PROMO_LIST",
          payload: {
            promotions: promotionList,
          },
        });
      }
    } catch (err: any) {
      return err.message;
    }
  };
};

export const checkPromoCodeExists = async (
  promoCode: string,
  firebase: any
) => {
  try {
    const promotionQuery = await firebase
      .firestore()
      .collection("promotion")
      .doc(promoCode)
      .get();
    return promotionQuery;
  } catch (err) {
    return false;
  }
};

export const handlePromotionIdCondition = (promotionAttributeState: string) => {
  if (promotionAttributeState.replace(/\s/g, "").length <= 0) {
    return "Please enter the promotion code";
  }
};

export const updateSelectedPromotion = (selectedPromotion: PromotionModel) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_SELECTED_PROMO",
      payload: {
        selectedPromotion: selectedPromotion,
      },
    });
  };
};

export const handleAddGenericPromoCode = async (
  userId: string,
  promoCode: string,
  promotion: PromotionApplied[]
) => {
  try {
    if (promoCode) {
      const currentUser = await firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .get();

      if (currentUser.exists) {
        const userData = currentUser.data() as UserModel;
        let promoRedeemed = false;
        if (userData.promotions && userData.promotions.length > 0) {
          userData.promotions.map((eachPromo) => {
            if (eachPromo.id === promoCode) {
              promoRedeemed = true;
            }
            return null;
          });
        }

        if (promoRedeemed) {
          return "This user have redeemed this promotion code before";
        }
      } else {
        return "User does not exists";
      }

      const promotionExists = await checkPromoCodeExists(promoCode, firebase);
      if (promotionExists.exists) {
        const redemptionCounter =
          promotionExists.data().genericStats.redemptionCounter + 1;

        const redemptionLimit =
          promotionExists.data().genericStats.redemptionLimit;

        if (redemptionCounter > redemptionLimit) {
          return "The promotion code has been redeemed finish.";
        } else {
          const genericStats: GenericStats = {
            redemptionCounter: redemptionCounter,
            redemptionLimit: redemptionLimit,
          };
          promotion.push({
            id: promoCode,
            appliedDate: moment().toDate(),
          });

          await firebase
            .firestore()
            .collection("users")
            .doc(userId)
            .update({ promotions: promotion });

          await firebase
            .firestore()
            .collection("promotion")
            .doc(promoCode)
            .update({ genericStats });
          return "";
        }
      } else {
        return "Promotion code is invalid";
      }
    } else {
      return "Promotion code is invalid";
    }
  } catch (err: any) {
    return err.message;
  }
};
