import { combineReducers } from "redux";
import adminReducer from "./adminsReducer";
import authReducer from "./authReducer";
import ngoReducer from "./ngoReducer";
import operationReducer from "./operationReducer";
import pendTransactionReducer from "./pendTransactionReducer";
import promotionReducer from "./promotionReducer";
import rewardReducer from "./rewardReducer";
import schedulesReducer from "./scheduleReducer";
import transactionReducer from "./transactionReducer";
import userReducer from "./usersReducer";
import inventoryReducer from "./inventoryReducer";

const rootReducer = combineReducers({
  adminStore: adminReducer,
  authStore: authReducer,
  userStore: userReducer,
  scheduleStore: schedulesReducer,
  promotionStore: promotionReducer,
  transactionStore: transactionReducer,
  pendTransactionStore: pendTransactionReducer,
  ngoStore: ngoReducer,
  rewardStore: rewardReducer,
  operationStore: operationReducer,
  inventoryStore: inventoryReducer,
});

export default rootReducer;
