export interface inventoryStateInterface {
  inventories: any[];
  loading: boolean;
}

const initState: inventoryStateInterface = {
  inventories: [],
  loading: false,
};

const inventoryReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_INVENTORIES_LIST":
      return {
        ...state,
        inventories: action.payload.inventories,
      };
    case "UPDATE_INVENTORY_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default inventoryReducer;
