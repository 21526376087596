import React, { Component } from "react";
import { connect } from "react-redux";
import Promotion from "../dashboard/Promotion";
import PromotionEditor from "../dashboard/PromotionEditor";
import {
  clearPromotion,
  getPromotionWithPagination,
} from "../store/actions/promotionActions";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  authStore: authStateInterface;
  history: string;
  pathName: string;
  confirmationModalVisible: boolean;
  getPromotionWithPagination: (
    paginationStartAt: string,
    paginationSearch: string
  ) => void;
  clearPromotion: () => void;
  handleOpenConfirmationModal: (visible: boolean) => void;
}

interface State {
  pageIndex: number;
  searchText: string;
}

class PromotionController extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;
  state: State = {
    pageIndex: 1,
    searchText: "",
  };

  componentDidMount() {
    this.props.getPromotionWithPagination("", "");
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.pathName !== this.props.pathName) {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedId = searchParams.get("data");
      if (selectedId === "r") {
        this.setState({ searchText: "" });
        this.handleUpdatePageIndex(1);
        this.props.clearPromotion();
        this.props.getPromotionWithPagination("", "");
      }
    }
  }

  componentWillUnmount() {
    this.props.clearPromotion();
  }

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex });
  };

  handleFilter = (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.props.clearPromotion();
        this.handleUpdatePageIndex(1);
        this.props.getPromotionWithPagination("", this.state.searchText);
      }, 500);
    });
  };

  render() {
    if (this.props.authStore.user?.role !== "SUPER") {
      return null;
    }

    if (this.props.pathName === "/dashboard/promotion") {
      return (
        <Promotion
          history={this.props.history}
          pageIndex={this.state.pageIndex}
          searchText={this.state.searchText}
          handleUpdatePageIndex={this.handleUpdatePageIndex}
          getPromotionWithPagination={this.props.getPromotionWithPagination}
          handleFilter={this.handleFilter}
        />
      );
    } else if (this.props.pathName === "/dashboard/promotionEditor") {
      return (
        <PromotionEditor
          history={this.props.history}
          confirmationModalVisible={this.props.confirmationModalVisible}
          handleOpenConfirmationModal={this.props.handleOpenConfirmationModal}
        />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getPromotionWithPagination: (
      paginationStartAt: string,
      paginationSearch: string
    ) =>
      dispatch(getPromotionWithPagination(paginationStartAt, paginationSearch)),
    clearPromotion: () => dispatch(clearPromotion()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionController);
