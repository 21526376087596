import { HomeIcon } from "@heroicons/react/24/outline";
import {
  Option as OptionV2,
  ReadQuery,
  Select as SelectV2,
} from "blixify-ui-web/lib";
import firebase from "firebase";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import Admin, { AdminRole, States } from "../../models/Admin";
import PromotionModel from "../../models/Promotion";
import Button from "../base/Button";
import Input from "../base/Input";
import Overlay from "../base/Overlay";
import Select, { Option } from "../base/Select";
import {
  createAdmin,
  deleteAdmin,
  getAdmin,
  handleAdminCondition,
  updateSelectedAdmin,
} from "../store/actions/adminsActions";
import { adminStateInterface } from "../store/reducers/adminsReducer";
import ConfirmationModal from "./ConfirmationModal";

interface Props {
  history: any;
  adminStore: adminStateInterface;
  confirmationModalVisible: boolean;
  handleOpenConfirmationModal: (visible: boolean) => void;
  updateSelectedAdmin: (selectedAdmin: Admin | null) => void;
}

export interface AdminState {
  id: string;
  email: string;
  password: string;
  name: string;
  promo: string;
  role: string;
  state: (keyof typeof States)[];
}

export interface AdminStateError {
  emailError: string;
  passwordError: string;
  nameError: string;
  promoError: string;
  roleError: string;
  stateError: string;
}

interface State {
  loading: boolean;
  creation: boolean;
  adminState: AdminState;
  adminStateError: AdminStateError;
  promoSearch: string;
  promoCodes: OptionV2[];
}

const roleTypeOption: Option[] = Object.keys(AdminRole).map((eachRoles) => {
  return {
    key: eachRoles,
    title: AdminRole[eachRoles as keyof typeof AdminRole],
  };
});

class AdminEditor extends Component<Props> {
  promoSelectV2: any = createRef();
  state: State = {
    loading: false,
    creation: false,
    adminState: {
      id: "",
      email: "",
      password: "",
      name: "",
      role: "",
      promo: "",
      state: [],
    },
    adminStateError: {
      emailError: "",
      passwordError: "",
      nameError: "",
      roleError: "",
      promoError: "",
      stateError: "",
    },
    promoSearch: "",
    promoCodes: [],
  };

  componentDidMount = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId && selectedId !== "new") {
      const adminData = await getAdmin(selectedId);
      if (typeof adminData === "string") {
        this.handleBack();
      } else {
        this.setState(
          {
            adminState: adminData,
          },
          () => {
            this.props.updateSelectedAdmin(adminData);
          }
        );
      }
    } else if (selectedId && selectedId === "new") {
      this.setState({
        creation: true,
      });
    } else {
      this.handleBack();
    }
  };

  componentWillUnmount() {
    this.props.updateSelectedAdmin(null);
  }

  handleBack = () => {
    this.props.history.push("/dashboard/admins");
  };

  handleLoading = (loading: boolean) => {
    this.setState({ loading });
  };

  handleInputChange = (e: any) => {
    const clonedAdminState = JSON.parse(JSON.stringify(this.state.adminState));
    clonedAdminState[e.target.id] = e.target.value;
    this.setState({
      adminState: clonedAdminState,
    });
  };

  handleSearchPromoCode = async () => {
    const promotionQuery = new ReadQuery({
      dbModule: firebase,
      dbType: "firebase",
      dbCollection: "promotion",
    });
    try {
      const options: OptionV2[] = [];
      const res = await promotionQuery.call({
        type: "list",
        query: [
          {
            type: "search",
            value: this.state.promoSearch,
            searchIds: ["id"],
          },
        ],
        limit: 10,
      });
      if (res && res.data) {
        res.data.data.map((eachPromo: PromotionModel) => {
          options.push({
            key: eachPromo.id,
            label: eachPromo.id,
          });
          return null;
        });
        this.setState({
          promoCodes: options,
        });
      }
    } catch (err) {}
  };

  handleRefreshPromoCode = (promoCode: string) => {
    this.setState(
      {
        promoSearch: promoCode,
      },
      () => {
        this.handleSearchPromoCode();
      }
    );
  };

  handleSelectV2Change = (value: string) => {
    const clonedAdminState = JSON.parse(JSON.stringify(this.state.adminState));
    clonedAdminState["promo"] = value;
    this.setState({
      adminState: clonedAdminState,
    });
  };

  handleSelectChange = (id: string, key: string) => {
    const clonedAdminState = JSON.parse(JSON.stringify(this.state.adminState));
    if (id === "state") {
      if (clonedAdminState[id].includes(key)) {
        const indexItem = clonedAdminState[id].indexOf(key);
        clonedAdminState[id].splice(indexItem, 1);
      } else {
        clonedAdminState[id].push(key);
      }
    } else {
      clonedAdminState[id] = key;
      if (key === "AGENT") {
        this.handleRefreshPromoCode("");
      }
    }
    this.setState({
      adminState: clonedAdminState,
    });
  };

  handleDeleteAdmin = async () => {
    await deleteAdmin(this.state.adminState.id);
    this.props.history.push("/dashboard/admins?data=r");
  };

  handleSubmit = async () => {
    this.handleLoading(true);
    let conditionsList: string[] = ["name", "email", "password", "role"];
    if (this.promoSelectV2.current) {
      await this.promoSelectV2.current.handleSubmit();
    }
    const clonedAdminError = JSON.parse(
      JSON.stringify(this.state.adminStateError)
    );
    handleAdminCondition(
      this.state.adminState,
      clonedAdminError,
      conditionsList
    );
    this.setState(
      {
        adminStateError: clonedAdminError,
      },
      async () => {
        let promoCodeError = false;
        if (this.state.adminState.role === "AGENT") {
          promoCodeError = this.state.adminState.promo ? false : true;
        }
        if (
          !this.state.adminStateError.nameError &&
          !this.state.adminStateError.emailError &&
          !this.state.adminStateError.passwordError &&
          !this.state.adminStateError.roleError &&
          !promoCodeError
        ) {
          await createAdmin(this.state.adminState);
          this.props.history.push("/dashboard/admins?data=r");
        }
        this.handleLoading(false);
      }
    );
  };

  renderStateOption = () => {
    let stateOption: Option[] = [];
    Object.keys(States).map((eachStates) => {
      if (eachStates !== "PN" && eachStates !== "MLC" && eachStates !== "FT") {
        stateOption.push({
          key: eachStates,
          title: States[eachStates as keyof typeof States],
        });
      }
      return null;
    });
    return stateOption;
  };

  renderStates = () => {
    let stateString = "";
    if (this.state.adminState) {
      this.state.adminState.state.map((eachState, index) => {
        stateString += `${index ? ", " : ""}${States[eachState]}`;
        return null;
      });
    }
    return stateString;
  };

  renderBreadcrumb = () => {
    return (
      <nav
        className="bg-white border-b border-gray-200 flex mt-0.5 mx-auto w-full"
        aria-label="Breadcrumb"
      >
        <ol className="max-w-6xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8 ">
          <li className="flex">
            <div className="flex items-center">
              <p
                className="text-gray-600 hover:text-arusgreen-500 cursor-pointer"
                onClick={this.handleBack}
              >
                <HomeIcon className="flex-shrink-0 h-5 w-5" />
              </p>
            </div>
          </li>
          {this.renderBreadcrumbItem(
            this.state.creation ? "New Admin" : "Admin Details"
          )}
        </ol>
      </nav>
    );
  };

  renderBreadcrumbItem = (title: String) => {
    return (
      <li className="flex">
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <p className="ml-4 text-sm font-medium text-gray-500">{title}</p>
        </div>
      </li>
    );
  };

  renderContent = () => {
    if (this.state.creation) {
      return (
        <div className="bg-white shadow-xl border-t border-gray-200 py-2 sm:p-0 sm:rounded-lg grid grid-cols-1 lg:grid-cols-2">
          <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12 xl:pb-24">
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <Input
                  id="name"
                  className="mt-1"
                  placeholder="Name"
                  value={this.state.adminState.name}
                  error={this.state.adminStateError.nameError}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <Input
                  id="email"
                  className="mt-1"
                  placeholder="Email"
                  value={this.state.adminState.email}
                  error={this.state.adminStateError.emailError}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Role
                </label>
                <Select
                  id="role"
                  placeholder={"Role"}
                  value={this.state.adminState.role}
                  error={this.state.adminStateError.roleError}
                  options={roleTypeOption}
                  onChange={this.handleSelectChange}
                />
              </div>
              {this.state.adminState.role === "MANAGER" && (
                <div className="col-span-2 sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    State
                  </label>
                  <Select
                    id="state"
                    placeholder={"State"}
                    value={this.state.adminState.state}
                    options={this.renderStateOption()}
                    onChange={this.handleSelectChange}
                  />
                </div>
              )}
              {this.state.adminState.role === "AGENT" && (
                <div className="col-span-2 sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Promo Codes
                  </label>
                  <SelectV2
                    ref={this.promoSelectV2}
                    label=""
                    value={this.state.adminState.promo}
                    options={this.state.promoCodes}
                    onChange={this.handleSelectV2Change}
                    onSearch={this.handleRefreshPromoCode}
                  />
                </div>
              )}
              <div className="col-span-2 sm:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <Input
                  id="password"
                  className="mt-1"
                  placeholder="Password"
                  value={this.state.adminState.password}
                  error={this.state.adminStateError.passwordError}
                  type="password"
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="sm:col-span-2 sm:flex sm:justify-end mt-5 pb-12">
                <Button
                  className="w-full sm:w-auto"
                  text="Submit"
                  type="normal"
                  onClick={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.adminState.email}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.adminState.name}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Role</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.adminState.role}
              </dd>
            </div>
            {this.state.adminState.role === "AGENT" && (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Promo Code
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {this.state.adminState.promo}
                </dd>
              </div>
            )}
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">State</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {this.state.adminState.state ? this.renderStates() : "-"}
              </dd>
            </div>
          </dl>
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <ConfirmationModal
          onClose={this.props.handleOpenConfirmationModal}
          title="Delete Role"
          open={this.props.confirmationModalVisible}
          content={`Are you sure that you want to remove this role?`}
          onClick={this.handleDeleteAdmin}
        />
        <Overlay loading={this.state.loading} text="Creating Admin" />
        {this.renderBreadcrumb()}
        <div className="mt-8">
          <div className="block mt-2">
            <div className="max-w-6xl mx-auto flex flex-col px-4 sm:px-6 lg:px-8">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {this.state.creation ? "New Admin" : "Admin Details"}
                  </h3>
                </div>
                {this.renderContent()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    adminStore: state.adminStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectedAdmin: (selectedAdmin: Admin | null) =>
      dispatch(updateSelectedAdmin(selectedAdmin)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditor);
