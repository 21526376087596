import { GiftIcon } from "@heroicons/react/24/outline";
import {
  BriefcaseIcon,
  ChatBubbleLeftIcon,
  CreditCardIcon,
  HomeIcon,
  KeyIcon,
  PencilSquareIcon,
  ScaleIcon,
  StarIcon,
  UserCircleIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import IconButton from "../base/IconButton";
import { authStateInterface } from "../store/reducers/authReducer";
import DashboardLogo from "./../../images/dashboard_logo.png";

interface Props {
  page: DashboardPage;
  authStore: authStateInterface;
  leftNavMobileMenu: boolean;
  handleToggleLeftNavMobileMenu: () => void;
}

export enum DashboardPage {
  HOME = "Home",
  USERS = "Users",
  USER_EDITOR = "Users Editor",
  SCHEDULES = "Schedules",
  SCHEDULE_EDITOR = "Schedules Editor",
  PROMOTION = "Promotion",
  PROMO_EDITOR = "Promotion Editor",
  TRANSACTION = "Transaction",
  TRANS_EDITOR = "Transaction Editor",
  PEND_TRANSACTION = "Pending Transaction",
  PEND_TRANSACTION_EDITOR = "Pending Transaction Editor",
  NGOS = "Ngos",
  NGO_EDITOR = "Ngo Editor",
  REWARD = "Reward",
  REWARDEDITOR = "Reward Editor",
  OPERATION = "Operation",
  OPERATIONEDITOR = "Operation Editor",
  ADMINS = "Admins",
  ADMIN_EDITOR = "Admins Editor",
  JOBS = "Jobs",
  JOBS_EDITOR = "Job Editor",
  RECEIPT = "Receipt",
  CREATE_SCHEDULE = "Create Schedule",
}

export default class LeftNav extends Component<Props> {
  renderDashboardLogo = () => {
    return (
      <Link to="/dashboard">
        <div className="flex items-center flex-shrink-0 px-6 cursor-pointer">
          <img className="h-10 w-auto" src={DashboardLogo} alt="Favicon" />
        </div>
      </Link>
    );
  };

  renderNavItems = () => {
    if (this.props.authStore.user?.role === "SUPER") {
      return (
        <>
          {this.renderLeftNavItems(DashboardPage.HOME)}
          {this.renderLeftNavItems(DashboardPage.ADMINS)}
          {this.renderLeftNavItems(DashboardPage.USERS)}
          {this.renderLeftNavItems(DashboardPage.NGOS)}
          {this.renderLeftNavItems(DashboardPage.REWARD)}
          {this.renderLeftNavItems(DashboardPage.SCHEDULES)}
          {this.renderLeftNavItems(DashboardPage.PROMOTION)}
          {this.renderLeftNavItems(DashboardPage.TRANSACTION)}
          {this.renderLeftNavItems(DashboardPage.PEND_TRANSACTION)}
          {this.renderLeftNavItems(DashboardPage.OPERATION)}
          {this.renderLeftNavItems(DashboardPage.CREATE_SCHEDULE)}
        </>
      );
    } else if (this.props.authStore.user?.role === "AGENT") {
      return (
        <>
          {this.renderLeftNavItems(DashboardPage.HOME)}
          {this.renderLeftNavItems(DashboardPage.JOBS)}
        </>
      );
    } else {
      return (
        <>
          {this.renderLeftNavItems(DashboardPage.HOME)}
          {this.props.authStore.user?.role === "DATA" &&
            this.renderLeftNavItems(DashboardPage.USERS)}
          {this.renderLeftNavItems(DashboardPage.SCHEDULES)}
          {this.renderLeftNavItems(DashboardPage.OPERATION)}
        </>
      );
    }
  };

  renderLeftNavItems = (index: DashboardPage) => {
    let title = "";
    let icon = <HomeIcon />;
    let path = "";

    let defaultClass =
      "text-green-100 hover:text-white hover:bg-green-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer";
    let defaultSvgClass = "text-green-100 group-hover:text-white mr-3 h-6 w-6";

    if (this.props.page === index) {
      defaultClass =
        "bg-green-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer";
      defaultSvgClass = "text-white mr-3 h-6 w-6";
    }

    switch (index) {
      case "Home":
        title = "Home";
        icon = <HomeIcon className={defaultSvgClass} />;
        path = "/dashboard";
        break;
      case "Admins":
      case "Admins Editor":
        title = "Admins";
        icon = <KeyIcon className={defaultSvgClass} />;
        path = "/dashboard/admins";
        break;
      case "Users":
      case "Users Editor":
        title = "Users";
        icon = <UserCircleIcon className={defaultSvgClass} />;
        path = "/dashboard/users";
        break;
      case "Schedules":
      case "Schedules Editor":
        title = "Schedules";
        icon = <ScaleIcon className={defaultSvgClass} />;
        path = "/dashboard/schedules";
        break;
      case "Promotion":
      case "Promotion Editor":
        title = "Promotion";
        icon = <GiftIcon className={defaultSvgClass} />;
        path = "/dashboard/promotion";
        break;
      case "Transaction":
      case "Transaction Editor":
        title = "Transactions";
        icon = <CreditCardIcon className={defaultSvgClass} />;
        path = "/dashboard/transaction";
        break;
      case "Pending Transaction":
      case "Pending Transaction Editor":
        title = "Pending Transactions";
        icon = <CreditCardIcon className={defaultSvgClass} />;
        path = "/dashboard/pendTransactions";
        break;
      case "Ngos":
      case "Ngo Editor":
        title = "NGOs";
        icon = <UserGroupIcon className={defaultSvgClass} />;
        path = "/dashboard/ngos";
        break;
      case "Reward":
      case "Reward Editor":
        title = "Reward";
        icon = <StarIcon className={defaultSvgClass} />;
        path = "/dashboard/reward";
        break;
      case "Operation":
      case "Operation Editor":
        title = "Operation";
        icon = <ChatBubbleLeftIcon className={defaultSvgClass} />;
        path = "/dashboard/operation";
        break;
      case "Jobs":
      case "Job Editor":
        title = "Jobs";
        icon = <BriefcaseIcon className={defaultSvgClass} />;
        path = "/dashboard/jobs";
        break;
      case "Create Schedule":
        title = "Create Schedule";
        icon = <PencilSquareIcon className={defaultSvgClass} />;
        path = "/dashboard/createSchedule";
        break;
    }

    return (
      <Link to={path}>
        <p className={defaultClass} aria-current="page">
          {icon}
          {title}
        </p>
      </Link>
    );
  };

  render() {
    return (
      <>
        {this.props.leftNavMobileMenu && (
          <div
            className="fixed inset-0 flex z-40 lg:hidden"
            role="dialog"
            aria-modal="true"
          >
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-75"
              onClick={this.props.handleToggleLeftNavMobileMenu}
              aria-hidden="true"
            ></div>
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-green-700">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <IconButton
                  icon={<XMarkIcon className="h-5 w-5 text-white" />}
                  background="bg-transparent"
                  className="text-white ml-1 rounded-md flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={this.props.handleToggleLeftNavMobileMenu}
                />
              </div>
              {this.renderDashboardLogo()}
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2">
                  <div className="flex flex-col space-y-1">
                    {this.renderNavItems()}
                  </div>
                </nav>
              </div>
            </div>

            <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
          </div>
        )}

        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-green-700">
            {this.renderDashboardLogo()}
            <div className="h-0 flex-1 flex flex-col overflow-y-auto">
              <nav className="px-3 mt-6">
                <div className="flex flex-col space-y-1">
                  {this.renderNavItems()}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  }
}
