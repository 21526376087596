import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Table, { TableHeader } from "../base/Table";
import {
  getPendingTransactionWithPagination,
  pendTransactionDataLimit,
} from "../store/actions/pendTransactionActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { pendTransactionStateInterface } from "../store/reducers/pendTransactionReducer";
import { userStateInterface } from "../store/reducers/usersReducer";

interface State {
  searchText: string;
  loading: boolean;
}

interface Props {
  pageIndex: number;
  history: any;
  authStore: authStateInterface;
  userStore: userStateInterface;
  pendTransactionStore: pendTransactionStateInterface;
  getPendingTransactionWithPagination: (
    paginationStartAt: string | Date
  ) => void;
  handleUpdatePageIndex: (pageIndex: number) => void;
}

class PendingTransaction extends Component<Props> {
  state: State = {
    searchText: "",
    loading: true,
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      if (
        this.props.pendTransactionStore.lastCursor &&
        this.props.pageIndex * pendTransactionDataLimit + 1 >
          this.props.pendTransactionStore.pendTransaction.length
      ) {
        this.props.getPendingTransactionWithPagination(
          this.props.pendTransactionStore.lastCursor
        );
      }

      this.props.handleUpdatePageIndex(this.props.pageIndex + 1);
    } else {
      this.props.handleUpdatePageIndex(this.props.pageIndex - 1);
    }
  };

  handleSelectedData = (id: string) => {
    this.props.history.push(`/dashboard/pendTransactionEditor?data=${id}`);
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (this.props.pendTransactionStore.pendTransaction?.length > 0) {
      this.props.pendTransactionStore.pendTransaction.map((eachTransaction) => {
        let selectedEmail = "-";
        this.props.userStore.users.map((eachUser) => {
          if (eachUser.id === eachTransaction.userId) {
            selectedEmail = eachUser.email ? eachUser.email : eachUser.mobileNo;
          }
          return "";
        });

        listView.push({
          id: eachTransaction.id,
          pendTransactionId: eachTransaction.id,
          email: selectedEmail,
          amount: eachTransaction.amount,
          date: moment(eachTransaction.date.seconds * 1000).format("DD-MM-YY"),
        });
        return null;
      });
    }
    return listView;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "pendTransactionId",
        title: "pending Transaction Id",
      },
      {
        key: "email",
        title: "Email",
      },
      {
        key: "amount",
        title: "Amount(RM)",
      },
      {
        key: "date",
        title: "Date",
      },
    ];
    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Pending Transactions
            </h3>
          </div>
        </div>

        <Table
          loading={this.props.pendTransactionStore.loading}
          header={headers}
          pageIndex={this.props.pageIndex}
          lastCursor={this.props.pendTransactionStore.lastCursor}
          data={this.renderFilteredData()}
          dataLimit={pendTransactionDataLimit}
          handlePage={this.handlePagination}
          onClickRow={this.handleSelectedData}
          newTabUrl="/dashboard/pendTransactionEditor?data="
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    userStore: state.userStore,
    pendTransactionStore: state.pendTransactionStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getPendingTransactionWithPagination: (paginationStartAt: string | Date) =>
      dispatch(getPendingTransactionWithPagination(paginationStartAt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingTransaction);
