export enum Gender {
  M = "Male",
  F = "Female",
}

export enum BankType {
  AFFIN = "AFFIN BANK",
  ALLIANCE = "ALLIANCE BANK MALAYSIA BERHAD",
  AL_RAJHI = "AL_RAJHI BANK",
  AMBANK = "AMBANK BHD",
  BANKISLAM = "BANK ISLAM MALAYSIA BERHAD",
  BANKKERJASAMARAKYATMALAYSIA = "BANK KERJASAMA RAKYAT MALAYSIA",
  BANKMUAMALAT = "BANK MUAMALAT",
  BANKOFCHINA = "BANK OF CHINA (MALAYSIA) BERHAD",
  BANKPERTANIAN = "BANK PERTANIAN MALAYSIA BERHAD (AGROBANK)",
  BSN = "BANK SIMPANAN NASIONAL",
  CIMB = "CIMB BANK BHD",
  CITI = "CITIBANK BHD",
  DEUTSCHEBANK = "DEUTSCHE BANK",
  HLB = "HONG LEONG BANK BHD",
  HSBC = "HSBC BANK MALAYSIA BHD",
  ICBC = "INDUSTRIAL AND COMMERCIAL BANK OF CHINA",
  JPMORGANCHASE = "J.P. MORGAN CHASE BANK BERHAD",
  KFH = "KUWAIT FINANCE HOUSE (MALAYSIA) BHD",
  MAYBANK = "MALAYAN BANKING BHD (MAYBANK)",
  OCBC = "OCBC BANK MALAYSIA BHD",
  PBB = "PUBLIC BANK BHD",
  RHB = "RHB BANK BHD",
  STANDARDCHARTERED = "STANDARD CHARTERED BANK BHD",
  UOB = "UNITED OVERSEAS BANK MALAYSIA BHD",
}

export enum Age {
  Y = "18 to 30 years old",
  M = "30 to 60 years old",
  O = "Above 60 years old",
}

export enum ProfessionSector {
  ACC = "Accounting & Banking",
  ART = "Arts & Marketing",
  BIZ = "Business Owner/Developer",
  ENG = "Engineer/Technician",
  HEALTH = "Healthcare",
  HOUSE = "Housewives",
  UNEMPLOY = "Unemployed/Retired",
  OTHERS = "Others",
}

export enum FamilyMembers {
  S = "Below 3 members",
  M = "3 to 6 members",
  L = "Above 6 members",
}

export enum CookFrequency {
  LESS = "Only on weekends only",
  AVERAGE = "Once per day",
  INTERMEDIATE = "Twice per day",
  PRO = "More than three times per day",
}

export enum EnterpriseStatus {
  PEND = "Pending",
  ACC = "Accepted",
  REJ = "Rejected",
}

export interface Address {
  name: string;
  lat: number;
  lng: number;
  district?: string;
  state?: string;
  country?: string;
}

export interface PromotionApplied {
  id: string;
  appliedDate: Date;
}

export interface UserModel {
  id: string;
  email?: string;
  name: string;
  gender: keyof typeof Gender;
  age: keyof typeof Age;
  profession: keyof typeof ProfessionSector;
  professionOther?: string;
  mobileNo: string;
  familyMembers: keyof typeof FamilyMembers;
  cookFrequency: keyof typeof CookFrequency;
  createdAt: Date;
  balance?: number;
  image?: string;
  address?: Address;
  promotions?: PromotionApplied[];
  bankName?: keyof typeof BankType;
  bankAccountName?: string;
  bankAccountNo?: string;
  jerryCanRedeem?: Date;
  fcmToken?: string[];
  enterprise?: string;
  enterpriseToken?: string;
  enterpriseStatus?: keyof typeof EnterpriseStatus;
  items?: {
    inventoryId: string;
    quantity: number;
    date: Date;
  }[];
  tngSegment?: "Business" | "Household";
  tngWalletId?: string;
}
