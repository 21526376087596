import React, { Component } from "react";
import { connect } from "react-redux";
import UserEditor from "../dashboard/UserEditor";
import Users from "../dashboard/Users";
import {
  clearInventory,
  clearUsers,
  getInventoryWithPagination,
  getUsersWithPagination,
} from "../store/actions/usersActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { inventoryStateInterface } from "../store/reducers/inventoryReducer";

interface Props {
  history: string;
  pathName: string;
  authStore: authStateInterface;
  inventoryStore: inventoryStateInterface;
  confirmationModalVisible: boolean;
  inventoryModalVisible: boolean;
  handleOpenConfirmationModal: (visible: boolean) => void;
  handleOpenInventoryVisible: (visible: boolean) => void;
  getUsersWithPagination: (
    paginationStartAt: string | Date,
    paginationSearch: string
  ) => void;
  clearUsers: () => void;
  getInventoryWithPagination: (paginationSearch: any) => void;
  clearInventory: () => void;
}

interface State {
  pageIndex: number;
  searchText: string;
  updatePromoModalVisible: boolean;
}

class UserController extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;
  state: State = {
    pageIndex: 1,
    searchText: "",
    updatePromoModalVisible: false,
  };

  componentDidMount() {
    this.props.getUsersWithPagination("", "");
    this.props.getInventoryWithPagination("");
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.pathName !== this.props.pathName) {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedId = searchParams.get("data");
      if (selectedId === "r") {
        this.setState({ searchText: "" });
        this.handleUpdatePageIndex(1);
        this.props.clearUsers();
        this.props.getUsersWithPagination("", "");
      }
    }
  }

  componentWillUnmount() {
    this.props.clearUsers();
    this.props.clearInventory();
  }

  handleOpenPromoModal = (visible: boolean) => {
    this.setState({
      updatePromoModalVisible: visible,
    });
  };

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex });
  };

  handleGetPreviewData = async (type?: string) => {
    if (this.props.authStore.user || type === "search") {
      this.props.getUsersWithPagination("", this.state.searchText);
    }
  };

  handleFilter = (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.handleGetPreviewData("search");
        this.handleUpdatePageIndex(1);
      }, 500);
    });
  };

  render() {
    if (this.props.authStore.user?.role === "AGENT") {
      return null;
    }

    if (this.props.pathName === "/dashboard/users") {
      return (
        <Users
          pageIndex={this.state.pageIndex}
          history={this.props.history}
          searchText={this.state.searchText}
          handleUpdatePageIndex={this.handleUpdatePageIndex}
          getUsersWithPagination={this.props.getUsersWithPagination}
          handleFilter={this.handleFilter}
        />
      );
    } else if (this.props.pathName === "/dashboard/userEditor") {
      return (
        <UserEditor
          history={this.props.history}
          confirmationModalVisible={this.props.confirmationModalVisible}
          updatePromoModalVisible={this.state.updatePromoModalVisible}
          inventoryModalVisible={this.props.inventoryModalVisible}
          handleOpenConfirmationModal={this.props.handleOpenConfirmationModal}
          handleOpenInventoryVisible={this.props.handleOpenInventoryVisible}
          handleOpenPromoModal={this.handleOpenPromoModal}
          inventoryList={this.props.inventoryStore.inventories}
          getInventoryWithPagination={this.props.getInventoryWithPagination}
        />
      );
    } else {
      return null;
    }
  }
}
const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    inventoryStore: state.inventoryStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearUsers: () => dispatch(clearUsers()),
    getUsersWithPagination: (
      paginationStartAt: string | Date,
      paginationSearch: string
    ) => dispatch(getUsersWithPagination(paginationStartAt, paginationSearch)),
    getInventoryWithPagination: (paginationSearch: any) =>
      dispatch(getInventoryWithPagination(paginationSearch)),
    clearInventory: () => dispatch(clearInventory()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserController);
