import axios from "axios";
import Admin from "../../../models/Admin";
import { AdminState, AdminStateError } from "../../dashboard/AdminEditor";
import firebase from "../../utils/firebase";

export const adminDataLimit: number = 10;

export const getAdminsWithPagination = (
  paginationStartAfter: string,
  paginationSearch: string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let adminSnapshot;
      let adminCollectionQuery = firebase
        .firestore()
        .collection("admin")
        .limit(adminDataLimit);

      adminCollectionQuery = adminCollectionQuery.orderBy("email");

      let adminList: Admin[] = getState().adminStore.admins;
      const newAdminList: Admin[] = [];

      if (!paginationStartAfter) {
        adminList = [];
      }

      updateAdminLoadingState(dispatch, true);

      if (paginationSearch) {
        adminSnapshot = await adminCollectionQuery
          .startAt(paginationSearch)
          .endAt(paginationSearch + "\uf8ff")
          .get();
      } else {
        if (paginationStartAfter) {
          adminSnapshot = await adminCollectionQuery
            .startAfter(paginationStartAfter)
            .get();
        } else {
          adminSnapshot = await adminCollectionQuery.get();
        }
      }

      if (adminSnapshot) {
        adminSnapshot.forEach((eachDoc: any) => {
          const eachAdmin = eachDoc.data() as Admin;
          newAdminList.push(eachAdmin);
        });
      }

      if (newAdminList.length > 0) {
        let lastCursor: string | Date = "";
        if (newAdminList.length === adminDataLimit) {
          lastCursor = newAdminList[adminDataLimit - 1].email;
        }

        if (paginationSearch) {
          lastCursor = "";
        }

        dispatch({
          type: "UPDATE_ADMIN_LIST",
          payload: {
            admins: adminList.concat(newAdminList),
            lastCursor: lastCursor,
          },
        });
      } else {
        dispatch({
          type: "UPDATE_ADMIN_LIST",
          payload: {
            admins: adminList,
            lastCursor: "",
          },
        });
      }

      updateAdminLoadingState(dispatch, false);
    } catch (err: any) {
      updateAdminLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const getAdmin = async (selectedId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      let adminQuery = await firebase
        .firestore()
        .collection("admin")
        .doc(selectedId)
        .get();

      return adminQuery.data() as Admin;
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const createAdmin = async (admin: AdminState) => {
  try {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    let defaultWebAPI =
      "https://asia-southeast2-arusoil-web-dev.cloudfunctions.net/createAdmin";
    if (isProd) {
      defaultWebAPI =
        "https://asia-southeast2-arusoil-web.cloudfunctions.net/createAdmin";
    }

    const createAdmin = {
      name: admin.name,
      email: admin.email,
      role: admin.role,
      state: admin.state,
      password: admin.password,
      promo: admin.promo,
    };

    await axios.post(defaultWebAPI, {
      admin: createAdmin,
      token: process.env.REACT_APP_ADMIN_TOKEN,
    });

    return "";
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const deleteAdmin = async (selectedId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      await firebase.firestore().collection("admin").doc(selectedId).delete();
      return "";
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const updateSelectedAdmin = (selectedAdmin: Admin | null) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_SELECTED_ADMIN",
      payload: {
        selectedAdmin: selectedAdmin,
      },
    });
  };
};

export const clearAdmins = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_ADMIN_LIST",
      payload: {
        admins: [],
        lastCursor: "",
      },
    });
  };
};

const updateAdminLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_ADMIN_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const handleAdminCondition = (
  adminAttributeState: AdminState,
  adminAttributeError: AdminStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "name":
        if (adminAttributeState.name.replace(/\s/g, "").length <= 0) {
          adminAttributeError["nameError"] = "Please enter your name";
        } else {
          adminAttributeError["nameError"] = "";
        }
        break;
      case "email":
        /* eslint-disable */
        const filter =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        /* eslint-enable */
        if (!filter.test(adminAttributeState.email.replace(/\s/g, ""))) {
          adminAttributeError["emailError"] =
            "Please enter your email in the correct format";
        } else {
          adminAttributeError["emailError"] = "";
        }
        break;
      case "password":
        if (adminAttributeState.password.replace(/\s/g, "").length < 6) {
          adminAttributeError["passwordError"] =
            "Please enter at least 6 characters";
        } else {
          adminAttributeError["passwordError"] = "";
        }
        break;
      case "role":
        if (!adminAttributeState.role) {
          adminAttributeError["roleError"] = "Please select a role";
        } else {
          adminAttributeError["roleError"] = "";
        }
        break;
    }
    return null;
  });
};
