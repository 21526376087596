import React, { Component } from "react";
import { connect } from "react-redux";
import { Flag } from "../../models/Restaurant";
import { Address } from "../../models/User";
import { Option } from "../base/Select";
import Operation from "../dashboard/Operation";
import OperationEditor from "../dashboard/OperationEditor";
import { flagOption } from "../dashboard/UpdateOperationModal";
import {
  clearRestaurant,
  clearSearchRestaurants,
  getRestaurantWithPagination,
} from "../store/actions/operationActions";

export enum Type {
  SEARCH = "Search",
  PASTRESULT = "Past Result",
}

enum SearchType {
  RESTAURANT = "restaurant",
  NIGHTCLUB = "night_club",
  MEALTAKEAWAY = "meal_takeaway",
  MEALDELIVERY = "meal_delivery",
  CAFE = "cafe",
}

interface Props {
  history: any;
  pathName: string;
  getRestaurantWithPagination: (
    paginationStartAt: string | Date,
    paginationFlag: keyof typeof Flag | string
  ) => void;
  clearRestaurant: () => void;
  clearSearchRestaurants: () => void;
}

interface State {
  type: Type;
  flag: keyof typeof Flag | string;
  pageIndex: number;
  pastResultPageIndex: number;
  searchResults: any[];
  searchState: SearchState;
}

export interface SearchState {
  address: Address;
  radius: number;
  searchType: SearchType;
}

export interface SearchStateError {
  addressError: string;
  radiusError: string;
  typeError: string;
}

class OperationController extends Component<Props> {
  state: State = {
    type: Type.SEARCH,
    flag: "",
    pageIndex: 1,
    pastResultPageIndex: 1,
    searchResults: [],
    searchState: {
      address: {
        name: "",
        lat: 0,
        lng: 0,
      },
      radius: 0,
      searchType: SearchType.RESTAURANT,
    },
  };

  componentWillUnmount() {
    this.props.clearRestaurant();
    this.props.clearSearchRestaurants();
  }

  handleUpdatePageIndex = (pageIndex: number, type: string) => {
    if (type === "restaurant") {
      this.setState({
        pageIndex,
      });
    } else {
      this.setState({
        pastResultPageIndex: pageIndex,
      });
    }
  };

  handleUpdateType = (type: Type) => {
    this.setState({
      type,
    });
  };

  handleSelectFlag = (id: string, key: string) => {
    this.setState(
      {
        pastResultPageIndex: 1,
        flag: key !== "clear" ? key : "",
      },
      () => {
        try {
          this.props.clearRestaurant();
          this.props.getRestaurantWithPagination("", this.state.flag);
        } catch (err) {}
      }
    );
  };

  handleUpdateSearchResult = (results: any[]) => {
    this.setState({
      searchResults: results,
    });
  };

  handleUpdateSearchState = (searchState: SearchState) => {
    this.setState({
      searchState,
    });
  };

  renderFlagOption = () => {
    let newFlagOption: Option[] = [];
    if (this.state.flag) {
      newFlagOption.push({
        key: "clear",
        title: "Clear Selection",
      });
    }
    flagOption.map((eachFlag) => {
      newFlagOption.push(eachFlag);
      return "";
    });
    return newFlagOption;
  };

  render() {
    if (this.props.pathName === "/dashboard/operation") {
      return (
        <Operation
          history={this.props.history}
          flag={this.state.flag}
          type={this.state.type}
          pageIndex={this.state.pageIndex}
          pastResultPageIndex={this.state.pastResultPageIndex}
          searchResults={this.state.searchResults}
          searchState={this.state.searchState}
          handleUpdatePageIndex={this.handleUpdatePageIndex}
          handleUpdateType={this.handleUpdateType}
          handleUpdateSearchResult={this.handleUpdateSearchResult}
          handleUpdateSearchState={this.handleUpdateSearchState}
          handleSelectFlag={this.handleSelectFlag}
          renderFlagOption={this.renderFlagOption}
          getRestaurantWithPagination={this.props.getRestaurantWithPagination}
        />
      );
    } else if (this.props.pathName === "/dashboard/operationEditor") {
      return <OperationEditor history={this.props.history} />;
    } else {
      return null;
    }
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getRestaurantWithPagination: (
      paginationStartAt: string | Date,
      paginationFlag: keyof typeof Flag | string
    ) =>
      dispatch(getRestaurantWithPagination(paginationStartAt, paginationFlag)),
    clearRestaurant: () => dispatch(clearRestaurant()),
    clearSearchRestaurants: () => dispatch(clearSearchRestaurants()),
  };
};

export default connect(null, mapDispatchToProps)(OperationController);
