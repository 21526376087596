import { Restaurant } from "../../../models/Restaurant";

export interface operationStateInterface {
  pagination: any | null;
  restaurants: Restaurant[];
  searchRestaurants: Restaurant[];
  lastCursor: string | Date;
  loading: boolean;
}

const initState: operationStateInterface = {
  pagination: null,
  loading: false,
  restaurants: [],
  searchRestaurants: [],
  lastCursor: "",
};

const operationReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_PAGINATION":
      return {
        ...state,
        pagination: action.payload.pagination,
      };
    case "UPDATE_RESTAURANT_LIST":
      return {
        ...state,
        restaurants: action.payload.restaurants,
        lastCursor: action.payload.lastCursor,
      };
    case "UPDATE_SEARCH_RESTAURANT_LIST":
      return {
        ...state,
        searchRestaurants: action.payload.searchRestaurants,
      };
    case "UPDATE_RESTAURANT_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default operationReducer;
