import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  getAuthListener,
  removeAuthListener,
} from "../components/store/actions/authActions";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";

declare global {
  interface Date {
    seconds: number;
  }
  interface Window {
    google: any;
  }
}

interface Props {
  getAuthListener: () => void;
  removeAuthListener: () => void;
}

class Navigator extends Component<Props> {
  componentDidMount() {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    const mapAPIKey = isProd
      ? process.env.REACT_APP_PROD_MAP_API
      : process.env.REACT_APP_DEV_MAP_API;
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://maps.googleapis.com/maps/api/js?key=${mapAPIKey}&libraries=places`;
    const head = document.getElementsByTagName("head")[0];
    if (head) {
      document.head.appendChild(script);
    }
    this.props.getAuthListener();
  }

  componentWillUnmount() {
    this.props.removeAuthListener();
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/dashboard/users" exact component={Dashboard} />
          <Route path="/dashboard/userEditor" exact component={Dashboard} />
          <Route path="/dashboard/admins" exact component={Dashboard} />
          <Route path="/dashboard/adminEditor" exact component={Dashboard} />
          <Route path="/dashboard/schedules" exact component={Dashboard} />
          <Route path="/dashboard/scheduleEditor" exact component={Dashboard} />
          <Route path="/dashboard/receipt" exact component={Dashboard} />
          <Route path="/dashboard/promotion" exact component={Dashboard} />
          <Route
            path="/dashboard/promotionEditor"
            exact
            component={Dashboard}
          />
          <Route path="/dashboard/transaction" exact component={Dashboard} />
          <Route
            path="/dashboard/transactionEditor"
            exact
            component={Dashboard}
          />
          <Route
            path="/dashboard/pendTransactions"
            exact
            component={Dashboard}
          />
          <Route
            path="/dashboard/pendTransactionEditor"
            exact
            component={Dashboard}
          />
          <Route path="/dashboard/ngos" exact component={Dashboard} />
          <Route path="/dashboard/ngoEditor" exact component={Dashboard} />
          <Route path="/dashboard/reward" exact component={Dashboard} />
          <Route path="/dashboard/rewardEditor" exact component={Dashboard} />
          <Route path="/dashboard/operation" exact component={Dashboard} />
          <Route
            path="/dashboard/operationEditor"
            exact
            component={Dashboard}
          />
          <Route path="/dashboard/jobs" exact component={Dashboard} />
          <Route path="/dashboard/jobEditor" exact component={Dashboard} />
          <Route path="/dashboard/createSchedule" exact component={Dashboard} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAuthListener: () => dispatch(getAuthListener()),
    removeAuthListener: () => dispatch(removeAuthListener()),
  };
};

export default connect(null, mapDispatchToProps)(Navigator);
