import { Query, ReadQuery } from "blixify-ui-web/lib/components/data/readQuery";
import { WriteQuery } from "blixify-ui-web/lib/components/data/writeQuery";
import firebase from "firebase";
import { ScheduleModel } from "../../../models/Schedules";
import { UserModel } from "../../../models/User";

const schedulesDataLimit = 10;
export const getJobsWithPagination = (
  paginationStartAfter: string | Date,
  promoCode: string,
  assignedId: string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      if (promoCode || assignedId) {
        updateScheduleLoadingState(dispatch, true);

        const promotionQuery = new ReadQuery({
          dbModule: firebase,
          dbType: "firebase",
          dbCollection: "schedules",
        });
        const listQuery: Query[] = promoCode
          ? [
              {
                type: "=",
                value: promoCode,
                queryId: "promoCode",
              },
              {
                type: "=",
                value: "",
                queryId: "assignedAgentId",
              },
            ]
          : [
              {
                type: "=",
                value: assignedId,
                queryId: "assignedAgentId",
              },
            ];
        const res = await promotionQuery.call({
          type: "list",
          query: listQuery,
          limit: schedulesDataLimit,
          sort: { sortId: "updatedAt", type: "desc" },
          cursor: paginationStartAfter ? paginationStartAfter : undefined,
        });

        const schedulesList: ScheduleModel[] =
          getState().scheduleStore.schedules;
        const usersList: UserModel[] = getState().userStore.users;
        const newScheduleList: ScheduleModel[] = [];
        if (res && res.data) {
          const userIdList: string[] = [];
          res.data.data.map((eachSchedule: ScheduleModel) => {
            newScheduleList.push(eachSchedule);
            let userPresent = false;
            usersList.map((eachUser) => {
              if (eachUser.id === eachSchedule.userId) {
                userPresent = true;
              }
              return null;
            });

            if (!userPresent) {
              if (!userIdList.includes(eachSchedule.userId)) {
                userIdList.push(eachSchedule.userId);
              }
            }
            return null;
          });

          let lastCursor: Date | string = "";
          if (newScheduleList.length > 0) {
            if (newScheduleList.length === schedulesDataLimit) {
              lastCursor = newScheduleList[schedulesDataLimit - 1].updatedAt;
            }

            dispatch({
              type: "UPDATE_SCHEDULES_LIST",
              payload: {
                schedules: schedulesList.concat(newScheduleList),
                lastCursor: lastCursor,
              },
            });
          } else {
            dispatch({
              type: "UPDATE_SCHEDULES_LIST",
              payload: {
                schedules: schedulesList,
                lastCursor: lastCursor,
              },
            });
          }

          const newUserList: UserModel[] = [];
          if (userIdList.length > 0) {
            while (userIdList.length > 0) {
              const spliceUserIdList = userIdList.splice(0, schedulesDataLimit);

              const userQuery = new ReadQuery({
                dbModule: firebase,
                dbType: "firebase",
                dbCollection: "users",
              });

              const userRes = await userQuery.call({
                type: "list",
                query: [
                  {
                    type: "in",
                    value: spliceUserIdList,
                    queryId: "id",
                  },
                ],
                limit: schedulesDataLimit,
              });
              if (userRes && userRes.data) {
                userRes.data.data.map((eachUser: UserModel) => {
                  newUserList.push(eachUser);
                  return null;
                });
              }
            }
            dispatch({
              type: "UPDATE_USER_LIST",
              payload: {
                users: usersList.concat(newUserList),
              },
            });
          }
        }
      }
      updateScheduleLoadingState(dispatch, false);
    } catch (err: any) {
      updateScheduleLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const updateJobAssignId = async (scheduleId: string, userId: string) => {
  try {
    const assignQuery = new WriteQuery({
      dbModule: firebase,
      dbType: "firebase",
      dbCollection: "schedules",
    });
    const res = await assignQuery.call("update", {
      id: scheduleId,
      data: { assignedAgentId: userId },
    });
    if (res) {
      return null;
    }
  } catch (err) {
    return "Unknown error";
  }
};

const updateScheduleLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_SCHEDULES_LOADING",
    payload: {
      loading: loading,
    },
  });
};
