import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Gender } from "../../models/User";
import Input from "../base/Input";
import Table, { TableHeader } from "../base/Table";
import { userDataLimit } from "../store/actions/usersActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { userStateInterface } from "../store/reducers/usersReducer";

interface Props {
  history: any;
  pageIndex: number;
  searchText: string;
  authStore: authStateInterface;
  userStore: userStateInterface;
  getUsersWithPagination: (
    paginationStartAt: string | Date,
    paginationSearch: string
  ) => void;
  handleUpdatePageIndex: (pageIndex: number) => void;
  handleFilter: (e: any) => void;
}

class Users extends Component<Props> {
  handleSelectedData = (id: string) => {
    this.props.history.push(`/dashboard/userEditor?data=${id}`);
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      //INFO : Check whether need to get new data from firebase
      if (
        this.props.userStore.lastCursor &&
        this.props.pageIndex * userDataLimit + 1 >
          this.props.userStore.users.length
      ) {
        this.props.getUsersWithPagination(this.props.userStore.lastCursor, "");
      }
      this.props.handleUpdatePageIndex(this.props.pageIndex + 1);
    } else {
      this.props.handleUpdatePageIndex(this.props.pageIndex - 1);
    }
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (this.props.userStore.users?.length > 0) {
      this.props.userStore.users.map((eachUser) =>
        listView.push({
          id: eachUser.id,
          email: eachUser.email ? eachUser.email : eachUser.mobileNo,
          name: eachUser.name,
          gender: Gender[eachUser.gender],
          mobileNo: eachUser.mobileNo,
          createdAt: moment(eachUser.createdAt.seconds * 1000).format(
            "DD-MM-YY"
          ),
        })
      );
    }
    return listView;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "email",
        title: "Email",
      },
      {
        key: "name",
        title: "Name",
      },
      {
        key: "gender",
        title: "Gender",
      },
      {
        key: "mobileNo",
        title: "Mobile No",
      },
      {
        key: "createdAt",
        title: "Created At",
      },
    ];

    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              User
            </h3>
            <div className="ml-4 w-60 sm:w-80">
              <Input
                id="id"
                placeholder="Search by Email/Mobile"
                value={this.props.searchText}
                onChange={this.props.handleFilter}
              />
            </div>
          </div>
        </div>

        <Table
          dataLimit={userDataLimit}
          loading={this.props.userStore.loading}
          header={headers}
          pageIndex={this.props.pageIndex}
          lastCursor={this.props.userStore.lastCursor}
          data={this.renderFilteredData()}
          handlePage={this.handlePagination}
          onClickRow={this.handleSelectedData}
          newTabUrl="/dashboard/userEditor?data="
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    userStore: state.userStore,
  };
};

export default connect(mapStateToProps)(Users);
