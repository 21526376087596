import { HomeIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { BannerAction } from "blixify-ui-web";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import Ngo from "../../models/Ngo";
import { PendingTransactionModel } from "../../models/PendingTransaction";
import Reward from "../../models/Reward";
import { UserModel } from "../../models/User";
import Overlay from "../base/Overlay";
import {
  deletePendTransactionById,
  getPendTransaction,
  updateSelectedPendTransaction,
} from "../store/actions/pendTransactionActions";
import { pendTransactionStateInterface } from "../store/reducers/pendTransactionReducer";
import ConfirmationModal from "./ConfirmationModal";
import {
  TransactionEditorError,
  TransactionEditorState,
} from "./TransactionEditor";

interface Props {
  history: any;
  confirmationModalVisible: boolean;
  pendTransactionStore: pendTransactionStateInterface;
  handleOpenConfirmationModal: (visible: boolean) => void;
  updateSelectedPendTransaction: (
    user: UserModel,
    pendTransaction: PendingTransactionModel
  ) => void;
}

interface State {
  loading: boolean;
  pendTransactionLoading: boolean;
  selectedUser: UserModel;
  selectedNgo: Ngo | null;
  selectedReward: Reward | null;
  selectedPendTransaction: PendingTransactionModel;
  transaction: TransactionEditorState;
  transactionError: TransactionEditorError;
}

class PendingTransactionEditor extends Component<Props> {
  state: State = {
    loading: false,
    pendTransactionLoading: false,
    selectedPendTransaction: {
      amount: 0,
      date: moment().toDate(),
      id: "",
      userId: "",
    },
    selectedNgo: null,
    selectedUser: {
      id: "",
      email: "",
      name: "",
      gender: "M",
      age: "M",
      profession: "ACC",
      mobileNo: "",
      familyMembers: "S",
      cookFrequency: "AVERAGE",
      createdAt: new Date(),
    },
    selectedReward: {
      id: "",
      name: "",
      validity: "",
      description: "",
      oneliner: "",
      category: "",
      rewardLogo: "",
      rewardBanner: "",
      importance: 0,
      value: 0,
      tag: "",
    },
    transaction: {
      email: "",
      amount: "",
      date: "",
    },
    transactionError: {
      emailError: "",
      amountError: "",
      dateError: "",
    },
  };

  componentDidMount = async () => {
    this.props.updateSelectedPendTransaction(
      this.state.selectedUser,
      this.state.selectedPendTransaction
    );
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId) {
      const userData = await getPendTransaction(selectedId);
      if (typeof userData === "string") {
        this.handleBack();
      } else {
        this.setState({
          selectedUser: userData.user,
          selectedPendTransaction: userData.pendTransaction,
          selectedNgo: userData.ngo ?? null,
          selectedReward: userData.reward ?? null,
        });
        this.props.updateSelectedPendTransaction(
          userData.user,
          userData.pendTransaction
        );
      }
    } else {
      this.handleBack();
    }
  };

  handlePendTransactionPayUserLoading = (visible: boolean) => {
    this.setState({
      pendTransactionLoading: visible,
    });
  };

  handlePendTransactionPayUser = async () => {
    try {
      this.handlePendTransactionPayUserLoading(true);
      const selectedPendTrans =
        this.props.pendTransactionStore.selectedTransaction;
      if (selectedPendTrans.pendTransaction.tng) {
        if (selectedPendTrans.user.tngWalletId) {
          const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
          const apiPrefix = `https://api${isProd ? "" : "-dev"}.arusoil.com`;
          const tngResp = await axios.post(apiPrefix + "/api/tng/disburse", {
            transactionId: selectedPendTrans.pendTransaction.id,
            accessToken: selectedPendTrans.user.tngWalletId,
          });
          if (tngResp.data?.data) {
            await deletePendTransactionById(
              selectedPendTrans,
              this.state.selectedNgo
            );
          } else throw "Error";
        } else throw "Error";
      } else
        await deletePendTransactionById(
          selectedPendTrans,
          this.state.selectedNgo
        );
      this.handlePendTransactionPayUserLoading(false);
      this.props.history.push("/dashboard/pendTransactions?data=r");
    } catch (err) {
      this.handlePendTransactionPayUserLoading(false);
    }
  };

  handleBack = () => {
    this.props.history.push("/dashboard/pendTransactions");
  };

  renderBreadcrumb = () => {
    return (
      <nav
        className="bg-white border-b border-gray-200 flex mt-0.5 mx-auto w-full"
        aria-label="Breadcrumb"
      >
        <ol className="max-w-6xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8 ">
          <li className="flex">
            <div className="flex items-center">
              <p
                className="text-gray-600 hover:text-arusgreen-500 cursor-pointer"
                onClick={this.handleBack}
              >
                <HomeIcon className="flex-shrink-0 h-5 w-5" />
              </p>
            </div>
          </li>
          {this.renderBreadcrumbItem(`Pending Transaction Details`)}
        </ol>
      </nav>
    );
  };

  renderBreadcrumbItem = (title: String) => {
    return (
      <li className="flex">
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <p className="ml-4 text-sm font-medium text-gray-500">{title}</p>
        </div>
      </li>
    );
  };

  renderContent = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    const donation = this.state.selectedPendTransaction.doneeId ? true : false;
    if (selectedId) {
      let tngText = "[IMPORTANT] Automated TNG Transaction";
      if (this.state.selectedUser.tngWalletId)
        tngText += ", Do Not Transfer via Bank";
      else tngText += ", Call The User To Resign DC";

      return (
        <>
          {this.state.selectedPendTransaction.tng && (
            <BannerAction text={tngText} mobileText={tngText} buttonText="" />
          )}
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Pending Transaction Details
              </h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Pending Transaction ID
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.selectedPendTransaction.id}
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Date</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {moment(
                      this.state.selectedPendTransaction.date.seconds * 1000
                    ).format("DD-MM-YY")}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    {donation ? "Donor Name" : "Name"}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.selectedUser.name}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    {donation ? "Donor Email" : "Email"}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.selectedUser.email
                      ? this.state.selectedUser.email
                      : this.state.selectedUser.mobileNo}
                  </dd>
                </div>
                {this.state.selectedReward ? (
                  <>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Rewards ID
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {this.state.selectedReward.id}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Rewards Name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {this.state.selectedReward.name}
                      </dd>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {donation ? "Donee Bank Name" : "Bank Name"}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {donation
                          ? this.state.selectedNgo?.bankName
                          : this.state.selectedUser.bankName}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {donation ? "Donee Account Name" : "Account Name"}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {donation
                          ? this.state.selectedNgo?.bankAccountName
                          : this.state.selectedUser.bankAccountName}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {donation ? "Donee Account Number" : "Account Number"}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {donation
                          ? this.state.selectedNgo?.bankAccountNo
                          : this.state.selectedUser.bankAccountNo}
                      </dd>
                    </div>
                  </>
                )}

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Amount(RM)
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {this.state.selectedPendTransaction.amount}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </>
      );
    }
  };

  renderTransConfirmation = () => {
    let callToAction = "";
    if (this.state.selectedPendTransaction.doneeId) {
      callToAction = `(Arus Oil account -> ${this.state.selectedNgo?.name} account)`;
    } else if (this.state.selectedPendTransaction.rewardId) {
      callToAction = `(Arus Oil account -> ${this.state.selectedReward?.name})`;
    } else if (this.state.selectedPendTransaction.tng) {
      callToAction = `(Arus Oil account -> TNG Account)`;
    } else {
      callToAction = "(Arus Oil account -> User's bank account)";
    }

    return `Are you sure that you want to create a credit transaction ${callToAction} with the following details? \n\n Email: ${
      this.state.selectedUser.email
    } \n Date: ${moment(
      this.state.selectedPendTransaction.date.seconds * 1000
    ).format("DD-MM-YYYY")} \n Amount: RM${
      this.state.selectedPendTransaction.amount
    }`;
  };

  render() {
    return (
      <>
        <Overlay
          loading={this.state.pendTransactionLoading}
          text="Creating Transaction"
        />
        <ConfirmationModal
          onClose={this.props.handleOpenConfirmationModal}
          title="Create Credit Transaction Confirmation"
          open={this.props.confirmationModalVisible}
          content={this.renderTransConfirmation()}
          onClick={this.handlePendTransactionPayUser}
        />
        {this.renderBreadcrumb()}
        <div className="mt-8">
          <div className="block mt-2">
            <div className="max-w-6xl mx-auto flex flex-col px-4 sm:px-6 lg:px-8">
              {this.renderContent()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    pendTransactionStore: state.pendTransactionStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectedPendTransaction: (
      user: UserModel,
      pendTransaction: PendingTransactionModel
    ) => dispatch(updateSelectedPendTransaction(user, pendTransaction)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingTransactionEditor);
