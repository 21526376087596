import Ngo from "../../../models/Ngo";

export interface ngoStateInterface {
  ngos: Ngo[];
  lastCursor: string | Date;
  loading: boolean;
  selectedNgo: Ngo | null;
}

const initState: ngoStateInterface = {
  ngos: [],
  lastCursor: "",
  loading: false,
  selectedNgo: null,
};

const ngoReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_NGO_LIST":
      return {
        ...state,
        ngos: action.payload.ngos,
        lastCursor: action.payload.lastCursor,
      };
    case "UPDATE_NGO_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "UPDATE_SELECTED_NGO":
      return {
        ...state,
        selectedNgo: action.payload.selectedNgo,
      };
    default:
      return state;
  }
};

export default ngoReducer;
