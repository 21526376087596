import React, { Component } from "react";
import { connect } from "react-redux";
import { States } from "../../models/Admin";
import { Status } from "../../models/Schedules";
import Receipt from "../dashboard/Receipt";
import Schedules from "../dashboard/Schedules";
import SchedulesEditor from "../dashboard/SchedulesEditor";
import {
  clearSchedule,
  getScheduleWithPagination,
} from "../store/actions/schedulesAction";
import { clearUsers } from "../store/actions/usersActions";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  history: any;
  receiptContainerRef: any;
  pathName: string;
  authStore: authStateInterface;
  updateScheduleModalVisible: boolean;
  updateScheduleModalType: string;
  handleOpenScheduleUpdateModal: (visible: boolean) => void;
  getScheduleWithPagination: (
    paginationStartAt: string | Date,
    userId: string,
    state: (keyof typeof States)[],
    statusType?: keyof typeof Status
  ) => void;
  clearSchedule: () => void;
  clearUsers: () => void;
}

interface State {
  pageIndex: number;
  userId: string;
  updateAddressModalVisible: boolean;
  scheduleState: keyof typeof Status | undefined;
}

class ScheduleController extends Component<Props> {
  state: State = {
    pageIndex: 1,
    userId: "",
    updateAddressModalVisible: false,
    scheduleState: undefined,
  };

  componentDidMount() {
    this.props.getScheduleWithPagination(
      "",
      this.state.userId,
      this.props.authStore.user?.state ?? [],
      this.state.scheduleState
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.pathName !== this.props.pathName) {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedId = searchParams.get("data");
      if (selectedId === "r") {
        this.setState({ userId: "", scheduleState: undefined });
        this.handleGetPreviewData();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearSchedule();
    this.props.clearUsers();
  }

  handleOpenAddressModal = (visible: boolean) => {
    this.setState({
      updateAddressModalVisible: visible,
    });
  };

  handleUpdateStatusFilter = (scheduleState: string) => {
    this.setState({ scheduleState, userId: "" }, () => {
      this.handleGetPreviewData();
    });
  };

  handleUpdateUserId = (id: string, key: string) => {
    this.setState({ userId: key, scheduleState: undefined }, () => {
      this.handleGetPreviewData();
    });
  };

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex });
  };

  handleGetPreviewData = async () => {
    this.handleUpdatePageIndex(1);
    this.props.clearSchedule();
    this.props.clearUsers();
    this.props.getScheduleWithPagination(
      "",
      this.state.userId,
      this.props.authStore.user?.state ?? [],
      this.state.scheduleState
    );
  };

  render() {
    if (this.props.authStore.user?.role === "AGENT") {
      return null;
    }

    if (this.props.pathName === "/dashboard/schedules") {
      return (
        <Schedules
          history={this.props.history}
          scheduleState={this.state.scheduleState}
          userId={this.state.userId}
          pageIndex={this.state.pageIndex}
          handleUpdatePageIndex={this.handleUpdatePageIndex}
          handleUpdateStatusFilter={this.handleUpdateStatusFilter}
          handleFilter={this.handleUpdateUserId}
        />
      );
    } else if (
      this.props.pathName === "/dashboard/scheduleEditor" ||
      this.props.pathName === "/dashboard/createSchedule"
    ) {
      const forceCreation = this.props.pathName === "/dashboard/createSchedule";
      return (
        <SchedulesEditor
          history={this.props.history}
          forceCreation={forceCreation ? true : undefined}
          updateAddressModalVisible={this.state.updateAddressModalVisible}
          updateScheduleModalVisible={this.props.updateScheduleModalVisible}
          updateScheduleModalType={this.props.updateScheduleModalType}
          handleOpenScheduleUpdateModal={
            this.props.handleOpenScheduleUpdateModal
          }
          handleOpenAddressModal={this.handleOpenAddressModal}
        />
      );
    } else if (this.props.pathName === "/dashboard/receipt") {
      return (
        <Receipt
          history={this.props.history}
          containerRef={this.props.receiptContainerRef}
        />
      );
    } else {
      return null;
    }
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getScheduleWithPagination: (
      paginationStartAt: string | Date,
      userId: string,
      state: (keyof typeof States)[],
      paginationType?: keyof typeof Status
    ) =>
      dispatch(
        getScheduleWithPagination(
          paginationStartAt,
          userId,
          state,
          paginationType
        )
      ),
    clearSchedule: () => dispatch(clearSchedule()),
    clearUsers: () => dispatch(clearUsers()),
  };
};

export default connect(null, mapDispatchToProps)(ScheduleController);
