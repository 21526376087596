import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { Component, ReactElement } from "react";

interface Props {
  open: boolean;
  title?: string;
  notification?: ReactElement;
  maxWidth?: "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl";
  darkMode?: boolean;
  renderContent?: () => any;
  onClose?: () => any;
}

export class Modal extends Component<Props> {
  renderModalWidth = () => {
    switch (this.props.maxWidth) {
      case "lg":
        return "sm:max-w-lg";
      case "xl":
        return "sm:max-w-xl";
      case "2xl":
        return "sm:max-w-2xl";
      case "3xl":
        return "sm:max-w-3xl";
      case "4xl":
        return "sm:max-w-4xl";
      case "5xl":
        return "sm:max-w-5xl";
      case "6xl":
        return "sm:max-w-6xl";
      case "7xl":
        return "sm:max-w-7xl";
      default:
        return "sm:max-w-lg";
    }
  };

  render() {
    const bgColor = this.props.darkMode ? "bg-gray-900" : "bg-white";
    const textColor = this.props.darkMode ? "text-white" : "text-gray-900";
    const iconColor = this.props.darkMode ? "text-white" : "text-primary-600";

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose ? this.props.onClose : () => {}}
        className="fixed z-50 inset-0 overflow-y-auto"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        >
          {this.props.notification ?? null}
        </DialogBackdrop>
        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className={
                `inline-block w-full align-bottom ${bgColor} rounded-lg px-4 pt-5 pb-10 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 ` +
                this.renderModalWidth()
              }
            >
              {this.props.title && (
                <div className="flex flex-row border-b border-gray-200 pb-4 w-full">
                  <DialogTitle
                    as="h3"
                    className={`text-lg leading-6 font-medium ${textColor}`}
                  >
                    {this.props.title}
                  </DialogTitle>
                  <div className="flex-grow" />
                  {this.props.onClose && (
                    <XMarkIcon
                      className={`h-5 w-5 cursor-pointer ${iconColor} `}
                      onClick={this.props.onClose}
                    />
                  )}
                </div>
              )}
              <div className="mt-2">
                {this.props.renderContent && this.props.renderContent()}
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    );
  }
}
