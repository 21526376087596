import React, { Component } from "react";
import { connect } from "react-redux";
import AdminEditor from "../dashboard/AdminEditor";
import Admins from "../dashboard/Admins";
import {
  clearAdmins,
  getAdminsWithPagination,
} from "../store/actions/adminsActions";
import { adminStateInterface } from "../store/reducers/adminsReducer";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  history: string;
  pathName: string;
  authStore: authStateInterface;
  adminStore: adminStateInterface;
  confirmationModalVisible: boolean;
  handleOpenConfirmationModal: (visible: boolean) => void;
  getAdminsWithPagination: (
    paginationStartAt: string,
    paginationSearch: string
  ) => void;
  clearAdmins: () => void;
}

interface State {
  pageIndex: number;
  searchText: string;
}

class AdminController extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;
  state: State = {
    pageIndex: 1,
    searchText: "",
  };

  componentDidMount() {
    this.props.getAdminsWithPagination("", "");
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.pathName !== this.props.pathName) {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedId = searchParams.get("data");
      if (selectedId === "r") {
        this.setState({ searchText: "" });
        this.handleUpdatePageIndex(1);
        this.props.clearAdmins();
        this.props.getAdminsWithPagination("", "");
      }
    }
  }

  componentWillUnmount() {
    this.props.clearAdmins();
  }

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex });
  };

  handleGetPreviewData = async (type?: string) => {
    if (this.props.adminStore.admins || type === "search") {
      this.props.getAdminsWithPagination("", this.state.searchText);
    }
  };

  handleFilter = (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.handleGetPreviewData("search");
        this.handleUpdatePageIndex(1);
      }, 500);
    });
  };

  render() {
    if (this.props.authStore.user?.role !== "SUPER") {
      return null;
    }

    if (this.props.pathName === "/dashboard/admins") {
      return (
        <Admins
          pageIndex={this.state.pageIndex}
          history={this.props.history}
          searchText={this.state.searchText}
          handleUpdatePageIndex={this.handleUpdatePageIndex}
          getAdminsWithPagination={this.props.getAdminsWithPagination}
          handleFilter={this.handleFilter}
        />
      );
    } else if (this.props.pathName === "/dashboard/adminEditor") {
      return (
        <AdminEditor
          history={this.props.history}
          confirmationModalVisible={this.props.confirmationModalVisible}
          handleOpenConfirmationModal={this.props.handleOpenConfirmationModal}
        />
      );
    } else {
      return null;
    }
  }
}
const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    adminStore: state.adminStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearAdmins: () => dispatch(clearAdmins()),
    getAdminsWithPagination: (
      paginationStartAt: string,
      paginationSearch: string
    ) => dispatch(getAdminsWithPagination(paginationStartAt, paginationSearch)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminController);
