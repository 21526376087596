import axios from "axios";
import moment from "moment";
import { States } from "../../../models/Admin";
import EventModel from "../../../models/Event";
import {
  PreferredTime,
  PreferrredDay,
  Process,
  ProcessType,
  ScheduleModel,
  Status,
} from "../../../models/Schedules";
import { Address, UserModel } from "../../../models/User";
import {
  ScheduleState,
  ScheduleStateError,
} from "../../dashboard/SchedulesEditor";
import {
  UpdateScheduleProcessAttribute,
  UpdateScheduleProcessAttributeError,
} from "../../dashboard/UpdateScheduleModal";
import firebase from "../../utils/firebase";

export const schedulesDataLimit: number = 10;

export const getScheduleWithPagination = (
  paginationStartAfter: string | Date,
  userId: string,
  adminState: (keyof typeof States)[],
  scheduleState?: keyof typeof Status
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let schedulesSnapshot;
      let scheduleQuery = firebase
        .firestore()
        .collection("schedules")
        .orderBy("updatedAt", "desc")
        .limit(schedulesDataLimit);

      const stateQuery: string[] = [];
      adminState.map((eachState) => {
        stateQuery.push(States[eachState]);
        return null;
      });

      if (adminState.includes("PNG")) {
        stateQuery.push(States["PN"]);
      } else if (adminState.includes("MLK")) {
        stateQuery.push(States["MLC"]);
      } else if (adminState.includes("KL")) {
        stateQuery.push(States["FT"]);
      }

      if (userId) {
        scheduleQuery = scheduleQuery.where("userId", "==", userId);
      } else if (scheduleState) {
        scheduleQuery = scheduleQuery.where("status", "==", scheduleState);
      }

      if (adminState.length > 0) {
        if (adminState.includes("BN"))
          scheduleQuery = scheduleQuery.where(
            "address.country",
            "==",
            "Brunei"
          );
        else
          scheduleQuery = scheduleQuery.where(
            "address.state",
            "in",
            stateQuery
          );
      }

      const schedulesList: ScheduleModel[] = getState().scheduleStore.schedules;
      const usersList: UserModel[] = getState().userStore.users;
      const newScheduleList: ScheduleModel[] = [];

      updateScheduleLoadingState(dispatch, true);

      if (paginationStartAfter) {
        schedulesSnapshot = await scheduleQuery
          .startAfter(paginationStartAfter)
          .get();
      } else {
        schedulesSnapshot = await scheduleQuery.get();
      }

      if (schedulesSnapshot) {
        const userIdList: string[] = [];
        schedulesSnapshot.forEach((eachDoc: any) => {
          const eachSchedule = eachDoc.data() as ScheduleModel;
          newScheduleList.push(eachSchedule);

          let userPresent = false;
          usersList.map((eachUser) => {
            if (eachUser.id === eachSchedule.userId) {
              userPresent = true;
            }
            return null;
          });

          if (!userPresent) {
            if (!userIdList.includes(eachSchedule.userId)) {
              userIdList.push(eachSchedule.userId);
            }
          }
        });

        const newUserList: UserModel[] = [];
        let lastCursor: Date | string = "";

        if (newScheduleList.length > 0) {
          if (newScheduleList.length === schedulesDataLimit) {
            lastCursor = newScheduleList[schedulesDataLimit - 1].updatedAt;
          }

          dispatch({
            type: "UPDATE_SCHEDULES_LIST",
            payload: {
              schedules: schedulesList.concat(newScheduleList),
              lastCursor: lastCursor,
            },
          });
        } else {
          dispatch({
            type: "UPDATE_SCHEDULES_LIST",
            payload: {
              schedules: schedulesList,
              lastCursor: lastCursor,
            },
          });
        }

        if (userIdList.length > 0) {
          while (userIdList.length > 0) {
            const spliceUserIdList = userIdList.splice(0, schedulesDataLimit);
            const userSnapshot = await firebase
              .firestore()
              .collection("users")
              .where("id", "in", spliceUserIdList)
              .get();
            if (userSnapshot) {
              userSnapshot.forEach((eachUser: any) => {
                const eachUserData = eachUser.data() as UserModel;
                newUserList.push(eachUserData);
              });
            }
          }

          dispatch({
            type: "UPDATE_USER_LIST",
            payload: {
              users: usersList.concat(newUserList),
            },
          });
        }
      }

      updateScheduleLoadingState(dispatch, false);
    } catch (err: any) {
      updateScheduleLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const clearSchedule = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_SCHEDULES_LIST",
      payload: {
        schedules: [],
        lastCursor: "",
      },
    });
  };
};

const updateScheduleLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_SCHEDULES_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const getSchedule = async (selectedId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      let scheduleQuery = await firebase
        .firestore()
        .collection("schedules")
        .doc(selectedId)
        .get();

      if (scheduleQuery.exists) {
        const currentSchedule = scheduleQuery.data() as ScheduleModel;
        const userQuery = await firebase
          .firestore()
          .collection("users")
          .doc(currentSchedule.userId)
          .get();

        if (userQuery.exists) {
          return {
            schedule: currentSchedule,
            user: userQuery.data() as UserModel,
          };
        } else {
          return "User cannot be found";
        }
      } else {
        return "Appointment cannot be found";
      }
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const updateScheduleProcess = async (
  selectedUser: UserModel,
  selectedId: string,
  newProcess: Process[],
  status: keyof typeof Status,
  events?: string,
  paymentMethod?: any
) => {
  try {
    let newSchedule: any = {
      processes: newProcess,
      status: status as keyof typeof Status,
      updatedAt: moment().toDate(),
      collectedBy: firebase.auth().currentUser?.uid,
    };
    if (events) {
      newSchedule = {
        ...newSchedule,
        eventCode: events,
      };
    }
    if (paymentMethod) {
      newSchedule = {
        ...newSchedule,
        paymentMethod,
      };
    }

    await firebase
      .firestore()
      .collection("schedules")
      .doc(selectedId)
      .update(newSchedule);

    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    if (isProd) {
      emailNotificationForSchedule(
        selectedUser,
        status,
        newProcess[newProcess.length - 1]
      );
      pushNotificationForSchedule(selectedUser.id, selectedId);
    }
    return "";
  } catch (err: any) {
    return err.messages;
  }
};

export const handleUploadPaidByCashImage = async (id: string, image: any) => {
  try {
    const uploadTask = await firebase
      .storage()
      .ref("schedules")
      .child(id + `/processes/${id}/resit.jpg`)
      .put(image);

    const uploadTaskUrl: string = await uploadTask.ref.getDownloadURL();
    const urlParams = new URLSearchParams(uploadTaskUrl);
    const imageToken = urlParams.get("token");
    return imageToken;
  } catch (err) {
    return false;
  }
};

export const updateScheduleDistance = async (
  selectedId: string,
  travelDistance: number
) => {
  try {
    await firebase
      .firestore()
      .collection("schedules")
      .doc(selectedId)
      .update({
        travelDistance: Number(travelDistance.toFixed(2)),
      });
  } catch (err: any) {
    return err.messages;
  }
};

export const updateScheduleLocation = async (
  selectedId: string,
  location: Address,
  type: string
) => {
  try {
    if (type === "startPoint") {
      await firebase
        .firestore()
        .collection("schedules")
        .doc(selectedId)
        .update({
          startPoint: location,
        });
    } else {
      await firebase
        .firestore()
        .collection("schedules")
        .doc(selectedId)
        .update({
          endPoint: location,
        });
    }
  } catch (err: any) {
    return err.messages;
  }
};

export const updateSelectedSchedule = (schedule: ScheduleModel) => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_SELECTED_SCHEDULE",
      payload: {
        selectedSchedule: schedule,
      },
    });
  };
};

export const clearSelectedSchedule = () => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_SELECTED_SCHEDULE",
      payload: {
        selectedSchedule: {
          id: "",
          userId: "",
          phone: "",
          address: {
            name: "",
            lat: 0,
            lng: 0,
          },
          image: "",
          preferredDay: "START",
          preferredTime: "MORNING",
          updatedAt: moment().toDate(),
          status: "PEND",
          additionRemark: "",
          estimatedWeight: 0,
          processes: [],
        },
      },
    });
  };
};

export const handleScheduleCondition = (
  scheduleState: UpdateScheduleProcessAttribute,
  scheduleError: UpdateScheduleProcessAttributeError,
  typeList: string[],
  paymentMethod: "Cash" | "Bank" | "Points" | "TNG",
  file: string | File
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "processType":
        if (!scheduleState.processType) {
          scheduleError["processTypeError"] = "Please select a process type";
        } else {
          scheduleError["processTypeError"] = "";
        }
        if (paymentMethod) {
          switch (paymentMethod) {
            case "Bank":
            case "Points":
              break;
            case "Cash":
              if (file instanceof File) {
                const fileExtensionFilter = /.(jpg|jpeg|png)$/;
                const fileSize = file.size / 1024 / 1024;
                if (!fileExtensionFilter.test(file.name)) {
                  scheduleError["imageError"] = "Please upload an image file";
                } else if (fileSize > 5) {
                  scheduleError["imageError"] = "File size cannot exceed 5MB";
                } else {
                  scheduleError["imageError"] = "";
                }
              } else {
                scheduleError["imageError"] = "Please upload an image file";
              }
              break;
            default:
              break;
          }
        }

        break;
      case "desc":
        switch (scheduleState.processType) {
          case "COMPLETED":
            if (Number(scheduleState.desc) > 0) {
              scheduleError["descError"] = "";
            } else {
              scheduleError["descError"] =
                "Please enter the amount of oil you have collected";
            }

            break;
          case "DECLINED":
            if (scheduleState.desc.replace(/\s/g, "").length <= 0) {
              scheduleError["descError"] =
                "Please enter the reason of rejection";
            } else {
              scheduleError["descError"] = "";
            }
            break;
          case "DISPATCHED":
            if (scheduleState.desc.replace(/\s/g, "").length <= 0) {
              scheduleError["descError"] =
                "Please enter the model & vehicle number";
            } else {
              scheduleError["descError"] = "";
            }
            break;
          case "SCHEDULED":
            if (scheduleState.desc.replace(/\s/g, "").length <= 0) {
              scheduleError["descError"] = "Please enter the date";
            } else if (
              moment(scheduleState.desc, "DD-MM-YYYY").format("DD-MM-YYYY") !==
              scheduleState.desc
            ) {
              scheduleError["descError"] =
                "Please enter the date with the format of date DD-MM-YYYY (eg 30-05-2021)";
            } else {
              scheduleError["descError"] = "";
            }
            break;
        }
        break;
    }
    return "";
  });
};

export const handleCheckAddressCondition = (
  scheduleAttributeState: Address
) => {
  if (scheduleAttributeState.name.replace(/\s/g, "").length <= 0) {
    return "Please enter new address";
  }
};

export const handleCreateScheduleCondition = (
  scheduleAttributeState: ScheduleState,
  scheduleAttributeError: ScheduleStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "phone":
        const phoneFilter = /^(\+60)-*[0-9]{8,10}$/;
        if (!phoneFilter.test(scheduleAttributeState.phone)) {
          scheduleAttributeError["phoneError"] =
            "Please enter your contact number in the correct format of +60123456789 (Example)";
        } else {
          scheduleAttributeError["phoneError"] = "";
        }
        break;
      case "address":
        if (
          scheduleAttributeState.address.name.replace(/\s/g, "").length <= 0
        ) {
          scheduleAttributeError["addressError"] =
            "Please select your address from the suggestions given";
        } else {
          scheduleAttributeError["addressError"] = "";
        }
        break;
      case "email":
        if (scheduleAttributeState.email.length <= 0) {
          scheduleAttributeError["emailError"] =
            "Please enter the user's email";
        } else {
          scheduleAttributeError["emailError"] = "";
        }
        break;
      case "preferredTime":
        if (!scheduleAttributeState.preferredTime) {
          scheduleAttributeError["preferredTimeError"] =
            "Please select your preferred time for collection";
        } else {
          scheduleAttributeError["preferredTimeError"] = "";
        }
        break;
      case "preferredDay":
        if (!scheduleAttributeState.preferredDay) {
          scheduleAttributeError["preferredDayError"] =
            "Please select your preferred day for collection";
        } else {
          scheduleAttributeError["preferredDayError"] = "";
        }
        break;
      case "estimatedWeight":
        if (Number(scheduleAttributeState.estimatedWeight) >= 5) {
          scheduleAttributeError["estimatedWeightError"] = "";
        } else {
          scheduleAttributeError["estimatedWeightError"] =
            "Your collected UCO has be to at least 5kg";
        }
        break;
      case "image":
        if (scheduleAttributeState.image) {
          const fileExtensionFilter = /.(jpg|jpeg|png)$/;
          const fileSize = scheduleAttributeState.image.size / 1024 / 1024;
          if (!fileExtensionFilter.test(scheduleAttributeState.image.name)) {
            scheduleAttributeError["imageError"] =
              "Please upload an image file";
          } else if (fileSize > 5) {
            scheduleAttributeError["imageError"] =
              "File size cannot exceed 5MB";
          } else {
            scheduleAttributeError["imageError"] = "";
          }
        } else {
          scheduleAttributeError["imageError"] = "Please upload an image file";
        }
        break;
    }
    return null;
  });
};

const emailNotificationForSchedule = async (
  selectedUser: UserModel,
  status: keyof typeof Status,
  newProcess: Process
) => {
  try {
    let content = "";
    switch (status) {
      case "PLAN":
        content = `Hi ${selectedUser?.name},<br><br>Your appointment for collection of your used cooking oil (UCO) has been scheduled on ${newProcess.description}. We will contact you when our collection vehicle has been dispatched for collection.<br><br>Thanks,<br>Arus Oil`;
        break;
      case "DISP":
        content = `Hi ${selectedUser?.name},<br><br>Our collection vehicle - "${newProcess.description}" will be coming over to your stated address for collection of your used cooking oil (UCO) today.<br><br>Our team will be reminding you on the exact time of collection via your stated mobile number.If you do have any questions,feel free to contact our support hotline at +6013-7311007<br><br>Thanks,<br>Arus Oil`;
        break;
      case "DEC":
        content = `Hi ${selectedUser?.name},<br><br>Your appointment for the collection of your used cooking oil (UCO) has been declined. This is due to the reason of "${newProcess.description}"<br><br>If you do face any problems,feel free to contact us at info@arusoil.com <br><br>Thanks,<br>Arus Oil`;
        break;
      case "COM":
        content = `Hi ${selectedUser?.name},<br><br>We have collected & purchased ${newProcess.description} kg of your used cooking oil (UCO) which costs RM${newProcess.amount} basing on the current purchasing rates. Please find the transaction record being reflected on your transaction dashboard.<br><br>If you enjoy our services, please rate us five stars on Facebook or App Store/Play Store.<br>Facebook : https://facebook.com/getarusoil<br>App Store (IOS): https://apps.apple.com/us/app/arus-oil/id1584768580<br>Play Store (Android) : https://play.google.com/store/apps/details?id=com.arusoil.app<br><br>Your review is appreciated ! Feel free to refer to a friend about Arus Oil to create awareness on recycling used cooking oil.<br><br>Thanks,<br>Arus Oil`;
        break;
    }

    await axios.post(
      "https://api.enginemailer.com/RESTAPI/Submission/SendEmail",
      {
        UserKey: process.env.REACT_APP_EMAIL_SEND_API,
        ToEmail: selectedUser.email,
        SenderEmail: "noreply@arusoil.com",
        SenderName: "Arus Oil",
        Subject: "[Arus Oil] Appointment Updates",
        SubmittedContent: content,
      }
    );
  } catch (err) {}
};

const pushNotificationForSchedule = async (
  selectedUserId: string,
  selectedScheduleId: string
) => {
  try {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    let defaultWebAPI =
      "https://asia-southeast2-arusoil-web-dev.cloudfunctions.net/pushNotificationToUser";
    if (isProd) {
      defaultWebAPI =
        "https://asia-southeast2-arusoil-web.cloudfunctions.net/pushNotificationToUser";
    }

    await axios.post(defaultWebAPI, {
      userId: selectedUserId,
      scheduleId: selectedScheduleId,
    });
  } catch (err) {}
};

export const updateScheduleDate = async (
  selectedId: string,
  updatedAt: Date
) => {
  try {
    if (selectedId && updatedAt) {
      await firebase
        .firestore()
        .collection("schedules")
        .doc(selectedId)
        .update({
          updatedAt: updatedAt,
        });
      return "";
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err: any) {
    return err.message;
  }
};

export const updateScheduleSignatureToken = async (
  selectedId: string,
  dataURL: any
) => {
  try {
    if (selectedId && dataURL) {
      let newSignatureToken = "";
      const byteString = atob(dataURL.split(",")[1]);
      const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const integerArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        integerArray[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([arrayBuffer], { type: mimeString });
      const uploadSignature = await firebase
        .storage()
        .ref("schedules")
        .child(selectedId + `/signature.jpg`)
        .put(blob);

      const uploadSignatureUrl: string =
        await uploadSignature.ref.getDownloadURL();
      const urlParams = new URLSearchParams(uploadSignatureUrl);
      const signatureToken = urlParams.get("token");
      if (signatureToken) {
        newSignatureToken = signatureToken;
      }

      if (newSignatureToken) {
        await firebase
          .firestore()
          .collection("schedules")
          .doc(selectedId)
          .update({
            signature: newSignatureToken,
          });
      }
      return "";
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err: any) {
    return err.message;
  }
};

export const updateScheduleAddress = async (
  selectedSchedule: string,
  selectedId: string,
  address: Address
) => {
  try {
    if (selectedId && address) {
      await firebase.firestore().collection("users").doc(selectedId).update({
        address: address,
      });

      await firebase
        .firestore()
        .collection("schedules")
        .doc(selectedSchedule)
        .update({
          address: address,
        });

      return "";
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err: any) {
    return err.message;
  }
};

export const createSchedule = async (scheduleState: ScheduleState) => {
  try {
    if (scheduleState.image) {
      const scheduleRef = firebase.firestore().collection("schedules").doc();
      const newScheduleRef = scheduleRef.id;
      const uploadTask = await firebase
        .storage()
        .ref("schedules")
        .child(newScheduleRef + `/preview.jpg`)
        .put(scheduleState.image);
      const uploadTaskUrl: string = await uploadTask.ref.getDownloadURL();
      const urlParams = new URLSearchParams(uploadTaskUrl);
      const imageToken = urlParams.get("token");

      const newProcess: Process = {
        type: "CREATED" as keyof typeof ProcessType,
        date: new Date(),
      };
      if (imageToken) {
        const scheduleModel: ScheduleModel = {
          id: newScheduleRef,
          userId: scheduleState.email[0],
          phone: scheduleState.phone,
          address: scheduleState.address,
          image: imageToken,
          updatedAt: new Date(),
          status: "PEND" as keyof typeof Status,
          preferredDay:
            scheduleState.preferredDay as keyof typeof PreferrredDay,
          preferredTime:
            scheduleState.preferredTime as keyof typeof PreferredTime,
          additionRemark: scheduleState.additionRemark,
          estimatedWeight: scheduleState.estimatedWeight,
          signature: "",
          processes: [newProcess],
        };
        await scheduleRef.set(scheduleModel);

        const userRef = firebase
          .firestore()
          .collection("users")
          .doc(scheduleState.email[0]);
        const userQuery = await userRef.get();
        let selectedUser;
        let address = "";
        if (userQuery.exists) {
          selectedUser = userQuery.data() as UserModel;
          address = selectedUser.address?.name ?? "";
        }
        if (!address) {
          userRef.update({
            address: scheduleState.address,
          });
        }
        return {
          scheduleId: newScheduleRef,
        };
      } else throw "Error";
    } else throw "Error";
  } catch (err: any) {
    return {
      error: err.message,
    };
  }
};

export const getEvents = async (selectedId: string) => {
  try {
    const eventsQuery = await firebase
      .firestore()
      .collection("events")
      .doc(selectedId)
      .get();
    if (eventsQuery.exists) {
      return {
        events: eventsQuery.data() as EventModel,
      };
    }
  } catch (err) {}
};
