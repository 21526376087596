import moment from "moment";
import Ngo from "../../../models/Ngo";
import { NgoStateAttribute, NgoStateError } from "../../dashboard/NgoEditor";
import firebase from "../../utils/firebase";

export const ngoDataLimit: number = 10;

export const getNgoWithPagination = (paginationStartAfter: string | Date) => {
  return async (dispatch: any, getState: any) => {
    try {
      let ngoSnapshot;
      let ngoCollectionQuery = firebase
        .firestore()
        .collection("ngos")
        .orderBy("importance")
        .limit(ngoDataLimit);

      let ngoList: Ngo[] = getState().ngoStore.ngos;
      const newNgoList: Ngo[] = [];

      updateNgoLoadingState(dispatch, true);

      if (paginationStartAfter) {
        ngoSnapshot = await ngoCollectionQuery
          .startAfter(paginationStartAfter)
          .get();
      } else {
        ngoSnapshot = await ngoCollectionQuery.get();
      }

      if (ngoSnapshot) {
        ngoSnapshot.forEach((eachDoc: any) => {
          const eachNgo = eachDoc.data() as Ngo;
          newNgoList.push(eachNgo);
        });
      }

      if (newNgoList.length > 0) {
        let lastCursor: string | Date = "";
        if (newNgoList.length === ngoDataLimit) {
          lastCursor = String(newNgoList[ngoDataLimit - 1].importance);
        }

        dispatch({
          type: "UPDATE_NGO_LIST",
          payload: {
            ngos: ngoList.concat(newNgoList),
            lastCursor: lastCursor,
          },
        });
      } else {
        dispatch({
          type: "UPDATE_NGO_LIST",
          payload: {
            ngos: ngoList,
            lastCursor: "",
          },
        });
      }

      updateNgoLoadingState(dispatch, false);
    } catch (err: any) {
      updateNgoLoadingState(dispatch, false);
      return err.message;
    }
  };
};

export const handleNGOCondition = (
  ngoState: NgoStateAttribute,
  ngoError: NgoStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "name":
        if (ngoState.name.replace(/\s/g, "").length <= 0) {
          ngoError["nameError"] = "Please enter the NGO name";
        } else {
          ngoError["nameError"] = "";
        }
        break;
      case "description":
        if (ngoState.description.replace(/\s/g, "").length <= 0) {
          ngoError["descriptionError"] = "Please enter the NGO description";
        } else {
          ngoError["descriptionError"] = "";
        }
        break;
      case "ros":
        if (ngoState.ros.replace(/\s/g, "").length < 6) {
          ngoError["rosError"] = "Please enter the NGO ROS with correct format";
        } else {
          ngoError["rosError"] = "";
        }
        break;
      case "personInChargeName":
        if (ngoState.personInChargeName.replace(/\s/g, "").length <= 0) {
          ngoError["personInChargeNameError"] =
            "Please enter the person in charge's name";
        } else {
          ngoError["personInChargeNameError"] = "";
        }
        break;
      case "personInChargeMobile":
        const phoneFilter = /^(\+60)-*[0-9]{8,10}$/;
        if (!phoneFilter.test(ngoState.personInChargeMobile)) {
          ngoError["personInChargeMobileError"] =
            "Please enter the person in charge's contact number in the correct format of +60123456789 (Example)";
        } else {
          ngoError["personInChargeMobileError"] = "";
        }
        break;
      case "ngoLogo":
        if (ngoState.ngoLogo) {
          const fileExtensionFilter = /.(jpg|jpeg|png)$/;
          const fileSize = ngoState.ngoLogo.size / 1024 / 1024;
          if (!fileExtensionFilter.test(ngoState.ngoLogo.name)) {
            ngoError["ngoLogoError"] = "Please upload an image file";
          } else if (fileSize > 5) {
            ngoError["ngoLogoError"] = "File size cannot exceed 5MB";
          } else {
            ngoError["ngoLogoError"] = "";
          }
        } else {
          ngoError["ngoLogoError"] = "Please upload your NGO's Logo";
        }
        break;
      case "ngoBanner":
        if (ngoState.ngoBanner) {
          const fileExtensionFilter = /.(jpg|jpeg|png)$/;
          const fileSize = ngoState.ngoBanner.size / 1024 / 1024;
          if (!fileExtensionFilter.test(ngoState.ngoBanner.name)) {
            ngoError["ngoBannerError"] = "Please upload an image file";
          } else if (fileSize > 5) {
            ngoError["ngoBannerError"] = "File size cannot exceed 5MB";
          } else {
            ngoError["ngoBannerError"] = "";
          }
        } else {
          ngoError["ngoBannerError"] = "Please upload your NGO's Banner";
        }
        break;
      case "addressStreet":
        if (ngoState.address.street.replace(/\s/g, "").length <= 0) {
          ngoError["addressStreetError"] =
            "Please enter the NGO street address";
        } else {
          ngoError["addressStreetError"] = "";
        }
        break;
      case "addressPostcode":
        if (ngoState.address.poscode.replace(/\s/g, "").length <= 0) {
          ngoError["addressPoscodeError"] =
            "Please enter the NGO postcode address";
        } else {
          ngoError["addressPoscodeError"] = "";
        }
        break;
      case "importance":
        if (ngoState.importance.toString().replace(/\s/g, "").length <= 0) {
          ngoError["importanceError"] = "Please enter the NGO importance";
        } else {
          ngoError["importanceError"] = "";
        }
        break;
      case "purpose":
        if (ngoState.purpose.replace(/\s/g, "").length <= 0) {
          ngoError["purposeError"] = "Please enter the purpose of fundraising";
        } else {
          ngoError["purposeError"] = "";
        }
        break;
      case "targetBeneficiaries":
        if (ngoState.targetBeneficiaries.replace(/\s/g, "").length <= 0) {
          ngoError["targetBeneficiariesError"] =
            "Please enter the NGO's target beneficiaries";
        } else {
          ngoError["targetBeneficiariesError"] = "";
        }
        break;
      case "oneliner":
        if (ngoState.oneliner.replace(/\s/g, "").length <= 0) {
          ngoError["onelinerError"] = "Please enter the NGO target oneliner";
        } else {
          ngoError["onelinerError"] = "";
        }
        break;
      case "bankName":
        if (!ngoState.bankName) {
          ngoError.bankNameError = "Please select a valid bank.";
        } else {
          ngoError.bankNameError = "";
        }
        break;
      case "bankAccountName":
        if (ngoState.bankAccountName.replace(/\s/g, "").length <= 0) {
          ngoError.bankAccountNameError =
            "Please enter valid bank account name.";
        } else {
          ngoError.bankAccountNameError = "";
        }
        break;
      case "bankAccountNo":
        if (ngoState.bankAccountNo.replace(/\s/g, "").length <= 0) {
          ngoError.bankAccountNoError =
            "Please enter valid bank account number.";
        } else {
          ngoError.bankAccountNoError = "";
        }
        break;
    }
    return null;
  });
};

export const getNgo = async (selectedId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      let ngoQuery = await firebase
        .firestore()
        .collection("ngos")
        .doc(selectedId)
        .get();
      return ngoQuery.data() as Ngo;
    } else {
      return "Ngo cannot be found";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const deleteNgo = async (selectedId: string) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      await firebase
        .storage()
        .ref("ngos")
        .child(selectedId + "/logo.jpg")
        .delete();

      await firebase
        .storage()
        .ref("ngos")
        .child(selectedId + "/banner.jpg")
        .delete();

      await firebase.firestore().collection("ngos").doc(selectedId).delete();
      return "";
    } else {
      return "Ngo cannot be found";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const updateNgo = async (ngo: NgoStateAttribute) => {
  try {
    const ngoRef = firebase.firestore().collection("ngos").doc(ngo.id);

    await ngoRef.update({
      name: ngo.name,
      description: ngo.description,
      ros: ngo.ros,
      oneliner: ngo.oneliner,
      personInChargeName: ngo.personInChargeName,
      personInChargeMobile: ngo.personInChargeMobile,
      address: ngo.address,
      importance: Number(ngo.importance),
      bankName: ngo.bankName,
      bankAccountName: ngo.bankAccountName,
      bankAccountNo: ngo.bankAccountNo,
      website: ngo.website,
    });
  } catch (err: any) {
    return err.message;
  }
};

export const createNgo = async (ngo: NgoStateAttribute) => {
  try {
    const ngoRef = firebase.firestore().collection("ngos").doc();
    const newNgoRef = ngoRef.id;
    if (ngo.ngoLogo && ngo.ngoBanner) {
      const logoUploadTask = await firebase
        .storage()
        .ref("ngos")
        .child(newNgoRef + `/logo.jpg`)
        .put(ngo.ngoLogo);
      const logoUploadTaskUrl: string =
        await logoUploadTask.ref.getDownloadURL();
      const logoUrlParams = new URLSearchParams(logoUploadTaskUrl);
      const logoImageToken = logoUrlParams.get("token");

      const bannerUploadTask = await firebase
        .storage()
        .ref("ngos")
        .child(newNgoRef + `/banner.jpg`)
        .put(ngo.ngoBanner);
      const bannerUploadTaskUrl: string =
        await bannerUploadTask.ref.getDownloadURL();
      const bannerUrlParams = new URLSearchParams(bannerUploadTaskUrl);
      const bannerImageToken = bannerUrlParams.get("token");

      if (logoImageToken && bannerImageToken) {
        const ngoModel = {
          id: newNgoRef,
          name: ngo.name,
          createdAt: moment().toDate(),
          description: ngo.description,
          ros: ngo.ros,
          personInChargeName: ngo.personInChargeName,
          personInChargeMobile: ngo.personInChargeMobile,
          oneliner: ngo.oneliner,
          purpose: ngo.purpose,
          targetBeneficiaries: ngo.targetBeneficiaries,
          ngoLogo: logoImageToken,
          ngoBanner: bannerImageToken,
          address: ngo.address,
          importance: ngo.importance,
          totalDonation: 0,
          bankName: ngo.bankName,
          bankAccountName: ngo.bankAccountName,
          bankAccountNo: ngo.bankAccountNo,
          website: ngo.website,
        };

        await ngoRef.set(ngoModel);
        return "";
      } else {
        return "Unknown error, please contact developer at info@arusoil.com if this continues";
      }
    }
  } catch (err: any) {
    return err.message;
  }
};

const updateNgoLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_NGO_LOADING",
    payload: {
      loading: loading,
    },
  });
};

export const updateSelectedNgo = (selectedNgo: Ngo | null) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_SELECTED_NGO",
      payload: {
        selectedNgo: selectedNgo,
      },
    });
  };
};

export const clearNgo = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_NGO_LIST",
      payload: {
        ngos: [],
        lastCursor: "",
      },
    });
  };
};
