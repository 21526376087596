import { HomeIcon } from "@heroicons/react/24/outline";
import React, { Component } from "react";
import { connect } from "react-redux";
import Reward from "../../models/Reward";
import Button from "../base/Button";
import Input from "../base/Input";
import Overlay from "../base/Overlay";
import UploadInput from "../base/UploadInput";
import {
  createReward,
  deleteReward,
  getReward,
  handleRewardCondition,
  updateReward,
  updateSelectedReward,
} from "../store/actions/rewardActions";
import ConfirmationModal from "./ConfirmationModal";

interface Props {
  history: any;
  confirmationModalVisible: boolean;
  handleOpenConfirmationModal: (visible: boolean) => void;
  updateSelectedReward: (selectedReward: Reward | null) => void;
}

export interface RewardStateAttribute {
  id: string;
  name: string;
  validity: string;
  description: string;
  oneliner: string;
  rewardLogo: File | "";
  rewardBanner: File | "";
  importance: number;
  category: string;
  tag: string;
  value: number;
  website?: string;
}

export interface RewardStateError {
  nameError: string;
  descriptionError: string;
  validityError: string;
  onelinerError: string;
  importanceError: string;
  rewardLogoError: string;
  rewardBannerError: string;
  valueError: string;
  websiteError: string;
  logoTokenError: string;
  categoryError: string;
  tagError: string;
  bannerTokenError: string;
}

interface State {
  creation: boolean;
  loading: boolean;
  rewardState: RewardStateAttribute;
  rewardStateError: RewardStateError;
}

class RewardEditor extends Component<Props> {
  state: State = {
    creation: false,
    loading: false,
    rewardState: {
      id: "",
      name: "",
      description: "",
      validity: "",
      oneliner: "",
      importance: 0,
      rewardLogo: "",
      rewardBanner: "",
      category: "",
      tag: "",
      value: 0,
      website: "",
    },
    rewardStateError: {
      nameError: "",
      descriptionError: "",
      validityError: "",
      onelinerError: "",
      rewardLogoError: "",
      rewardBannerError: "",
      categoryError: "",
      tagError: "",
      importanceError: "",
      valueError: "",
      websiteError: "",
      logoTokenError: "",
      bannerTokenError: "",
    },
  };

  componentDidMount = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId && selectedId !== "new") {
      const rewardData = await getReward(selectedId);
      if (typeof rewardData === "string") {
        this.handleBack();
      } else {
        this.setState(
          {
            rewardState: rewardData,
          },
          () => {
            this.props.updateSelectedReward(rewardData);
          }
        );
      }
    } else if (selectedId === "new") {
      this.setState({ creation: true });
    } else {
      this.handleBack();
    }
  };

  componentWillUnmount() {
    this.props.updateSelectedReward(null);
  }

  handleCloneRewardState = () => {
    const currentLogoImage = this.state.rewardState.rewardLogo;
    const currentBannerImage = this.state.rewardState.rewardBanner;
    const clonedRewardState = JSON.parse(
      JSON.stringify(this.state.rewardState)
    );

    clonedRewardState["rewardLogo"] = currentLogoImage;
    clonedRewardState["rewardBanner"] = currentBannerImage;
    return clonedRewardState;
  };

  handleInputChange = (e: any) => {
    const clonedRewardState = this.handleCloneRewardState();
    clonedRewardState[e.target.id] = e.target.value;
    this.setState({
      rewardState: clonedRewardState,
    });
  };

  handleSelectChangeAddress = (id: string, key: string) => {
    const clonedRewardState = this.handleCloneRewardState();
    clonedRewardState["address"][id] = key;
    this.setState({
      rewardState: clonedRewardState,
    });
  };

  handleSelectChange = (id: string, key: string) => {
    const clonedRewardState = this.handleCloneRewardState();

    clonedRewardState[id] = key;
    this.setState({
      rewardState: clonedRewardState,
    });
  };

  handleAddressInputChange = (attribute: string, e: any) => {
    const clonedRewardState = this.handleCloneRewardState();
    clonedRewardState[e.target.id][attribute] = e.target.value;
    this.setState({
      rewardState: clonedRewardState,
    });
  };

  handleUploadChange = (id: string, e: any) => {
    const clonedRewardState = this.handleCloneRewardState();
    clonedRewardState[id] = e.target.files[0];
    this.setState({
      rewardState: clonedRewardState,
    });
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleBack = () => {
    this.props.history.push("/dashboard/reward");
  };

  handleDeleteReward = async () => {
    await deleteReward(this.state.rewardState.id);
    this.props.history.push("/dashboard/reward?data=r");
  };

  handleSubmit = (type?: string) => {
    let typeList: string[] = [
      "name",
      "validity",
      "description",
      "oneliner",
      "importance",
      "category",
      "tag",
      "value",
      "website",
    ];

    if (this.state.creation) {
      typeList.push("rewardLogo");
      typeList.push("rewardBanner");
    }

    const clonedRewardError = JSON.parse(
      JSON.stringify(this.state.rewardStateError)
    );
    handleRewardCondition(this.state.rewardState, clonedRewardError, typeList);

    this.setState(
      {
        rewardStateError: clonedRewardError,
      },
      async () => {
        const logoCreationError =
          this.state.creation && this.state.rewardStateError.logoTokenError;
        const bannerCreationError =
          this.state.creation && this.state.rewardStateError.bannerTokenError;
        if (
          !logoCreationError &&
          !bannerCreationError &&
          !this.state.rewardStateError.nameError &&
          !this.state.rewardStateError.descriptionError &&
          !this.state.rewardStateError.validityError &&
          !this.state.rewardStateError.onelinerError &&
          !this.state.rewardStateError.rewardLogoError &&
          !this.state.rewardStateError.rewardBannerError &&
          !this.state.rewardStateError.categoryError &&
          !this.state.rewardStateError.tagError &&
          !this.state.rewardStateError.importanceError &&
          !this.state.rewardStateError.valueError &&
          !this.state.rewardStateError.websiteError
        ) {
          this.handleLoading(true);

          let creationError = "";
          if (this.state.creation) {
            creationError = await createReward(this.state.rewardState);
          } else {
            creationError = await updateReward(this.state.rewardState);
          }
          if (creationError) {
            const clonedRewardError = JSON.parse(
              JSON.stringify(this.state.rewardStateError)
            );
            clonedRewardError["nameError"] = creationError;
            this.setState({
              rewardStateError: clonedRewardError,
            });
            this.handleLoading(false);
          } else {
            this.props.history.push("/dashboard/reward?data=r");
          }
        }
      }
    );
  };

  renderBreadcrumb = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    return (
      <nav
        className="bg-white border-b border-gray-200 flex mt-0.5 mx-auto w-full"
        aria-label="Breadcrumb"
      >
        <ol className="max-w-6xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8 ">
          <li className="flex">
            <div className="flex items-center">
              <p
                className="text-gray-600 hover:text-arusgreen-500 cursor-pointer"
                onClick={() => {
                  this.props.history.push("/dashboard/reward");
                }}
              >
                <HomeIcon className="flex-shrink-0 h-5 w-5" />
              </p>
            </div>
          </li>
          {selectedId === "new"
            ? this.renderBreadcrumbItem(`Add A Reward`)
            : this.renderBreadcrumbItem(`Reward Details`)}
        </ol>
      </nav>
    );
  };

  renderBreadcrumbItem = (title: String) => {
    return (
      <li className="flex">
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <p className="ml-4 text-sm font-medium text-gray-500">{title}</p>
        </div>
      </li>
    );
  };

  renderTitle = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");
    if (selectedId === "new") {
      return "Add A Reward";
    }
  };

  renderContent = () => {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    let defaultWebUrl =
      "https://firebasestorage.googleapis.com/v0/b/arusoil-web-dev.appspot.com/o/rewards%2F";
    if (isProd) {
      defaultWebUrl =
        "https://firebasestorage.googleapis.com/v0/b/arusoil-web.appspot.com/o/rewards%2F";
    }
    const searchParams = new URLSearchParams(window.location.search);
    const selectedId = searchParams.get("data");

    return (
      <div className="bg-white shadow-xl grid grid-cols-1 lg:grid-cols-2">
        <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
          <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Reward Logo
              </label>
              {selectedId === "new" ? (
                <UploadInput
                  accept=".png,.jpg,.jpeg"
                  onChange={this.handleUploadChange.bind(this, "rewardLogo")}
                  file={this.state.rewardState.rewardLogo}
                  error={this.state.rewardStateError.rewardLogoError}
                />
              ) : (
                <img
                  className="mt-2 h-20 w-30 object-contain"
                  src={`${defaultWebUrl}${this.state.rewardState.id}%2Flogo.jpg?alt=media&token=${this.state.rewardState.rewardLogo}`}
                  alt=""
                />
              )}
            </div>
            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Reward Banner
              </label>
              {selectedId === "new" ? (
                <UploadInput
                  accept=".png,.jpg,.jpeg"
                  onChange={this.handleUploadChange.bind(this, "rewardBanner")}
                  file={this.state.rewardState.rewardBanner}
                  error={this.state.rewardStateError.rewardBannerError}
                />
              ) : (
                <img
                  className="mt-2 h-30 w-42 object-contain"
                  src={`${defaultWebUrl}${this.state.rewardState.id}%2Fbanner.jpg?alt=media&token=${this.state.rewardState.rewardBanner}`}
                  alt=""
                />
              )}
            </div>
            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Reward Name
              </label>
              <Input
                id="name"
                className="mt-1"
                placeholder="Name"
                value={this.state.rewardState.name}
                error={this.state.rewardStateError.nameError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Reward Description
              </label>
              <Input
                id="description"
                className="mt-1"
                placeholder="Description"
                value={this.state.rewardState.description}
                error={this.state.rewardStateError.descriptionError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Reward Validity
              </label>
              <Input
                id="validity"
                className="mt-1"
                placeholder="Valid for 6 months"
                value={this.state.rewardState.validity.toString()}
                error={this.state.rewardStateError.validityError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                One Liner
              </label>
              <Input
                id="oneliner"
                className="mt-1"
                placeholder="One sentence of description"
                value={this.state.rewardState.oneliner}
                error={this.state.rewardStateError.onelinerError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Importance
              </label>
              <Input
                id="importance"
                className="mt-1"
                placeholder="Importance"
                value={this.state.rewardState.importance.toString()}
                error={this.state.rewardStateError.importanceError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Value (RM)
              </label>
              <Input
                id="value"
                className="mt-1"
                placeholder="Value"
                type="number"
                value={this.state.rewardState.value.toString()}
                error={this.state.rewardStateError.valueError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Category
              </label>
              <Input
                id="category"
                className="mt-1"
                placeholder="Reward Category"
                value={this.state.rewardState.category}
                error={this.state.rewardStateError.categoryError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Tag
              </label>
              <Input
                id="tag"
                className="mt-1"
                placeholder="Reward Tag"
                value={this.state.rewardState.tag}
                error={this.state.rewardStateError.tagError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                More Details - Website
              </label>
              <Input
                id="website"
                className="mt-1"
                placeholder="www.arusoil.com"
                value={this.state.rewardState.website}
                error={this.state.rewardStateError.websiteError}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="sm:col-span-2 sm:flex sm:justify-end mt-5">
              <Button
                className="w-full sm:w-auto"
                text={this.state.creation ? "Create" : "Update"}
                type="normal"
                onClick={this.handleSubmit}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <Overlay
          loading={this.state.loading}
          text={this.state.creation ? "Creating Reward" : "Updating Reward"}
        />
        <ConfirmationModal
          onClose={this.props.handleOpenConfirmationModal}
          title="Delete Reward Confirmation"
          open={this.props.confirmationModalVisible}
          content="Are you sure that you want delete this reward? "
          onClick={this.handleDeleteReward}
        />
        {this.renderBreadcrumb()}
        <div className="mt-8">
          <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
            {this.renderTitle()}
          </h2>

          <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
            {this.renderContent()}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectedReward: (selectedReward: Reward | null) =>
      dispatch(updateSelectedReward(selectedReward)),
  };
};

export default connect(null, mapDispatchToProps)(RewardEditor);
