import {
  Dialog,
  DialogBackdrop,
  DialogTitle,
  Transition,
} from "@headlessui/react";
import React, { Component, Fragment } from "react";
import { PromotionApplied, UserModel } from "../../models/User";
import Button from "../base/Button";
import Input from "../base/Input";
import {
  handleAddGenericPromoCode,
  handlePromotionIdCondition,
} from "../store/actions/promotionActions";

interface Props {
  open: boolean;
  selectedUser: UserModel;
  selectedUserPromotion: PromotionApplied[];
  handleGetSelectedUser: () => void;
  onClose: (visible: boolean) => void;
}

interface State {
  promotionCode: string;
  promotionCodeError: string;
  loading: boolean;
}

export default class PromoModal extends Component<Props> {
  state: State = {
    promotionCode: "",
    promotionCodeError: "",
    loading: false,
  };

  handleCloseModal = (force?: boolean) => {
    if (force || !this.state.loading) {
      this.setState({
        promotionCode: "",
        promotionCodeError: "",
      });
      this.props.onClose(false);
    }
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleInputChangePromotion = (e: any) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmitPromoCode = async () => {
    this.handleLoading(true);
    let promotionError = handlePromotionIdCondition(this.state.promotionCode);
    if (promotionError) {
      this.setState({
        promotionCodeError: promotionError,
      });
    } else {
      const promotionList = this.props.selectedUserPromotion;
      let promoCodeError = await handleAddGenericPromoCode(
        this.props.selectedUser.id,
        this.state.promotionCode,
        promotionList
      );
      if (promoCodeError) {
        this.setState({ promotionCodeError: promoCodeError });
      } else {
        this.handleCloseModal(true);
        this.props.handleGetSelectedUser();
      }
    }
    this.handleLoading(false);
  };

  render() {
    return (
      <Transition.Root show={this.props.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.props.open}
          onClose={() => {}}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full align-bottom bg-white rounded-lg px-4 pt-5 pb-10 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:p-6">
                <div>
                  <div className=" border-b border-gray-200 pb-2 mb-4">
                    <DialogTitle
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      New Promo Code
                    </DialogTitle>
                  </div>
                  <div className="mt-2">
                    <Input
                      id="promotionCode"
                      className="mt-1"
                      placeholder="New Promotion Code"
                      value={this.state.promotionCode}
                      error={this.state.promotionCodeError}
                      onChange={this.handleInputChangePromotion}
                    />
                  </div>
                  <div className="mt-5 flex justify-end">
                    <Button
                      className="w-full sm:w-auto mr-4"
                      text="Cancel"
                      type="light"
                      onClick={this.handleCloseModal}
                    />
                    <Button
                      className="w-full sm:w-auto"
                      text="Apply"
                      type="normal"
                      onClick={this.handleSubmitPromoCode}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
