import Admin from "../../../models/Admin";

export interface adminStateInterface {
  admins: Admin[];
  selectedAdmin: {
    id: string;
  } | null;
  lastCursor: string;
  loading: boolean;
}

const initState: adminStateInterface = {
  admins: [],
  selectedAdmin: {
    id: "",
  },
  lastCursor: "",
  loading: false,
};

const adminReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_ADMIN_LIST":
      return {
        ...state,
        admins: action.payload.admins,
        lastCursor: action.payload.lastCursor,
      };
    case "UPDATE_SELECTED_ADMIN":
      return {
        ...state,
        selectedAdmin: action.payload.selectedAdmin,
      };
    case "UPDATE_ADMIN_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default adminReducer;
