import { Option, ReadQuery, Select } from "blixify-ui-web/lib";
import firebase from "firebase";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TransactionType } from "../../models/Transaction";
import { UserModel } from "../../models/User";
import Table, { TableHeader } from "../base/Table";
import { clearSchedule } from "../store/actions/schedulesAction";
import {
  clearTransaction,
  getTransactionWithPagination,
} from "../store/actions/transactionAction";
import { clearUsers, userDataLimit } from "../store/actions/usersActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { scheduleStateInterface } from "../store/reducers/scheduleReducer";
import { transactionStateInterface } from "../store/reducers/transactionReducer";
import { userStateInterface } from "../store/reducers/usersReducer";

enum ButtonGroupType {
  "DEBIT" = "Enter user's account",
  "CREDIT" = "Leave user's account",
}

interface Props {
  history: any;
  selectedButton: keyof typeof ButtonGroupType;
  selectedUser: string;
  authStore: authStateInterface;
  pageIndex: number;
  userStore: userStateInterface;
  transactionStore: transactionStateInterface;
  scheduleStore: scheduleStateInterface;
  getTransactionWithPagination: (
    paginationStartAt: string | Date,
    paginationType: keyof typeof TransactionType,
    selectedUser: string
  ) => void;
  clearTransaction: () => void;
  clearSchedule: () => void;
  clearUsers: () => void;
  handleUpdateSelectedUser: (value: string) => void;
  handleUpdatePageIndex: (pageIndex: number) => void;
  handleUpdateSelectedButton: (
    selectedButton: keyof typeof ButtonGroupType
  ) => void;
}

interface State {
  userOptions: Option[];
  pageIndex: number;
  searchText: string;
}

class Users extends Component<Props> {
  userQuery = new ReadQuery({
    dbModule: firebase,
    dbType: "firebase",
    dbCollection: "users",
  });
  searchTimeout: ReturnType<typeof setTimeout> | null = null;
  state: State = {
    userOptions: [],
    pageIndex: 1,
    searchText: "",
  };

  componentDidMount = () => {
    this.handleSearchUser("");
  };

  handleSearchUser = async (searchText: string) => {
    try {
      const userRes = await this.userQuery.call({
        type: "list",
        query: [
          {
            type: "search",
            searchIds: searchText.includes("+") ? ["mobileNo"] : ["email"],
            value: searchText,
          },
        ],
        limit: 10,
      });
      const userOptions: Option[] = [];
      if (userRes?.data) {
        userRes.data.data.map((eachData: UserModel) => {
          userOptions.push({
            key: eachData.id,
            label: eachData.email ? eachData.email : eachData.mobileNo,
          });
          return null;
        });
      }
      this.setState({
        userOptions,
      });
    } catch (err) {}
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      //INFO : Check whether need to get new data from firebase
      if (
        this.props.transactionStore.lastCursor &&
        this.props.pageIndex * userDataLimit + 1 >
          this.props.transactionStore.transactions.length
      ) {
        this.props.getTransactionWithPagination(
          this.props.transactionStore.lastCursor,
          this.props.selectedButton,
          this.props.selectedUser
        );
      }
      this.props.handleUpdatePageIndex(this.props.pageIndex + 1);
    } else {
      this.props.handleUpdatePageIndex(this.props.pageIndex - 1);
    }
  };

  handleSelectedData = (id: string) => {
    this.props.history.push(`/dashboard/transactionEditor?data=${id}`);
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (this.props.transactionStore.transactions?.length > 0) {
      this.props.transactionStore.transactions.map((eachTransaction) => {
        let selectedEmail = "-";
        let weightCollected = "-";

        this.props.userStore.users.map((eachUser) => {
          if (eachUser.id === eachTransaction.userId) {
            selectedEmail = eachUser.email ? eachUser.email : eachUser.mobileNo;
          }
          return "";
        });
        this.props.scheduleStore.schedules.map((eachSchedule) => {
          if (eachSchedule.id === eachTransaction.scheduleId) {
            weightCollected =
              eachSchedule.processes[eachSchedule.processes.length - 1]
                .description ?? "-";
          }
          return "";
        });

        listView.push({
          id: eachTransaction.id,
          transactionId: eachTransaction.id,
          email: selectedEmail,
          weightCollected: weightCollected,
          amount: eachTransaction.amount,
          date: moment(eachTransaction.date.seconds * 1000).format("DD-MM-YY"),
        });
        return null;
      });
    }
    return listView;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "transactionId",
        title: "Transaction Id",
      },
      {
        key: "email",
        title: "Email",
      },
      {
        key: "amount",
        title: "Amount(RM)",
      },
      {
        key: "weightCollected",
        title: "Weight Collected(kg)",
      },
      {
        key: "date",
        title: "Date",
      },
    ];

    const selectedButtonGroup =
      "z-10 ring-1 ring-arusgreen-500 border-arusgreen-500";
    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-row items-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Transactions
            </h3>
            <div className="flex-1" />
            <div className="my-3 w-30 sm:mr-4 sm:my-0 sm:w-60">
              <Select
                value={this.props.selectedUser}
                label=""
                options={this.state.userOptions}
                onSearch={this.handleSearchUser}
                onChange={this.props.handleUpdateSelectedUser}
              />
            </div>
            <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <button
                type="button"
                className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none ${
                  this.props.selectedButton === "DEBIT"
                    ? selectedButtonGroup
                    : ""
                }`}
                onClick={this.props.handleUpdateSelectedButton.bind(
                  this,
                  "DEBIT"
                )}
              >
                Debit
              </button>
              <button
                type="button"
                className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none ${
                  this.props.selectedButton === "CREDIT"
                    ? selectedButtonGroup
                    : ""
                }`}
                onClick={this.props.handleUpdateSelectedButton.bind(
                  this,
                  "CREDIT"
                )}
              >
                Credit
              </button>
            </span>
          </div>
        </div>

        <Table
          loading={this.props.userStore.loading}
          header={headers}
          pageIndex={this.props.pageIndex}
          lastCursor={this.props.transactionStore.lastCursor}
          data={this.renderFilteredData()}
          dataLimit={userDataLimit}
          handlePage={this.handlePagination}
          onClickRow={this.handleSelectedData}
          newTabUrl="/dashboard/transactionEditor?data="
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    userStore: state.userStore,
    transactionStore: state.transactionStore,
    scheduleStore: state.scheduleStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getTransactionWithPagination: (
      paginationStartAt: string | Date,
      paginationType: keyof typeof TransactionType,
      selectedUser: string
    ) =>
      dispatch(
        getTransactionWithPagination(
          paginationStartAt,
          paginationType,
          selectedUser
        )
      ),
    clearTransaction: () => dispatch(clearTransaction()),
    clearUsers: () => dispatch(clearUsers()),
    clearSchedule: () => dispatch(clearSchedule()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
