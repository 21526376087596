import { CheckIcon } from "@heroicons/react/24/solid";
import React, { Component } from "react";
import { UserModel } from "../../models/User";
import { getUserName, getUserSuggestions } from "../store/actions/usersActions";
import { Option } from "./Select";

interface Props {
  id: string;
  title?: string;
  value?: string;
  placeholder?: string;
  className?: string;
  error?: string;
  onChange: (id: string, e: string) => void;
}

interface State {
  valuePlaceholder: string;
  onFocus: boolean;
  options: Option[];
}

export default class SmartSelect extends Component<Props> {
  typingTimeout: ReturnType<typeof setTimeout> | undefined;

  state: State = {
    valuePlaceholder: "",
    onFocus: false,
    options: [],
  };

  componentDidMount = () => {
    this.handleGetSuggestions();
  };

  componentDidUpdate = async (prevProps: Props) => {
    if (this.props.value !== prevProps.value) {
      this.handleGetPreviewData();
    }
  };

  handleGetPreviewData = async () => {
    let valuePlaceholder = "";
    if (this.props.value) {
      switch (this.props.id) {
        case "email":
          valuePlaceholder = await getUserName(this.props.value);
          break;
      }
      this.setState({
        valuePlaceholder,
      });
    } else {
      this.setState({
        valuePlaceholder: "",
      });
    }
  };

  handleGetSuggestions = async () => {
    const options: Option[] = [];
    switch (this.props.id) {
      case "email":
        const userData = await getUserSuggestions(this.state.valuePlaceholder);
        if (userData.length > 0) {
          userData.map((eachUser: UserModel) => {
            options.push({
              key: eachUser.id,
              title: eachUser.email ? eachUser.email : eachUser.mobileNo,
            });
            return null;
          });
        }
        break;
    }

    this.setState({
      options,
    });
  };

  handleOnChange = (e: any) => {
    this.setState({
      valuePlaceholder: e.target.value,
    });

    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }

    this.typingTimeout = setTimeout(() => {
      this.handleGetSuggestions();
    }, 250);
  };

  handleBlur = () => {
    this.setState(
      {
        onFocus: false,
        valuePlaceholder: "",
      },
      () => {
        this.handleGetPreviewData();
      }
    );
  };

  handleFocus = () => {
    this.setState({
      onFocus: true,
      valuePlaceholder: "",
    });
  };

  renderOptions = () => {
    let optionView: any = [];
    this.state.options.map((eachOption) => {
      optionView.push(
        <li
          key={eachOption.key}
          className="text-gray-900 cursor-default select-none relative px-5 py-3 hover:bg-arusgreen-300 group"
          onMouseDown={this.props.onChange.bind(
            this,
            this.props.id,
            eachOption.key === this.props.value ? "" : eachOption.key
          )}
        >
          <span className="font-normal block truncate group-hover:text-white">
            {eachOption.title}
          </span>
          {this.props.value === eachOption.key && (
            <span className="text-arusgreen-600 absolute inset-y-0 right-0 flex items-center pr-4 group-hover:text-white">
              <CheckIcon className="h-5 w-5" />
            </span>
          )}
        </li>
      );
      return null;
    });

    return optionView;
  };

  render() {
    let defaultClassName =
      "block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 focus:border-arusgreen-500 focus:outline-none";
    if (this.props.className) {
      defaultClassName += ` ${this.props.className}`;
    }
    if (this.props.error) {
      defaultClassName += " mb-0.5 border-red-300";
    }

    return (
      <div className="w-full">
        <div className="relative">
          <div className="flex justify-between">
            <label className="block text-sm font-medium text-gray-700">
              {this.props.title}
            </label>
          </div>
          <label className="sr-only">{this.props.title}</label>
          <div>
            <input
              id={this.props.id}
              className={defaultClassName}
              value={this.state.valuePlaceholder}
              placeholder={this.props.placeholder}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.handleOnChange}
            />
            {this.state.onFocus && (
              <ul className="absolute z-10 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {this.renderOptions()}
              </ul>
            )}
          </div>

          <span className="text-red-500 text-sm">{this.props.error}</span>
        </div>
      </div>
    );
  }
}
