import React, { Component } from "react";
import { connect } from "react-redux";
import Input from "../base/Input";
import Table, { TableHeader } from "../base/Table";
import { promotionDataLimit } from "../store/actions/promotionActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { promotionStateInterface } from "../store/reducers/promotionReducer";

interface Props {
  pageIndex: number;
  history: any;
  searchText: string;
  authStore: authStateInterface;
  promotionStore: promotionStateInterface;
  getPromotionWithPagination: (
    paginationStartAt: string,
    paginationSearch: string
  ) => void;
  handleUpdatePageIndex: (pageIndex: number) => void;
  handleFilter: (e: any) => void;
}

class Promotion extends Component<Props> {
  handleSelectedData = (id: string) => {
    this.props.history.push(`/dashboard/promotionEditor?data=${id}`);
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      if (
        this.props.promotionStore.lastCursor &&
        this.props.pageIndex * promotionDataLimit + 1 >
          this.props.promotionStore.promotions.length
      ) {
        this.props.getPromotionWithPagination(
          this.props.promotionStore.lastCursor,
          ""
        );
      }

      this.props.handleUpdatePageIndex(this.props.pageIndex + 1);
    } else {
      this.props.handleUpdatePageIndex(this.props.pageIndex - 1);
    }
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (this.props.promotionStore.promotions?.length > 0) {
      this.props.promotionStore.promotions.map((eachPromotion) => {
        listView.push({
          id: eachPromotion.id,
          type: eachPromotion.type,
          promotionCode: eachPromotion.id,
          increaseRate:
            eachPromotion.type === "FLAT"
              ? eachPromotion.fixedPriceRate
              : eachPromotion.increasePercentRate,
          durationAppliedInMonths: eachPromotion.durationAppliedInMonths,
          redeemptionLimit: eachPromotion.genericStats?.redemptionLimit,
        });
        return "";
      });
    }
    return listView;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "promotionCode",
        title: "Promotion Code",
      },
      {
        key: "type",
        title: "Type",
      },
      {
        key: "increaseRate",
        title: "Increase Percent/Fixed Rate",
      },
      {
        key: "durationAppliedInMonths",
        title: "Duration Applied",
      },
      {
        key: "redeemptionLimit",
        title: "Redeemption Limit",
      },
    ];

    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto my-4 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Promotion List
            </h3>
            <div className="ml-4 w-60 sm:w-80">
              <Input
                id="id"
                placeholder="Search Promotion Code"
                value={this.props.searchText}
                onChange={this.props.handleFilter}
              />
            </div>
          </div>
        </div>

        <Table
          dataLimit={promotionDataLimit}
          loading={this.props.promotionStore.loading}
          header={headers}
          pageIndex={this.props.pageIndex}
          lastCursor={this.props.promotionStore.lastCursor}
          data={this.renderFilteredData()}
          onClickRow={this.handleSelectedData}
          handlePage={this.handlePagination}
          newTabUrl="/dashboard/promotionEditor?data="
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    promotionStore: state.promotionStore,
  };
};

export default connect(mapStateToProps)(Promotion);
