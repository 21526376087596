import Reward from "../../../models/Reward";

export interface rewardStateInterface {
  reward: Reward[];
  lastCursor: string | Date;
  loading: boolean;
  selectedReward: Reward | null;
}

const initState: rewardStateInterface = {
  reward: [],
  lastCursor: "",
  loading: false,
  selectedReward: null,
};

const rewardReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_REWARD_LIST":
      return {
        ...state,
        reward: action.payload.reward,
        lastCursor: action.payload.lastCursor,
      };
    case "UPDATE_REWARD_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "UPDATE_SELECTED_REWARD":
      return {
        ...state,
        selectedReward: action.payload.selectedReward,
      };
    default:
      return state;
  }
};

export default rewardReducer;
