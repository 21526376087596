export enum PromotionType {
  GENERIC = "Generic",
  FLAT = "Flat",
}

export interface GenericStats {
  redemptionLimit: number;
  redemptionCounter: number;
}

interface UniqueStats {
  redeemed: boolean;
}

export default interface PromotionModel {
  id: string;
  type: keyof typeof PromotionType;
  increasePercentRate?: number;
  fixedPriceRate?: number;
  expiryDate?: Date;
  genericStats?: GenericStats;
  uniqueStats?: UniqueStats;
  durationAppliedInMonths?: number;
  createdAt: Date;
}
