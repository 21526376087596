import moment from "moment";
import PromotionModel from "../../../models/Promotion";

export interface promotionStateInterface {
  promotions: PromotionModel[];
  selectedPromotion: PromotionModel;
  lastCursor: string;
  loading: boolean;
}

const initState: promotionStateInterface = {
  promotions: [],
  selectedPromotion: {
    id: "",
    type: "GENERIC",
    increasePercentRate: 0,
    expiryDate: moment().toDate(),
    durationAppliedInMonths: 0,
    createdAt: moment().toDate(),
  },
  lastCursor: "",
  loading: false,
};

const promotionReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_PROMO_LIST":
      return {
        ...state,
        promotions: action.payload.promotions,
        lastCursor: action.payload.lastCursor,
      };
    case "UPDATE_SELECTED_PROMO":
      return {
        ...state,
        selectedPromotion: action.payload.selectedPromotion,
      };
    case "UPDATE_PROMO_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default promotionReducer;
