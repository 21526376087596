import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import AdminController from "../components/controller/AdminController";
import JobController from "../components/controller/JobController";
import NgoController from "../components/controller/NgoController";
import OperationController from "../components/controller/OperationController";
import PendingTransactionController from "../components/controller/PendingTransactionController";
import PromotionController from "../components/controller/PromotionController";
import RewardController from "../components/controller/RewardController";
import ScheduleController from "../components/controller/ScheduleController";
import TransactionController from "../components/controller/TransactionController";
import UserController from "../components/controller/UserController";
import Greeting from "../components/dashboard/Greetings";
import Header from "../components/dashboard/Header";
import Home from "../components/dashboard/Home";
import LeftNav, { DashboardPage } from "../components/dashboard/LeftNav";
import { getSchedule } from "../components/store/actions/schedulesAction";
import { updateTransactionTrigger } from "../components/store/actions/transactionAction";
import { adminStateInterface } from "../components/store/reducers/adminsReducer";
import { authStateInterface } from "../components/store/reducers/authReducer";
import { ngoStateInterface } from "../components/store/reducers/ngoReducer";
import { pendTransactionStateInterface } from "../components/store/reducers/pendTransactionReducer";
import { promotionStateInterface } from "../components/store/reducers/promotionReducer";
import { rewardStateInterface } from "../components/store/reducers/rewardReducer";
import { scheduleStateInterface } from "../components/store/reducers/scheduleReducer";
import { transactionStateInterface } from "../components/store/reducers/transactionReducer";
import { userStateInterface } from "../components/store/reducers/usersReducer";
import SecurityNavigator from "../navigator/SecurityNavigator";

interface Props {
  history: any;
  location: any;
  authStore: authStateInterface;
  userStore: userStateInterface;
  promotionStore: promotionStateInterface;
  transactionStore: transactionStateInterface;
  scheduleStore: scheduleStateInterface;
  pendTransactionStore: pendTransactionStateInterface;
  ngoStore: ngoStateInterface;
  rewardStore: rewardStateInterface;
  adminStore: adminStateInterface;
  updateTransactionTrigger: (trigger: boolean) => void;
}

interface State {
  leftNavMobileMenu: boolean;
  page: DashboardPage;
  updateScheduleModalVisible: boolean;
  updateScheduleModalType: string;
  confirmationModalVisible: boolean;
  inventoryModalVisible: boolean;
  pendTransactionLoading: boolean;
}

class Dashboard extends Component<Props> {
  private containerRef = createRef<HTMLCanvasElement>();

  state: State = {
    leftNavMobileMenu: false,
    page: DashboardPage.HOME,
    updateScheduleModalVisible: false,
    updateScheduleModalType: "",
    pendTransactionLoading: false,
    confirmationModalVisible: false,
    inventoryModalVisible: false,
  };

  componentDidMount() {
    this.handlePageNavigation();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handlePageNavigation();
    }
  }

  handlePageNavigation = () => {
    const path = window.location.pathname;
    let page: DashboardPage = DashboardPage.HOME;
    if (path.includes("dashboard/users")) {
      page = DashboardPage.USERS;
    } else if (path.includes("dashboard/userEditor")) {
      page = DashboardPage.USER_EDITOR;
    } else if (path.includes("dashboard/schedules")) {
      page = DashboardPage.SCHEDULES;
    } else if (path.includes("dashboard/scheduleEditor")) {
      page = DashboardPage.SCHEDULE_EDITOR;
    } else if (path.includes("dashboard/promotionEditor")) {
      page = DashboardPage.PROMO_EDITOR;
    } else if (path.includes("dashboard/promotion")) {
      page = DashboardPage.PROMOTION;
    } else if (path.includes("dashboard/transactionEditor")) {
      page = DashboardPage.TRANS_EDITOR;
    } else if (path.includes("dashboard/transaction")) {
      page = DashboardPage.TRANSACTION;
    } else if (path.includes("dashboard/pendTransactionEditor")) {
      page = DashboardPage.PEND_TRANSACTION_EDITOR;
    } else if (path.includes("dashboard/pendTransactions")) {
      page = DashboardPage.PEND_TRANSACTION;
    } else if (path.includes("dashboard/ngoEditor")) {
      page = DashboardPage.NGO_EDITOR;
    } else if (path.includes("dashboard/ngos")) {
      page = DashboardPage.NGOS;
    } else if (path.includes("dashboard/rewardEditor")) {
      page = DashboardPage.REWARDEDITOR;
    } else if (path.includes("dashboard/reward")) {
      page = DashboardPage.REWARD;
    } else if (path.includes("dashboard/operationEditor")) {
      page = DashboardPage.OPERATIONEDITOR;
    } else if (path.includes("dashboard/operation")) {
      page = DashboardPage.OPERATION;
    } else if (path.includes("dashboard/admins")) {
      page = DashboardPage.ADMINS;
    } else if (path.includes("dashboard/adminEditor")) {
      page = DashboardPage.ADMIN_EDITOR;
    } else if (path.includes("dashboard/jobs")) {
      page = DashboardPage.JOBS;
    } else if (path.includes("dashboard/jobEditor")) {
      page = DashboardPage.JOBS_EDITOR;
    } else if (path.includes("dashboard/receipt")) {
      page = DashboardPage.RECEIPT;
    } else if (path.includes("dashboard/createSchedule")) {
      page = DashboardPage.CREATE_SCHEDULE;
    }

    this.setState({
      page,
    });
  };

  handleToggleLeftNavMobileMenu = () => {
    this.setState({
      leftNavMobileMenu: !this.state.leftNavMobileMenu,
    });
  };

  handlePayUser = () => {
    this.props.updateTransactionTrigger(true);
    setTimeout(() => {
      this.props.updateTransactionTrigger(false);
    }, 1000);
  };

  handleOpenScheduleUpdateModal = (visible: boolean, type?: string) => {
    this.setState({
      updateScheduleModalVisible: visible,
      updateScheduleModalType: type,
    });
  };

  handleOpenInventoryVisible = (visible: boolean) => {
    this.setState({
      inventoryModalVisible: visible,
    });
  };

  handleOpenConfirmationModal = (visible: boolean) => {
    this.setState({
      confirmationModalVisible: visible,
    });
  };

  handleManageReceipt = async (type: string) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedId = searchParams.get("data");
      let receiptDate = "";
      if (selectedId) {
        const scheduleData = await getSchedule(selectedId);
        if (typeof scheduleData !== "string") {
          receiptDate = moment(
            scheduleData.schedule.updatedAt.seconds * 1000
          ).format("DD-MM-YY");
        }
      }

      if (type === "share") {
        if (this.containerRef.current) {
          const pdf = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: [297, 210],
          });
          let canvas = await html2canvas(this.containerRef.current, {
            scale: 10,
          });
          const imgData = canvas.toDataURL("image/jpeg");
          const x = 10;
          const y = 10;
          const width = pdf.internal.pageSize.getWidth() - 20;
          const height = pdf.internal.pageSize.getHeight() - 20;
          pdf.addImage(imgData, "JPEG", x, y, width, height);
          const pdfBlob = await pdf.output("blob");
          const pdfFile = new File([pdfBlob], `${receiptDate}-receipt.pdf`, {
            type: "application/pdf",
          });
          const shareData = {
            title: "Share PDF",
            text: "Check out this PDF!",
            files: [pdfFile],
          };
          if (navigator.share) {
            await navigator.share(shareData);
          } else {
            window.alert("Unable to share file. Please try again.");
          }
        }
      } else {
        if (this.containerRef.current) {
          //INFO:  Set the orientation to landscape
          const pdf = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: [297, 210],
          });
          let canvas = await html2canvas(this.containerRef.current, {
            scale: 10,
          });
          const imgData = canvas.toDataURL("image/jpeg");
          //INFO:  Define the position and dimensions of the image within the PDF
          const x = 10;
          const y = 10;
          const width = pdf.internal.pageSize.getWidth() - 20;
          const height = pdf.internal.pageSize.getHeight() - 20;
          //INFO: Add the canvas image to the PDF
          pdf.addImage(imgData, "JPEG", x, y, width, height);
          pdf.save(`${receiptDate}-receipt.pdf`);
        }
      }
    } catch (err) {}
  };

  renderContent = () => {
    switch (this.state.page) {
      case DashboardPage.HOME:
        return <Home />;
      case DashboardPage.USERS:
      case DashboardPage.USER_EDITOR:
        return (
          <UserController
            history={this.props.history}
            pathName={this.props.location.pathname}
            confirmationModalVisible={this.state.confirmationModalVisible}
            inventoryModalVisible={this.state.inventoryModalVisible}
            handleOpenConfirmationModal={this.handleOpenConfirmationModal}
            handleOpenInventoryVisible={this.handleOpenInventoryVisible}
          />
        );
      case DashboardPage.SCHEDULES:
      case DashboardPage.SCHEDULE_EDITOR:
      case DashboardPage.RECEIPT:
      case DashboardPage.CREATE_SCHEDULE:
        return (
          <ScheduleController
            authStore={this.props.authStore}
            history={this.props.history}
            updateScheduleModalVisible={this.state.updateScheduleModalVisible}
            updateScheduleModalType={this.state.updateScheduleModalType}
            pathName={this.props.location.pathname}
            handleOpenScheduleUpdateModal={this.handleOpenScheduleUpdateModal}
            receiptContainerRef={this.containerRef}
          />
        );
      case DashboardPage.PROMOTION:
      case DashboardPage.PROMO_EDITOR:
        return (
          <PromotionController
            history={this.props.history}
            pathName={this.props.location.pathname}
            confirmationModalVisible={this.state.confirmationModalVisible}
            handleOpenConfirmationModal={this.handleOpenConfirmationModal}
          />
        );
      case DashboardPage.TRANSACTION:
      case DashboardPage.TRANS_EDITOR:
        return (
          <TransactionController
            history={this.props.history}
            trigger={this.props.transactionStore.trigger}
            pathName={this.props.location.pathname}
          />
        );
      case DashboardPage.PEND_TRANSACTION:
      case DashboardPage.PEND_TRANSACTION_EDITOR:
        return (
          <PendingTransactionController
            history={this.props.history}
            pathName={this.props.location.pathname}
            confirmationModalVisible={this.state.confirmationModalVisible}
            handleOpenConfirmationModal={this.handleOpenConfirmationModal}
          />
        );
      case DashboardPage.NGOS:
      case DashboardPage.NGO_EDITOR:
        return (
          <NgoController
            history={this.props.history}
            pathName={this.props.location.pathname}
            confirmationModalVisible={this.state.confirmationModalVisible}
            handleOpenConfirmationModal={this.handleOpenConfirmationModal}
          />
        );
      case DashboardPage.REWARD:
      case DashboardPage.REWARDEDITOR:
        return (
          <RewardController
            history={this.props.history}
            pathName={this.props.location.pathname}
            confirmationModalVisible={this.state.confirmationModalVisible}
            handleOpenConfirmationModal={this.handleOpenConfirmationModal}
          />
        );
      case DashboardPage.OPERATION:
      case DashboardPage.OPERATIONEDITOR:
        return (
          <OperationController
            history={this.props.history}
            pathName={this.props.location.pathname}
          />
        );
      case DashboardPage.ADMINS:
      case DashboardPage.ADMIN_EDITOR:
        return (
          <AdminController
            history={this.props.history}
            pathName={this.props.location.pathname}
            confirmationModalVisible={this.state.confirmationModalVisible}
            handleOpenConfirmationModal={this.handleOpenConfirmationModal}
          />
        );
      case DashboardPage.JOBS:
      case DashboardPage.JOBS_EDITOR:
        return (
          <JobController
            authStore={this.props.authStore}
            history={this.props.history}
            confirmationModalVisible={this.state.confirmationModalVisible}
            handleOpenConfirmationModal={this.handleOpenConfirmationModal}
            updateScheduleModalVisible={this.state.updateScheduleModalVisible}
            updateScheduleModalType={this.state.updateScheduleModalType}
            pathName={this.props.location.pathname}
            handleOpenScheduleUpdateModal={this.handleOpenScheduleUpdateModal}
          />
        );

      default:
        return null;
    }
  };

  renderExtraActionText = () => {
    if (
      this.state.page === DashboardPage.SCHEDULE_EDITOR &&
      this.props.scheduleStore.selectedSchedule.status === "COM"
    ) {
      return "Generate Receipt";
    } else if (this.state.page === DashboardPage.RECEIPT) {
      return "Share Receipt";
    } else if (
      this.state.page === DashboardPage.USER_EDITOR &&
      this.props.userStore.selectedUser.id &&
      !this.props.userStore.selectedUser.jerryCanRedeem
    ) {
      return "Update Inventory";
    }
  };

  renderExtraActionFunction = () => {
    if (
      this.state.page === DashboardPage.SCHEDULE_EDITOR &&
      this.props.scheduleStore.selectedSchedule.status === "COM"
    ) {
      const searchParams = new URLSearchParams(window.location.search);
      const selectedId = searchParams.get("data");
      return this.props.history.push(`/dashboard/receipt?data=${selectedId}`);
    } else if (this.state.page === DashboardPage.RECEIPT) {
      return this.handleManageReceipt("share");
    } else if (
      this.state.page === DashboardPage.USER_EDITOR &&
      this.props.userStore.selectedUser.id &&
      !this.props.userStore.selectedUser.jerryCanRedeem
    ) {
      return this.handleOpenInventoryVisible(true);
    }
  };

  renderCallToActionText = () => {
    if (this.state.page === DashboardPage.PROMOTION) {
      return "Add Promotion";
    } else if (
      this.state.page === DashboardPage.PROMO_EDITOR &&
      this.props.promotionStore.selectedPromotion.id
    ) {
      return "Delete Promotion";
    } else if (
      this.state.page === DashboardPage.SCHEDULE_EDITOR &&
      this.props.scheduleStore.selectedSchedule.id &&
      this.props.scheduleStore.selectedSchedule.status !== "CANCEL" &&
      this.props.scheduleStore.selectedSchedule.status !== "DEC" &&
      this.props.scheduleStore.selectedSchedule.status !== "COM"
    ) {
      return "Update Schedule";
    } else if (
      this.state.page === DashboardPage.SCHEDULE_EDITOR &&
      this.props.scheduleStore.selectedSchedule.id &&
      this.props.scheduleStore.selectedSchedule.status === "COM"
    ) {
      return "Update Sorting Date";
    } else if (
      this.state.page === DashboardPage.USER_EDITOR &&
      this.props.userStore.selectedUser.id &&
      !this.props.userStore.selectedUser.jerryCanRedeem
    ) {
      return "Jerry Can Redeemed";
    } else if (this.state.page === DashboardPage.TRANSACTION) {
      return "Credit Transaction";
    } else if (this.state.page === DashboardPage.TRANS_EDITOR) {
      if (
        this.props.transactionStore.selectedTransaction.user.email &&
        this.props.transactionStore.selectedTransaction.transaction.id &&
        this.props.transactionStore.selectedTransaction.transaction.type !==
          "CREDIT"
      ) {
        return "Pay User";
      }
    } else if (
      this.state.page === DashboardPage.ADMIN_EDITOR &&
      this.props.adminStore.selectedAdmin
    ) {
      // return "Delete Role";
    } else if (this.state.page === DashboardPage.PEND_TRANSACTION_EDITOR) {
      return "Pay User";
    } else if (this.state.page === DashboardPage.USERS) {
      return "Create User";
    } else if (this.state.page === DashboardPage.ADMINS) {
      return "Create Role";
    } else if (this.state.page === DashboardPage.SCHEDULES) {
      return "Create Schedule";
    } else if (this.state.page === DashboardPage.RECEIPT) {
      return "Download Receipt";
    } else if (this.state.page === DashboardPage.NGOS) {
      return "Add NGO";
    } else if (
      this.state.page === DashboardPage.NGO_EDITOR &&
      this.props.ngoStore.selectedNgo
    ) {
      return "Delete NGO";
    } else if (this.state.page === DashboardPage.REWARD) {
      return "Add Reward";
    } else if (
      this.state.page === DashboardPage.REWARDEDITOR &&
      this.props.rewardStore.selectedReward
    ) {
      return "Delete Reward";
    } else if (this.state.page === DashboardPage.JOBS_EDITOR) {
      if (
        this.props.authStore.user?.promo &&
        this.props.authStore.user?.promo ===
          this.props.scheduleStore.selectedSchedule.promoCode
      ) {
        if (this.props.scheduleStore.selectedSchedule.assignedAgentId) {
          if (
            this.props.scheduleStore.selectedSchedule.id &&
            this.props.scheduleStore.selectedSchedule.status !== "CANCEL" &&
            this.props.scheduleStore.selectedSchedule.status !== "DEC" &&
            this.props.scheduleStore.selectedSchedule.status !== "COM"
          ) {
            return "Update Schedule";
          }
        } else {
          return "Accept Job";
        }
      }
    }
  };

  renderCallToActionFunction = () => {
    if (this.state.page === DashboardPage.PROMOTION) {
      return this.props.history.push("/dashboard/promotionEditor?data=new");
    } else if (
      this.state.page === DashboardPage.PROMO_EDITOR &&
      this.props.promotionStore.selectedPromotion.id
    ) {
      return this.handleOpenConfirmationModal(true);
    } else if (
      this.state.page === DashboardPage.SCHEDULE_EDITOR &&
      this.props.scheduleStore.selectedSchedule.id &&
      this.props.scheduleStore.selectedSchedule.status !== "CANCEL" &&
      this.props.scheduleStore.selectedSchedule.status !== "DEC" &&
      this.props.scheduleStore.selectedSchedule.status !== "COM"
    ) {
      return this.handleOpenScheduleUpdateModal(true);
    } else if (
      this.state.page === DashboardPage.SCHEDULE_EDITOR &&
      this.props.scheduleStore.selectedSchedule.id &&
      this.props.scheduleStore.selectedSchedule.status === "COM"
    ) {
      return this.handleOpenScheduleUpdateModal(true, "updatedDate");
    } else if (this.state.page === DashboardPage.RECEIPT) {
      return this.handleManageReceipt("export");
    } else if (this.state.page === DashboardPage.TRANSACTION) {
      return this.props.history.push("/dashboard/transactionEditor?data=new");
    } else if (this.state.page === DashboardPage.TRANS_EDITOR) {
      this.handlePayUser();
    } else if (
      this.state.page === DashboardPage.USER_EDITOR &&
      this.props.userStore.selectedUser.id &&
      !this.props.userStore.selectedUser.jerryCanRedeem
    ) {
      return this.handleOpenConfirmationModal(true);
    } else if (this.state.page === DashboardPage.PEND_TRANSACTION_EDITOR) {
      return this.handleOpenConfirmationModal(true);
    } else if (this.state.page === DashboardPage.USERS) {
      return this.props.history.push("/dashboard/userEditor?data=new");
    } else if (
      this.state.page === DashboardPage.ADMIN_EDITOR &&
      this.props.adminStore.selectedAdmin
    ) {
      // return this.handleOpenConfirmationModal(true);
    } else if (this.state.page === DashboardPage.ADMINS) {
      return this.props.history.push("/dashboard/adminEditor?data=new");
    } else if (this.state.page === DashboardPage.SCHEDULES) {
      return this.props.history.push("/dashboard/scheduleEditor?data=new");
    } else if (this.state.page === DashboardPage.NGOS) {
      return this.props.history.push("/dashboard/ngoEditor?data=new");
    } else if (
      this.state.page === DashboardPage.NGO_EDITOR &&
      this.props.ngoStore.selectedNgo
    ) {
      return this.handleOpenConfirmationModal(true);
    } else if (this.state.page === DashboardPage.REWARD) {
      return this.props.history.push("/dashboard/rewardEditor?data=new");
    } else if (
      this.state.page === DashboardPage.REWARDEDITOR &&
      this.props.rewardStore.selectedReward
    ) {
      return this.handleOpenConfirmationModal(true);
    } else if (this.state.page === DashboardPage.JOBS_EDITOR) {
      if (
        this.props.authStore.user?.promo &&
        this.props.authStore.user?.promo ===
          this.props.scheduleStore.selectedSchedule.promoCode
      ) {
        if (this.props.scheduleStore.selectedSchedule.assignedAgentId) {
          if (
            this.props.scheduleStore.selectedSchedule.id &&
            this.props.scheduleStore.selectedSchedule.status !== "CANCEL" &&
            this.props.scheduleStore.selectedSchedule.status !== "DEC" &&
            this.props.scheduleStore.selectedSchedule.status !== "COM"
          ) {
            return this.handleOpenScheduleUpdateModal(true);
          }
        } else {
          return this.handleOpenConfirmationModal(true);
        }
      }
    }
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }
    return (
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <SecurityNavigator location={this.props.location} />
        <LeftNav
          leftNavMobileMenu={this.state.leftNavMobileMenu}
          handleToggleLeftNavMobileMenu={this.handleToggleLeftNavMobileMenu}
          page={this.state.page}
          authStore={this.props.authStore}
        />
        <div className="flex-1 overflow-auto focus:outline-none">
          <Header
            handleToggleLeftNavMobileMenu={this.handleToggleLeftNavMobileMenu}
          />
          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
            {this.state.page !== DashboardPage.OPERATION && (
              <Greeting
                extraActionText={this.renderExtraActionText()}
                extraActionFunction={this.renderExtraActionFunction}
                callToActionText={this.renderCallToActionText()}
                callToActionFunction={this.renderCallToActionFunction}
              />
            )}
            {this.props.authStore.userAuth &&
              this.props.authStore.user &&
              this.renderContent()}
          </main>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    userStore: state.userStore,
    transactionStore: state.transactionStore,
    promotionStore: state.promotionStore,
    scheduleStore: state.scheduleStore,
    ngoStore: state.ngoStore,
    rewardStore: state.rewardStore,
    adminStore: state.adminStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTransactionTrigger: (trigger: boolean) =>
      dispatch(updateTransactionTrigger(trigger)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
