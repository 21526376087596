import { Dialog, DialogBackdrop, Transition } from "@headlessui/react";
import React, { Component, Fragment } from "react";
import { ScheduleModel } from "../../models/Schedules";
import { Address, UserModel } from "../../models/User";
import AddressInput from "../base/AddressInput";
import Button from "../base/Button";
import {
  handleCheckAddressCondition,
  updateScheduleAddress,
} from "../store/actions/schedulesAction";

interface Props {
  open: boolean;
  selectedUser: UserModel;
  selectedSchedule: ScheduleModel;
  handleGetSelectedSchedule: () => void;
  onClose: (visible: boolean) => void;
}

interface State {
  address: Address;
  addressError: string;
  loading: boolean;
}

export default class AddressModal extends Component<Props> {
  state: State = {
    address: {
      name: "",
      lat: 0,
      lng: 0,
    },
    addressError: "",
    loading: false,
  };

  handleCloseModal = (force?: boolean) => {
    if (force || !this.state.loading) {
      this.setState({
        address: {
          name: "",
          lat: 0,
          lng: 0,
        },
        addressError: "",
      });
      this.props.onClose(false);
    }
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleAddressChange = (address: Address) => {
    this.setState({
      address: address,
    });
  };

  handleSubmitNewAddress = async () => {
    this.handleLoading(true);
    let newAddressError = handleCheckAddressCondition(this.state.address);
    if (newAddressError) {
      this.setState({
        addressError: newAddressError,
      });
    } else {
      let updateAddressError = await updateScheduleAddress(
        this.props.selectedSchedule.id,
        this.props.selectedUser.id,
        this.state.address
      );
      if (updateAddressError) {
        this.setState({
          addressError: updateAddressError,
        });
      } else {
        this.handleCloseModal(true);
        this.props.handleGetSelectedSchedule();
      }
    }
    this.handleLoading(false);
  };

  render() {
    return (
      <Transition.Root show={this.props.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.props.open}
          onClose={() => {}}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full align-bottom bg-white rounded-lg px-4 pt-5 pb-10 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:p-6">
                <div>
                  <div className=" border-b border-gray-200 pb-2 mb-4">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      New Address
                    </Dialog.Title>
                  </div>
                  <div className="mt-2">
                    <AddressInput
                      id="address"
                      className="mt-1"
                      value={this.state.address}
                      error={this.state.addressError}
                      onChange={this.handleAddressChange}
                    />
                  </div>
                  <div className="mt-5 flex justify-end">
                    <Button
                      className="w-full sm:w-auto mr-4"
                      text="Cancel"
                      type="light"
                      onClick={this.handleCloseModal}
                    />
                    <Button
                      className="w-full sm:w-auto"
                      text="Submit"
                      type="normal"
                      onClick={this.handleSubmitNewAddress}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
