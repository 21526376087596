import { UserCircleIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Favicon from "../../images/favicon.png";
import { AdminRole, States } from "../../models/Admin";
import Button from "../base/Button";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  authStore: authStateInterface;
  extraActionText?: string;
  callToActionText?: string;
  callToActionFunction?: (e: any) => void;
  extraActionFunction?: (e: any) => void;
}

enum GreetingTime {
  MORNING = "morning",
  AFTERNOON = "afternoon",
  EVENING = "evening",
  NIGHT = "night",
}

class Greeting extends Component<Props> {
  renderGreetingCopy = () => {
    let type = GreetingTime.MORNING;
    let text = "";
    let currentHour = Number(moment().format("HH"));

    if (currentHour >= 3 && currentHour < 12) {
      type = GreetingTime.MORNING;
    } else if (currentHour >= 12 && currentHour < 15) {
      type = GreetingTime.AFTERNOON;
    } else if (currentHour >= 15 && currentHour < 20) {
      type = GreetingTime.EVENING;
    } else if (currentHour >= 20 && currentHour < 3) {
      type = GreetingTime.NIGHT;
    }

    switch (type) {
      case GreetingTime.MORNING:
        text = "Good morning";
        break;
      case GreetingTime.AFTERNOON:
        text = "Good afternoon";
        break;
      case GreetingTime.EVENING:
        text = "Good evening";
        break;
      case GreetingTime.NIGHT:
        text = "Good night";
        break;
    }

    return text;
  };

  renderStates = () => {
    let stateString = "(";
    if (this.props.authStore.user?.state) {
      this.props.authStore.user?.state.map((eachState, index) => {
        stateString += `${index ? ", " : ""}${States[eachState]}`;
        return null;
      });
    }
    stateString += ")";
    return stateString;
  };

  renderSubRole = () => {
    if (this.props.authStore.user?.role === "AGENT") {
      return `(${this.props.authStore.user.promo})`;
    } else {
      return this.props.authStore.user?.state ? this.renderStates() : "";
    }
  };
  render() {
    return (
      <div className="bg-white shadow">
        <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div
            className={`py-6 ${
              this.props.extraActionText && this.props.extraActionFunction
                ? "xl:flex"
                : "md:flex"
            } md:items-center md:justify-between lg:border-t lg:border-gray-200`}
          >
            <div className="flex-1 min-w-0">
              <div className="flex items-center">
                <img
                  className="hidden h-16 w-16 object-cover sm:block"
                  src={Favicon}
                  alt=""
                />
                <div>
                  <div className="flex items-center">
                    <img
                      className="h-16 w-16 object-cover sm:hidden"
                      src={Favicon}
                      alt=""
                    />
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                      {this.renderGreetingCopy()},{" "}
                      {this.props.authStore.user?.name ?? ""}
                    </h1>
                  </div>
                  <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dt className="sr-only">Occupation</dt>
                    <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                      <UserCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                      {this.props.authStore.user?.role
                        ? AdminRole[this.props.authStore.user.role]
                        : ""}{" "}
                      {this.renderSubRole()}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="flex flex-row xl:mt-0 md:mt-5">
              {this.props.extraActionText && (
                <div className="mt-6 space-x-3 mr-5 md:mr-0 md:mt-0">
                  <Button
                    className="w-auto"
                    text={this.props.extraActionText}
                    type="normal"
                    onClick={this.props.extraActionFunction}
                  />
                </div>
              )}
              {this.props.callToActionText && (
                <div className="mt-6 space-x-3 md:mt-0 md:ml-4">
                  <Button
                    className="w-auto"
                    text={this.props.callToActionText}
                    type="normal"
                    onClick={this.props.callToActionFunction}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(Greeting);
